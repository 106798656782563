// Libraries and utilites
import { Avatar, AvatarSizes, Button, Icon } from '@musiversal/musiversal-components';
import classNames from 'classnames';
import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ToastTypes } from '../../../../enums/toast-types.enum';
// Enums
import { UserStatuses } from '../../../../enums/user-statuses.enum';
// Helpers
import { isAllowedReferralHelper } from '../../../../helpers/is-allowed-referral.helper';
import { executeResumeSubscription } from '../../../../helpers/paymentActions.helper';
import { figureOutVideoPlatform, getVideoId } from '../../../../helpers/video.helper';
// Hooks
import { useIsMobileResolution } from '../../../../hooks/useIsMobileResolution';
import { useToast } from '../../../../hooks/useToast';
//Services
import UserService from '../../../../services/user/user.service';
import {
  selectBannerHeight,
  setIsShowGlobalLoader,
  selectPlans,
  selectOnboardingData
} from '../../../../store/slices/global.slice';
import {
  setIsHelpModal,
  setIsShowSubscriptionModal,
  setIsShowLeadToWaitlisterModal,
  setIsShowPaywallModal,
  setIsVideoModal,
  setIsShowRejoinWaitlistModal
} from '../../../../store/slices/modals.slice';
// Redux actions and selectors
// ToastHooks
import { hideToast, showToast } from '../../../../store/slices/toast.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ActiveSessionsCounter from '../ActiveSessionsCounter/ActiveSessionsCounter';
// Assets
import { ReactComponent as Logo } from './Musiversal-White.svg';
// Styles
import styles from './Navbar.module.scss';

const billingAreaPath = '/profile#billing';

const Navbar = (): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bannerHeight = useSelector(selectBannerHeight);
  const plans = useSelector(selectPlans, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  const isMobileResolution = useIsMobileResolution();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const onClick = () => setIsActive(!isActive);
  const history = useHistory();
  const location = useLocation();
  const { showResources, hideSessionCounter } = useFlags();
  const { sendToast } = useToast();

  // we get the currentPath from location
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  const logout = async () => {
    // We delete all cookies
    cookieCutter.set('userToken', null, { expires: new Date(0) });
    localStorage.removeItem('avatar');
    history.push('/logout');
  };

  const openHowItWorksModal = async () => {
    if (onboardingData?.starting) {
      dispatch(
        setIsVideoModal({
          isShown: true,
          videoOnly: false,
          copy: {
            headerTitle: onboardingData?.starting?.title,
            headerSubtitle: onboardingData?.starting?.subtitle,
            buttonText: onboardingData?.starting?.button?.label
          },
          videoId: getVideoId(onboardingData?.starting?.video_url),
          videoPlatform: figureOutVideoPlatform(onboardingData?.starting?.video_url)
        })
      );
      setIsActive(false);
    }
  };
  const openHelpModal = async () => {
    dispatch(setIsHelpModal(true));
    setIsActive(false);
  };

  const resumeSubscription = async (): Promise<void> => {
    await executeResumeSubscription(user, plans, dispatch, history);
  };

  const openRejoinWaitlistModal = async () => {
    if (user.status === UserStatuses.CANCELLED) {
      const response = await UserService.rejoinWaitlistNotification({});
      sendToast({ message: response?.data?.msg, toastType: ToastTypes.SUCCESS });
      sendToast(response?.data?.msg, 'success');
    } else if (user.status === UserStatuses.LEAD) {
      dispatch(setIsShowLeadToWaitlisterModal({ isShown: true, source: 'navbar_button' }));
    } else {
      dispatch(setIsShowRejoinWaitlistModal(true));
    }
  };

  const openSubscribeModal = () => async () => {
    // dispatch(setIsShowSubscriptionModal(true));
  };

  const showActionButton = useMemo(
    () => [UserStatuses.UNSUBSCRIBED, UserStatuses.PENDING_CANCELLATION, UserStatuses.CANCELLED].includes(user.status),
    [user.status]
  );

  const showSubscribeButton = useMemo(
    () =>
      [UserStatuses.UNSUBSCRIBED, UserStatuses.CANCELLED, UserStatuses.INVITED].includes(user.status) &&
      user.allow_subscribe,
    [user.status]
  );

  const showJoinWaitlistButton = useMemo(
    () =>
      [UserStatuses.UNSUBSCRIBED, UserStatuses.CANCELLED, UserStatuses.LEAD].includes(user.status) &&
      !user.allow_subscribe,
    [user.status]
  );

  const showResumeSubscriptionButton = useMemo(() => [UserStatuses.PENDING_CANCELLATION].includes(user.status), [
    user.status
  ]);

  const showSpeedMeter = useMemo(
    () => [UserStatuses.PENDING_CANCELLATION, UserStatuses.UNLIMITED].includes(user.status),
    [user.status]
  );

  useEffect(() => {
    const pageClickEvent = e => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  const contactSupportLink = useMemo(
    () =>
      `https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a?__hstc=22948580.d84bcc8487e7c4952ad4187adc305fc0.1637051653108.1641209517908.1641290813033.70&__hssc=22948580.1.1641290813033&__hsfp=7147803&your_name=${user.name}&email=${user.email}`,
    [user.name, user.email]
  );

  return (
    <>
      <div
        data-status={user.status}
        data-name={user.name}
        data-platform='unlimited'
        className={classNames(styles.navbar, showActionButton)}
        style={{ top: `${bannerHeight}px` }}
      >
        <Link to='/explore' className={styles.navbarLogo}>
          <Logo />
        </Link>
        <nav className={styles.navbarNav}>
          <Link
            to='/explore'
            className={styles.navbarNavLink + ' ' + (currentPath === '/explore' ? styles.isActive : '')}
          >
            <div className={styles.iconSection}>
              <Icon name='compass' />
              Explore
            </div>
          </Link>
          <Link
            to='/sessions'
            className={styles.navbarNavLink + ' ' + (currentPath === '/sessions' ? styles.isActive : '')}
          >
            <div className={styles.iconSection}>
              <Icon name='server' />
              Sessions
            </div>
          </Link>
          {showResources && (
            <Link
              to='/resources'
              className={styles.navbarNavLink + ' ' + (currentPath === '/resources' ? styles.isActive : '')}
            >
              <div className={styles.iconSection}>
                <Icon name='grid' />
                Resources
              </div>
            </Link>
          )}
        </nav>
        <div className={styles.dropdown__container}>
          <div className={styles.avatarAreaContainer}>
            {!isMobileResolution && (
              <div className={styles.creditsDisplay}>
                {/* <CreditsDisplay
                  useIsMobileResolution={useIsMobileResolution}
                /> */}
              </div>
            )}
            {showSubscribeButton && !isMobileResolution && (
              <Button
                // onClick={() => dispatch(setIsShowSubscriptionModal(true))}
                onClick={() => dispatch(setIsShowPaywallModal(true))}
                className={styles.getFullAccessButton}
                width={'100%'}
                buttonText={'Subscribe'}
              />
            )}
            {showJoinWaitlistButton && !isMobileResolution && (
              <Button
                onClick={openRejoinWaitlistModal}
                className={styles.getFullAccessButton}
                width={'100%'}
                buttonText={'Join the waitlist'}
              />
            )}
            {showResumeSubscriptionButton && !isMobileResolution && (
              <Button
                onClick={() => resumeSubscription()}
                className={styles.getFullAccessButton}
                width={'100%'}
                buttonText={'Resume subscription'}
              />
            )}
            <div onClick={onClick} className={styles.dropdown__trigger}>
              <Avatar
                key={user?.id}
                id={user?.id}
                name={user?.name}
                user={!hideSessionCounter ? user : { ...user, speed_limit: 0, sessions_left: 0 }}
                avatarUrl={user?.avatar}
                showSpeedMeter={true}
                avatarSize={AvatarSizes.SMALL}
                avatarOverride={'../../../../../assets/images/default-avatar.png'}
              />
            </div>
          </div>
          <nav
            ref={dropdownRef}
            className={classNames(
              styles.dropdown__menu + ' ' + (isActive ? styles.active : styles.inactive),
              hideSessionCounter && styles.withoutCounter
            )}
          >
            <ul>
              {isMobileResolution && (showSubscribeButton || showJoinWaitlistButton || showResumeSubscriptionButton) && (
                <li className={styles.liWithButton}>
                  {showSubscribeButton && (
                    <Button
                      onClick={() => dispatch(setIsShowPaywallModal(true))}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonText={'Subscribe'}
                    />
                  )}
                  {showJoinWaitlistButton && (
                    <Button
                      onClick={openRejoinWaitlistModal}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonText={'Join the waitlist'}
                    />
                  )}
                  {showResumeSubscriptionButton && (
                    <Button
                      onClick={() => dispatch(setIsShowSubscriptionModal(true))}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonText={'Resume subscription'}
                    />
                  )}
                </li>
              )}
              {user?.speed_limit > 0 && user.status != UserStatuses.UNSUBSCRIBED && !hideSessionCounter && (
                <li>
                  <div className={styles.counterContainer}>
                    <ActiveSessionsCounter sessionsUsed={user?.sessions_used} sessionsTotal={user?.speed_limit} />
                  </div>
                </li>
              )}
              <li>
                <Link to='/profile#profile'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'user'} />
                  </span>
                  Profile
                </Link>
              </li>
              <li>
                <a onClick={openHowItWorksModal}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'video'} />
                  </span>
                  How it works
                </a>
              </li>
              {/* {isAllowedReferralHelper(user.status, isPartnersDashboard) && <li>
                <Link to='/referral'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'referral'} />
                  </span>
                  Referral
                </Link>
              </li>} */}
              <li>
                <a onClick={openHelpModal}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'help_circle'} />
                  </span>
                  Help & Support
                </a>
              </li>
              {/* <li>
                <a href={contactSupportLink} target='_blank' rel='noreferrer'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'contactSupport'} />
                  </span>
                  Contact Support
                </a>
              </li> */}
              <li>
                <a onClick={logout}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'log_out'} />
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
