import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import InputError from './../../Atoms/InputError/InputError';
import MusicianImage from './../../Atoms/MusicianImage/MusicianImage';
import styles from './BookSessionForm.module.scss';
import BookingConfirmation from './components/BookingConfirmation/BookingConfirmation';
import DeliverablesForm from './components/DeliverablesForm/DeliverablesForm';
import TrackDetailsForm from './components/TrackDetails/TrackDetailsForm';
import { prepareDeliverablesInfo, prepareTrackDetailsInfo } from './utils/bookSessionForm.utils';
import { selectUser } from '../../../store/slices/user.slice';
import { translateUserSubscription } from '../../../helpers/translate-user-subscription';
import { setIsShowTopUpModal } from '../../../store/slices/modals.slice';
import { Mixpanel } from '../../../helpers/mixpanel.helper';
import Icon from '../../Atoms/Icon/Icon';
import { BookSessionTabs, ChooseAServiceTab } from '../../../enums/book-session-tabs.enum';
import { EditSessionTabs } from '../../../enums/edit-session-tabs.enum';
import { ModalWarningTypes } from '../../../enums/modal-types.enum';
import SlotsService from '../../../services/slots/slots.service';
import { selectSession, setSession } from '../../../store/slices/sessions.slice';
import PreConfirmation from './components/PreConfirmation/PreConfirmation';
import SessionsService from '../../../services/sessions/sessions.service';
import TracksService from '../../../services/tracks/tracks.service';
import BookingCalendar from '../BookingCalendar/BookingCalendar';
import { selectCurrentlyBookingArtistConfig } from '../../../store/slices/musicians.slice';
import { getSingleSession, getTracks } from '../../../store/actions/sessions.actions';
import { getNextAvailableSessions } from '../../../store/actions/musicians.actions';
import ChooseAService from './components/ChooseAService/ChooseAService';
import { useGetFormDefaultValuesHook } from './useGetFormDefaultValues.hook';

const NEED_MORE_CREDITS_MESSAGE = 'You need more credits to book this session.';

export default function BookSessionForm({
                                          musician,
                                          musicianConfiguration,
                                          slots = [],
                                          initialState,
                                          isEdit,
                                          instrument = undefined,
                                          onCancel,
                                          onClose,
                                          onSubmit
                                        }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const session = useSelector(selectSession, shallowEqual);
  const servicesFirst = true;
  const {
    services: { servicesList }
  } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const { handleSubmit: handleSubmitSlotPrebook, formState: { errors: errorsSlotPrebook } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [primarySlot, setPrimarySlot] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  // const [formStep, setFormStep] = useState(initialState ||
  //   (servicesFirst ? ChooseAServiceTab.CHOOSE_A_SERVICE : BookSessionTabs.CHOOSE_A_TIME));
  const [formStep, setFormStep] = useState(initialState || ChooseAServiceTab.CHOOSE_A_SERVICE);
  const [editFormStep, setEditFormStep] = useState(initialState || EditSessionTabs.TRACK_DETAILS);

  const defaultValues = useGetFormDefaultValuesHook({ session, extraConfig: musicianConfiguration });

  const {
    register,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
    control,
    handleSubmit,
    getValues
  } = useForm({ defaultValues, mode: 'all' });

  const tabs = useMemo(() => {
    const baseTabs = Object.values(BookSessionTabs);
    if (servicesFirst && !isEdit) {
      return [ChooseAServiceTab.CHOOSE_A_SERVICE, ...baseTabs];
    }
    return baseTabs;
  }, [isEdit, servicesFirst]);

  const fullSelectedService = useMemo(() => {
    if (selectedService) {
      return servicesList.filter(x => x.value === selectedService)[0];
    }
  }, [selectedService]);

  const editTabs = Object.values(EditSessionTabs);

  const { sessionsLeft, creditsBank } = translateUserSubscription(user);

  useEffect(() => {
    const fallbackStep = servicesFirst ? ChooseAServiceTab.CHOOSE_A_SERVICE :
      isEdit ? EditSessionTabs.TRACK_DETAILS :
        BookSessionTabs.CHOOSE_A_TIME;
    setFormStep(initialState || fallbackStep);
  }, [initialState, isEdit, servicesFirst]);

  const onSaveButtonPress = useCallback(async () => {
    setSubmitting(true);
    const data = getValues();
    const { track_title: trackTitle } = data;

    const trackDetailsInfo = prepareTrackDetailsInfo({
      data,
      session,
      extraConfig: musicianConfiguration
    });
    const deliverablesInfo = prepareDeliverablesInfo({
      data,
      session
    });

    const info = { session_info: trackDetailsInfo.session_info, ...deliverablesInfo };

    if (trackTitle?.__isNew__ || trackTitle?.addedOnBlur) {
      try {
        const track = (await TracksService.createTrack(trackDetailsInfo.track)).data;
        info.track_id = track.id;
      } catch (e) {
        console.error(e);
      }
    } else {
      info.track_id = trackTitle?.value;
    }

    const updatedSession = (await SessionsService.updateSession(info)).data;
    dispatch(setSession(updatedSession));
    await dispatch(getSingleSession(session.id));
    await dispatch(getTracks());
    onSubmit();
    onClose?.();
  }, [dispatch, getValues, musicianConfiguration, onSubmit, session, onClose]);

  const isNoSessionsOrCreditsLeft = sessionsLeft <= 0 && creditsBank <= 0;

  useEffect(() => {
    const slot = selectedSlots.find(s => s.isPrimary) || selectedSlots?.[0];
    setPrimarySlot(slot);
  }, [selectedSlots]);

  const onSubmitDate = async () => {
    if (isNoSessionsOrCreditsLeft) {
      dispatch(setIsShowTopUpModal({ isShown: true, warningType: ModalWarningTypes.WARNING, message: NEED_MORE_CREDITS_MESSAGE }));
      return;
    }

    /* Prebook session */
    try {
      const { service } = getValues();
      const payload = {
        slots: selectedSlots.map(({ id }) => id),
        primary_slot: primarySlot?.id
      };
      if (service) {
        payload.options = service;
      }
      const res = await SlotsService.prebook(payload);
      const sessionToUpdate = res.data;
      try {
        Mixpanel.track('Booking Date Selected', {
          'Partner': musician.slug,
          'Service': service,
          'User ID': user.id,
          'User status': user.status,
          'Session duration': sessionToUpdate.duration,
          'Number of Slots': selectedSlots.length,
          'Slot Time': primarySlot?.start_date,
          'Time to Session (hours)': moment(primarySlot?.start_date).diff(moment(), 'hours')
        });
      } catch (err) {
        console.error(err);
      }
      dispatch(setSession(sessionToUpdate));
      setFormStep(BookSessionTabs.TRACK_DETAILS);
    } catch (e) {
      console.error(e);
    }
  };

  const renderEditFooter = () => (
    <div className={classNames(styles.sessionForm__actions, styles.sessionForm__actionsMultiple, styles.isActive)}>
      <a className={styles.sessionForm__actionsButtonOutline} onClick={() => onCancel()}>
        {'Cancel'}
      </a>
      <button
        type='submit'
        className={classNames(styles.sessionForm__actionsButton, styles.sessionForm__saveButton)}
        disabled={submitting}
        onClick={onSaveButtonPress}
      >
        {submitting ? ' Saving...' : ' Save Changes'}
      </button>
    </div>
  );

  const setStep = step => () => {
    isEdit ? setEditFormStep(step) : setFormStep(step);
  };

  const onBookingConfirmation = () => {
    dispatch(getNextAvailableSessions());
    setStep(BookSessionTabs.THANK_YOU)();
  };

  const onCalendarGoBack = () => {
    setSelectedSlots([]);
    setStep(ChooseAServiceTab.CHOOSE_A_SERVICE)();
  };

  const onChooseATimeClick = () => {
    const {
      isServiceURL,
      service_url: serviceUrl
    } = servicesList.find(({ value }) => value === getValues().extra) || {};
    try {
      Mixpanel.track('Service Selected', {
        'Partner': musician.slug,
        'Service': selectedService,
        'User ID': user.id,
        'User status': user.status,
        'External Service': isServiceURL
      });
    } catch (err) {
      console.error(err);
    }
    if (isServiceURL && serviceUrl) {
      window.open(serviceUrl, '_blank');
      return;
    }
    setStep(BookSessionTabs.CHOOSE_A_TIME)();
  };

  const renderEditMode = () => <>
    <div className={classNames(styles.sessionForm__subHeader, styles.inEditMode)}>
      {editTabs.map(tab =>
        <div
          key={tab}
          className={classNames(
            styles.sessionForm__subHeaderItem,
            editFormStep === tab && styles.isActive,
            isEdit && styles.isEditTab
          )}
        >
          <a onClick={setStep(tab)}>{tab}</a>
        </div>
      )}
    </div>

    <div className={styles.sessionForm__body}>
      <div hidden={editFormStep !== EditSessionTabs.TRACK_DETAILS}>
        <div className={styles.sessionForm__bodyForm}>
          <TrackDetailsForm
            getValues={getValues}
            register={register}
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            setValue={setValue}
            watch={watch}
            clearErrors={clearErrors}
          >
            {renderEditFooter()}
          </TrackDetailsForm>
        </div>
      </div>

      <div hidden={editFormStep !== EditSessionTabs.SESSION_DETAILS}>
        <div className={styles.sessionForm__bodyForm}>
          <DeliverablesForm
            getValues={getValues}
            extraConfig={musicianConfiguration}
            control={control}
            register={register}
            errors={errors}
            clearErrors={clearErrors}
          >
            {renderEditFooter()}
          </DeliverablesForm>
        </div>
      </div>
    </div>
  </>;

  const renderHeader = () => <div className={styles.sessionForm__header}>
    <div className={styles.sessionForm__headerMusician}>
      <div className={styles.sessionForm__headerMusicianAvatar}>
        <MusicianImage
          id={musician.id}
          avatar={musician.image ?? `/images/musicians-avatars/${musician.name}.png`}
          name={musician.name}
          width={64}
          height={64}
          altUrl={musician.image_url}
        />
      </div>
      <div className={styles.sessionForm__headerMusicianMeta}>
        <h3 className={styles.sessionForm__headerMusicianInstrument}>{instrument}</h3>
        <p className={styles.sessionForm__headerMusicianName}>{musician.name}</p>
      </div>
    </div>

    {musician.slug && (
      <a
        href={`${process.env.REACT_APP_MUSICIAN_PROFILES_DOMAIN}/artists/${musician.slug}`}
        target='_blank'
        className={styles.sessionForm__headerFullProfile}
        rel='noreferrer'
      >
        View full profile
        <Icon name={'goToLink'} />
      </a>
    )}
    <Icon
      onClick={onClose}
      name={'xClose'}
      className={styles.closeIcon}
    />
  </div>;

  return (
    <>
      <div className={styles.sessionForm}>
        {isEdit ? renderEditMode() : (
          <>
            {renderHeader()}
            {formStep !== BookSessionTabs.THANK_YOU && <div className={styles.sessionForm__subHeader}>
              {tabs.map(tab => ![BookSessionTabs.THANK_YOU, BookSessionTabs.PRE_CONFIRMATION].includes(tab) && <div
                key={tab}
                className={classNames(
                  styles.sessionForm__subHeaderItem,
                  formStep === tab && styles.isActive
                )}
              >
                <a>{tab}</a>
              </div>)}
            </div>}

            <div className={styles.sessionForm__body}>
              <div hidden={formStep !== ChooseAServiceTab.CHOOSE_A_SERVICE}>
                <div className={styles.sessionForm__bodyForm}>
                  <ChooseAService
                    control={control}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                    setSelection={setSelectedService}
                  />
                </div>
                <div className={classNames(styles.sessionForm__actions, styles.isActive)}>
                  <button
                    className={styles.sessionForm__actionsButton}
                    onClick={onChooseATimeClick}
                  >
                    {'Choose a time ->'}
                  </button>
                </div>
              </div>

              <form
                onSubmit={handleSubmitSlotPrebook(onSubmitDate)}
                hidden={formStep !== BookSessionTabs.CHOOSE_A_TIME}
              >
                <div className={styles.sessionForm__bodyForm}>
                  <BookingCalendar
                    slots={slots}
                    selectedSlots={selectedSlots}
                    selectedService={selectedService}
                    setSelectedSlots={setSelectedSlots}
                    getValues={getValues}
                    watch={watch}
                  />
                  {errorsSlotPrebook.server && <InputError message={errorsSlotPrebook.server.message} />}
                </div>

                <div className={classNames(styles.sessionForm__actions, selectedSlots.length && styles.isActive)}>
                  <div
                    className={classNames(styles.sessionForm__actions, servicesFirst && styles.sessionForm__actionsMultiple, selectedSlots.length && styles.isActive)}
                  >
                    {servicesFirst && <a
                      className={styles.sessionForm__actionsButtonOutline}
                      onClick={onCalendarGoBack}
                    >
                      {'<- Back'}
                    </a>}

                    <button
                      className={styles.sessionForm__actionsButton}
                      disabled={!selectedSlots.length}
                      type='submit'
                    >
                      {'Add track details ->'}
                    </button>
                  </div>
                </div>
              </form>

              <div hidden={formStep !== BookSessionTabs.TRACK_DETAILS}>
                <div className={styles.sessionForm__bodyForm}>
                  <TrackDetailsForm
                    getValues={getValues}
                    register={register}
                    control={control}
                    errors={errors}
                    setStep={setStep}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                    watch={watch}
                    clearErrors={clearErrors}
                  >
                    <div
                      className={classNames(styles.sessionForm__actions, styles.sessionForm__actionsMultiple, styles.isActive)}
                    >
                      <a
                        className={styles.sessionForm__actionsButtonOutline}
                        onClick={setStep(BookSessionTabs.CHOOSE_A_TIME)}
                      >
                        {'<- Back'}
                      </a>

                      <button
                        className={styles.sessionForm__actionsButton}
                        type='submit'
                      >
                        {'Add Session Details ->'}
                      </button>
                    </div>
                  </TrackDetailsForm>
                </div>
              </div>

              <div hidden={formStep !== BookSessionTabs.SESSION_DETAILS}>
                <div className={styles.sessionForm__bodyForm}>
                  <DeliverablesForm
                    getValues={getValues}
                    extraConfig={musicianConfiguration}
                    isShowSendAMessage
                    control={control}
                    register={register}
                    errors={errors}
                    clearErrors={clearErrors}
                  >
                    <div
                      className={classNames(styles.sessionForm__actions, styles.sessionForm__actionsMultiple, styles.isActive)}
                    >
                      <a
                        className={styles.sessionForm__actionsButtonOutline}
                        onClick={setStep(BookSessionTabs.TRACK_DETAILS)}
                      >
                        {'<- Back'}
                      </a>

                      <button
                        className={styles.sessionForm__actionsButton}
                        onClick={setStep(BookSessionTabs.PRE_CONFIRMATION)}
                      >
                        {'Next ->'}
                      </button>
                    </div>
                  </DeliverablesForm>
                </div>
              </div>

              <div hidden={formStep !== BookSessionTabs.PRE_CONFIRMATION}>
                <div className={styles.sessionForm__bodyForm}>
                  <PreConfirmation
                    session={session}
                    primarySlot={primarySlot}
                    musicianConfiguration={musicianConfiguration}
                    onBookingConfirmation={onBookingConfirmation}
                    onGoBack={setStep(BookSessionTabs.SESSION_DETAILS)}
                    getValues={getValues}
                  />
                </div>
              </div>

              <div hidden={formStep !== BookSessionTabs.THANK_YOU}>
                <BookingConfirmation
                  session={session}
                  selectedService={fullSelectedService}
                  musician={musician}
                  instrument={instrument}
                  primarySlot={primarySlot}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
