import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

import { selectUser } from '../../../../store/slices/user.slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectSession, selectTracks, setSession } from '../../../../store/slices/sessions.slice';
import { selectLimitations } from '../../../../store/slices/limitations.slice';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { removeUpload, selectUploads } from '../../../../store/slices/uploads.slice';
import { timeParserHelper } from '../../../../helpers/time-parser.helper';
import SingleFileUpload from './SingleFileUpload/SingleFileUpload';
import { SessionFilesFolder } from '../../../../enums/session-files-folder.enum';
import { selectIsPartnersDashboard } from '../../../../store/slices/global.slice';
import Spinner from '../../Spinner/Spinner';
import { UploadStatus } from '../../../../enums/upload-status.enum';
import { formatBytes } from '../../../../helpers/format-bytes';
import { setShowFilesModal } from '../../../../store/slices/modals.slice';
import SessionsService from '../../../../services/sessions/sessions.service';
import TagManager from 'react-gtm-module';
import { selectCurrentlyBookingArtistConfig } from '../../../../store/slices/musicians.slice';
import { getSingleSession } from '../../../../store/actions/sessions.actions';
import { SessionType } from '../../../../enums/session-type.enum';
import { SessionFilesStatus } from '../../../../enums/session-files-status.enum';
import { SessionStatus } from '../../../../enums/session-status.enum';
import Icon from '../../Icon/Icon';
import FilePreview from '../../v3/FilePreview/FilePreview';
import styles from './FileUploadDropzone.module.scss';

// https://react-dropzone.js.org/#section-styling-dropzone

const baseStyle = {};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const MAX_SIZE_IN_BYTES = 5368709120;
// 10MB in bytes
const TEN_MB_IN_BYTES = 10485760;
const MAX_ACCEPTED_FILES = 5;

interface FileUploadDropzoneInterface {
  isAllowUpload: boolean;
  setAddedFiles: any;
}

const FileUploadDropzone = ({ isAllowUpload = true, setAddedFiles }: FileUploadDropzoneInterface): ReactElement => {
  const dispatch = useDispatch();
  const params = useParams();
  // const session = useSelector(selectSession, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);
  const limitations = useSelector(selectLimitations, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  // const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);

  const tracks = useSelector(selectTracks, shallowEqual);
  const uploads = useSelector(selectUploads, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsToUpload, setItemsToUpload] = useState([]);

  const removeItemFromItemsToUpload = index => {
    const newItemsToUpload = itemsToUpload.filter((item, i) => i !== index);
    setItemsToUpload(newItemsToUpload);
    setAddedFiles(newItemsToUpload);
  };

  // const fileUploadRules = isPartnersDashboard ? null : artistConfig?.fileUploadRules;

  // const partnerName = session?.partner.name;

  const afterPartnerUpload = useCallback(async () => {
  //   try {
  //     const { id } = session;
  //     await SessionsService.closeSession(id);
  //     await dispatch(getSingleSession(id));
  //   } catch (err) {
      console.error(err);
  //   }
  // }, [session, dispatch]);
  }, [dispatch]);

  const sessionUploads =  1;
  // const sessionUploads = useMemo(() =>
  //     uploads
  //       ?.filter(upload => upload.sessionId.toString() === session.id.toString())
  //       ?.sort((a, b) => a.uploadTimestamp < b.uploadTimestamp ? -1 : 1),
  //   [uploads, session.id]);

  // const sessionFolderName = useMemo(() => {
  //   const track_id = session?.track_id;
  //   const sessionId = session?.id;
  //   const filteredTrack = tracks?.tracks?.filter(({ id }) => id === track_id);
  //   const title = filteredTrack?.[0]?.title;
  //   return title
  //     ? `${sessionId} - ${title} - Session for ${partnerName}`
  //     : `${sessionId} - Session for ${partnerName}`;
  // }, [partnerName, session?.id, session?.track_id, tracks?.tracks]);

  // const clearUploadsList = () => {
  //   const finishedUploads = uploads.filter(({ status, sessionId }) =>
  //     status !== UploadStatus.UPLOADING && sessionId === session.id);
  //   finishedUploads.forEach(({ id }) => dispatch(removeUpload(id)));
  // };

  // const endOfDeliveryRestriction = useMemo(() => (
  //   moment(session?.start_date).add(parseInt(limitations?.express_files_delivery_time), 'minutes')
  // ), [session?.id, user?.id, limitations]);

  // const endOfDownloadRestriction = useMemo(() => (
  //   moment(session.start_date).add(parseInt(limitations?.files_expiration_time), 'days')
  // ), [session?.id, user?.id, limitations]);

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsLoading(true);
      const allFiles = [
        ...itemsToUpload,
        ...acceptedFiles
      ];
      setItemsToUpload(allFiles);
      setAddedFiles(allFiles);
      setIsLoading(false);

       // const payload = {
      //   session_id: session.id,
      //   files: acceptedFiles.map(({ path, type }) => ({ fileName: path, filePath: path, fileType: type }))
      // };
      // try {
      //   const { urls } = (await SessionsService.createFileUploadLink(payload)).data;
      //   setIsLoading(false);

      //   const uploadsRes = await Promise.allSettled(
      //     urls.map(async ({ url, key }) => {
      //       const fileToUpload = (acceptedFiles.filter(({ path }) => path === key) || [])[0];
      //       if (!fileToUpload) {
      //         return;
      //       }
      //       await dispatch(uploadFile({ fileToUpload, url, session }));
      //     })
      //   );

      //   const isUploadSuccess = uploadsRes.some(({ status }) => status === 'fulfilled');

      //   const sessionIdFromParams = window.location.href.split('sessions/')[1];
      //   const { id: sessionId } = session;
      //   if (isUploadSuccess && sessionId.toString() === sessionIdFromParams?.toString()) {
      //     await dispatch(getSingleSession(sessionId));
      //   }

      //   if (isPartnersDashboard) {
      //     await afterPartnerUpload();
      //   }
      // } catch (e) {
      //   setIsLoading(false);
      //   console.error(e);
      // }
    },
    // [session, isPartnersDashboard, dispatch, afterPartnerUpload]
    [isPartnersDashboard, dispatch, afterPartnerUpload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({ onDrop, maxFiles: MAX_ACCEPTED_FILES, maxSize: TEN_MB_IN_BYTES });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const openFileManager = () => {
    // dispatch(setShowFilesModal({
    //   isShown: true,
    //   folder: isPartnersDashboard ? SessionFilesFolder.USER_FILES : SessionFilesFolder.OUTPUT
    // }));
  };

  const isShowClearUploadListButton = useMemo(() => uploads.some(({ status }) =>
    status === UploadStatus.COMPLETED || status === UploadStatus.FAILED), [uploads]);

  return (
    <div className={styles.container}>
      {/* Upload Zone */}
      {isAllowUpload && (
        <div className={styles.uploadedZone}>
          <div className={styles.fileUploadZone} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isLoading ? <Spinner className={styles.spinner} isBlue /> : <>
            <span className={styles.icon}>
              <Icon name={'upload-folder'} />
            </span>
              <p className={styles.backgroundText}>Upload Files</p>
            </>}
          </div>
          {fileRejections?.length ? (
            <span className={styles.tooLargeFileError}>
              {fileRejections[0].errors[0].code === 'too-many-files' ? (
                <>
                  <strong>Error: </strong>Too many files
                </>
              ) : (
                <>
                  <strong>Error: </strong>Some files were larger than {formatBytes(TEN_MB_IN_BYTES)}
                </>
              )}
            </span>
          ): (
            <div className={styles.uploadHint}>
              Upload up to 5 files, Max file size = 10mb
            </div>
          )}
        </div>
      )}

      {itemsToUpload?.length > 0 && (
        <div className={styles.itemsToUpload}>
          { itemsToUpload.map((item, index) => (
            <FilePreview
              key={index}
              filename={item.fileName}
              sizeInBytes={item.size}
              onAction={() => removeItemFromItemsToUpload(index)}
            />
          ))}
        </div>
      )}


    </div>
  );
};

export default FileUploadDropzone;
