import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../../Icon/Icon';
import styles from './InputError.module.scss';

interface InputErrorInterface {
  message: string;
}

const InputError = ({ message }: InputErrorInterface): ReactElement => (
  <span className={styles.InputError}>
    <div className={styles.icon}>
      <Icon name={'alert-circle'} className={styles.icon} />
    </div>
    <span className={styles.errorMessage}>{message}</span>
  </span>
);

export default InputError;

