import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getMe, getPermissions } from 'app/store/actions/user.actions';
import { RootState } from 'app/store/store';

import { UserInterface, PermissionsInterface } from '../../interfaces/user.interface';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  user: UserInterface;
  permissions: PermissionsInterface;
};

export const user = createSlice({
  name: SlicesNames.USER_SLICE,
  initialState: {
    user: null,
    permissions: null
  },
  reducers: {
    setUser: (state: SliceState, { payload }: PayloadAction<UserInterface>): void => {
      state.user = payload;
    },
    resetUser: (state: SliceState): void => {
      state.user = null;
    },
    setPermissions: (state: SliceState, { payload }: PayloadAction<PermissionsInterface>): void => {
      state.permissions = payload;
    },
    resetPermissions: (state: SliceState): void => {
      state.permissions = null;
    }
  },
  extraReducers: {
    [getMe.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<UserInterface>): void => {
      state.user = payload;
    },
    [getPermissions.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<PermissionsInterface>): void => {
      state.permissions = payload;
    }
  }
});

export const { setUser, resetUser, setPermissions, resetPermissions } = user.actions;
export const selectUser = (state: RootState) => state.userSlice.user;
export const selectPermissions = (state: RootState) => state.userSlice.permissions;

export default user.reducer;
