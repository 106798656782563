import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import Icon from '../../Icon/Icon';
import styles from './RadioWide.module.scss';

interface RadioInterface {
  name: string;
  id: string;
  value: any;
  label: string;
  control: Control<any>;
  containerClassName?: string;
  labelClassName?: string;
  required?: boolean;
  defaultChecked?: boolean;
  description?: string;
  infoContent?: string;
}

const RadioWide = ({
  name,
  id,
  value,
  label,
  containerClassName,
  labelClassName,
  control,
  required,
  defaultChecked,
  description,
  infoContent
}: RadioInterface): ReactElement => (
  <Controller
    control={control}
    name={name}
    rules={{
      required
    }}
    render={({ field: { onChange } }) => (
      <div key={id} className={classNames(styles.radio, containerClassName)}>
        <input
          type='radio'
          name={name}
          id={id}
          value={value}
          defaultChecked={defaultChecked}
          onChange={val => onChange(val)}
        />
        <label htmlFor={id}>
          <span className={classNames(styles.label, labelClassName)}>
            {label}
            {infoContent && <Icon name={'infoIcon'} data-tip={infoContent} />}
          </span>
          {description && <span className={styles.description}>{description}</span>}
        </label>
        <ReactTooltip
          clickable
          html
          place='right'
          delayHide={1000}
          textColor='#FFF'
          backgroundColor='#4B5563'
          effect='solid'
        />
      </div>
    )}
  />
);

export default RadioWide;
