import classnames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';
import ReactModal from 'react-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { v4 } from 'uuid';

import { SessionFilesFolder } from '../../../enums/session-files-folder.enum';
import { formatBytes } from '../../../helpers/format-bytes';
import SessionService from '../../../services/sessions/sessions.service';
import { selectIsPartnersDashboard } from '../../../store/slices/global.slice';
import { selectShowFilesModal, setShowFilesModal } from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Icon from '../../Atoms/Icon/Icon';
import Spinner from '../../Atoms/Spinner/Spinner';
import styles from './SessionFilesModal.module.scss';
import { useDownloadAllFiles } from './useDownloadAllFiles';

interface SessionFilesModalInterface {
  customerName: string;
  partnerName: string;
  trackName: string;
  sessionId: number;
}

interface CurrentlyPlayingInterface {
  header: string;
  url: string;
  id: string;
}

const SUPPORTED_AUDIO_FORMATS = ['mp3', 'wav'];

const Table = ({ columns, data }: any): ReactElement => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <table className={styles.table} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                key={column.id}
                {...column.getHeaderProps([column.getSortByToggleProps(), { className: styles[column.className] }])}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon
                        className={styles.sortArrow}
                        style={{ transform: 'rotate(90deg)', top: '3px' }}
                        name='arrow-right'
                        width={8}
                        height={16}
                        viewBox='0 0 10 18'
                      />
                    ) : (
                      <Icon
                        className={styles.sortArrow}
                        style={{ transform: 'rotate(-90deg)', top: '4px' }}
                        name='arrow-right'
                        width={8}
                        height={16}
                        viewBox='0 0 10 18'
                      />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td key={cell.id} {...cell.getCellProps([{ className: styles[cell.column.className] }])}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const SessionFilesModal = ({
  customerName,
  trackName,
  sessionId,
  partnerName
}: SessionFilesModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const showFilesModal = useSelector(selectShowFilesModal, shallowEqual);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sessionFolder, setSessionFolder] = useState<SessionFilesFolder | string>();
  const [currentlyPlaying, setCurrentlyPLaying] = useState<CurrentlyPlayingInterface>(null);

  const { setDownloadUrl, generateDownloadLink, downloadUrl, generatingDownloadLink } = useDownloadAllFiles(sessionId);

  const playerRef = useRef(null);

  const audioFiles = useMemo(() => files.filter(file => file.isSupportedAudioFile), [files]);

  const tabs = useMemo(
    (): { folder: SessionFilesFolder; label: string }[] => [
      {
        folder: SessionFilesFolder.USER_FILES,
        label: `From ${customerName}`
      },
      {
        folder: SessionFilesFolder.OUTPUT,
        label: `From ${partnerName}`
      }
    ],
    [customerName, partnerName]
  );

  const getDownloadSingleFile = useCallback(
    (path: string, filename: string): (() => void) => async (): Promise<void> => {
      try {
        const { downloadUrl: url } = (await SessionService.getDownloadLink(sessionId, path)).data;
        const res = await fetch(url);
        const data = await res.blob();
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
      } catch (e) {
        console.error(e);
      }
    },
    [sessionId]
  );

  const playFile = useCallback(
    (path: string, filename: string, id: string): (() => void) => async (): Promise<void> => {
      try {
        const { downloadUrl: url } = (await SessionService.getDownloadLink(sessionId, path)).data;
        setCurrentlyPLaying({ header: filename, url, id });
      } catch (e) {
        console.error(e);
      }
    },
    [sessionId]
  );

  const pauseFile = (): void => {
    playerRef.current?.audio.current.pause();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        className: 'nameColumn',
        accessor: '',
        Cell: (props: Record<string, any>): ReactElement => (
          <div className={styles.nameCell}>
            {!!audioFiles.length && (
              <div className={styles.playIcon}>
                {props.row.original.isSupportedAudioFile && (
                  <>
                    {isPlaying && currentlyPlaying?.id === props.row.original.id ? (
                      <Icon onClick={pauseFile} name={'pause'} />
                    ) : (
                      <Icon
                        onClick={playFile(
                          props.row.original.filePath,
                          props.row.original.filename,
                          props.row.original.id
                        )}
                        name={'play'}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            <p>
              <i className={styles.folder}>{props.row.original.prefix}</i>
              <span>{props.row.original.filename}</span>
            </p>
          </div>
        )
      },
      {
        Header: 'Last modified',
        accessor: 'last_modified'
      },
      {
        Header: 'Size',
        accessor: 'size'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: (props: Record<string, any>): ReactElement => (
          <span
            onClick={getDownloadSingleFile(props.row.original.filePath, props.row.original.filename)}
            className={styles.downloadIcon}
          >
            <Icon name='move-layer-down' />
          </span>
        ),
        disableSortBy: true
      }
    ],
    [audioFiles.length, isPlaying, currentlyPlaying?.id, playFile, getDownloadSingleFile]
  );

  const getFiles = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const sessionFiles = (await SessionService.getSessionFilesRequest(sessionId, sessionFolder)).data?.files;
      const mappedFiles = sessionFiles.map(file => {
        const nameSplit = file.filename.split('.');
        return {
          ...file,
          id: v4(),
          last_modified: moment(file.last_modified).tz(user.timezone).format('HH:mm:ss A, DD MMM YY'),
          size: formatBytes(file.size),
          isSupportedAudioFile: SUPPORTED_AUDIO_FORMATS.includes(nameSplit[nameSplit.length - 1])
        };
      });
      setFiles(mappedFiles);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, [sessionId, sessionFolder, user.timezone]);

  useEffect((): void => {
    if (showFilesModal.isShown && sessionFolder) {
      getFiles().finally();
    }
  }, [sessionId, user.timezone, getFiles, showFilesModal, sessionFolder]);

  useEffect((): void => {
    setSessionFolder(showFilesModal.folder);
  }, [showFilesModal.folder]);

  const closeModal = () => {
    setSessionFolder(null);
    setDownloadUrl(null);
    setFiles([]);
    setCurrentlyPLaying(null);
    dispatch(setShowFilesModal({ isShown: false }));
  };

  const deleteSessionFilesFolder = async () => {
    try {
      setIsLoading(true);
      await SessionService.deleteSessionFiles(sessionId);
      setFiles([]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAllText = useMemo(
    (): string =>
      sessionFolder === SessionFilesFolder.USER_FILES
        ? `pre-session files (by ${customerName})`
        : `session files (by ${partnerName})`,
    [sessionFolder, customerName, partnerName]
  );

  const deleteFiles = async (): Promise<void> => {
    if (window.confirm(`Are you sure you want to delete all ${deleteAllText}?`)) {
      await deleteSessionFilesFolder();
    }
  };

  const renderCloseButton = (): ReactElement => (
    <button className={styles.close} onClick={closeModal}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19.7 4.3C19.3 3.9 18.7 3.9 18.3 4.3L12 10.6L5.7 4.3C5.3 3.9 4.7 3.9 4.3 4.3C3.9 4.7 3.9 5.3 4.3 5.7L10.6 12L4.3 18.3C3.9 18.7 3.9 19.3 4.3 19.7C4.5 19.9 4.7 20 5 20C5.3 20 5.5 19.9 5.7 19.7L12 13.4L18.3 19.7C18.5 19.9 18.8 20 19 20C19.2 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3L13.4 12L19.7 5.7C20.1 5.3 20.1 4.7 19.7 4.3Z'
          fill='#D1D5DB'
        />
      </svg>
    </button>
  );

  const onTabChange = (tabFolder: SessionFilesFolder): (() => void) => (): void => setSessionFolder(tabFolder);

  return (
    <div className={styles.container}>
      <ReactModal
        appElement={document.getElementById('root')}
        isOpen={showFilesModal.isShown}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <p>
                Session: <span className={styles.sessionId}>#{sessionId}</span>
              </p>
              <p>
                Track: <span className={styles.sessionId}>{trackName}</span>
              </p>
            </div>

            <div className={styles.user}>
              <p>{customerName}</p>
            </div>
            {renderCloseButton()}
          </div>
          <div className={styles.tabs}>
            {tabs.map(({ folder: tabFolder, label }) => (
              <div
                onClick={onTabChange(tabFolder)}
                key={label}
                className={classnames(styles.tab, tabFolder === sessionFolder && styles.activeTab)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        {isLoading ? (
          <Spinner className={styles.spinner} isBlue />
        ) : (
          <>
            {files.length ? (
              <>
                <div className={styles.tableWrapper}>
                  <Table columns={columns} data={files} />
                </div>
                {(isPartnersDashboard && sessionFolder === SessionFilesFolder.USER_FILES) ||
                (!isPartnersDashboard && sessionFolder === SessionFilesFolder.OUTPUT) ? (
                  <button onClick={generateDownloadLink} className={styles.itemMetaUploadButton}>
                    <div className={styles.itemMetaUploadButtonIcon}>
                      <Icon name='move-layer-down' />
                    </div>
                    {downloadUrl ? (
                      <a href={downloadUrl}>Your files are ready! Press to download</a>
                    ) : (
                      <>
                        {downloadUrl === null && generatingDownloadLink === true
                          ? 'Preparing your files...'
                          : 'Download all files'}
                      </>
                    )}
                  </button>
                ) : (
                  <button className={styles.itemMetaUploadButton}>
                    <a onClick={deleteFiles}>Delete all {deleteAllText}</a>
                  </button>
                )}
              </>
            ) : (
              <span className={styles.noFiles}>No files</span>
            )}
            {!!audioFiles.length && (
              <AudioPlayer
                header={currentlyPlaying?.header}
                src={currentlyPlaying?.url}
                showJumpControls={false}
                customAdditionalControls={[]}
                ref={playerRef}
                autoPlayAfterSrcChange
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                customIcons={{
                  play: <Icon className={styles.playerIcon} name={'play'} />,
                  pause: <Icon className={styles.playerIcon} name={'pause'} />
                }}
              />
            )}
          </>
        )}
      </ReactModal>
    </div>
  );
};

export default SessionFilesModal;
