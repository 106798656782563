import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from 'app/helpers/mixpanel.helper';

import { localStorageKeys } from '../../../constants/localStorageKeys.const';
import { PaymentProviders } from '../../../enums/payment-providers.enum';
import { Routes } from '../../../enums/routes.enum';
import { CancelReasonInterface } from '../../../interfaces/cancel-reason.interface';
import { ModalInterface } from '../../../interfaces/modal.interface';
import PaypalService from '../../../services/subscriptions/paypal.service';
import SubscriptionService from '../../../services/subscriptions/stripe.service';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { selectOffboardingModalData, setIsShowOffboardingModal } from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import CustomCheckbox from '../../Atoms/CustomCheckbox/CustomCheckbox';
import Icon from '../../Atoms/Icon/Icon';
import Radio from '../../Atoms/Radio/Radio';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './OffboardingModal.module.scss';

enum Steps {
  FIRST = '1',
  SECOND = '2',
  THIRD = '3'
}

const MAX_STEP = 2;

const OffboardingModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const offboardingModalData = useSelector(selectOffboardingModalData, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);

  const { handleSubmit, control, getValues, watch, register, reset } = useForm();

  const [step, setStep] = useState(Steps.FIRST);

  useEffect(() => {
    try {
      if (isOpen) {
        Mixpanel.track('Cancellation Modal Shown', {
          user_id: user?.id,
          payment_provider: user.subscription?.paymentsProvider,
          user_status: user?.status
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [isOpen]);

  if (!offboardingModalData) {
    return <></>;
  }

  const {
    Step1: { Description: step1Description, reasons },
    Step2: { acknowledgements_description, acknowledgement, benefits_description, benefits },
    Step3: { title: step3title, body: step3body },
    Step3B: { title: step3BTitle, body: step3BBody }
  } = offboardingModalData;

  const acknowledgementFields = acknowledgement.map((item, idx) => `acknowledgement_${idx}`);
  const benefitsFields = benefits.map((item, idx) => `benefits_${idx}`);
  watch(['reason', ...acknowledgementFields, ...benefitsFields]);

  const isReasonSelected = getValues().reason;
  const isAllCheckboxSelected =
    acknowledgementFields.every(field => !!getValues()[field]) && benefitsFields.every(field => !!getValues()[field]);

  const onRequestClose = (): void => {
    dispatch(setIsShowOffboardingModal(false));
    reset();
    goToStep(Steps.FIRST)();
  };

  const goToStep = (toStep: Steps): (() => void) => (): void => setStep(toStep);

  const onSubmit = async (data: Record<string, string>): Promise<void> => {
    if (!isAllCheckboxSelected) {
      return;
    }
    dispatch(setIsShowGlobalLoader(true));
    localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
    const { reason, comments } = data;
    const cancelReason = {
      value: reason,
      comments
    } as CancelReasonInterface;
    try {
      user.subscription?.paymentsProvider === PaymentProviders.PAYPAL
        ? await PaypalService.cancelSubscription(cancelReason)
        : await SubscriptionService.cancelSubscription(cancelReason);
      goToStep(Steps.THIRD)();
      Mixpanel.track('Cancelled Subscription', {
        user_id: user?.id,
        payment_provider: user.subscription?.paymentsProvider,
        user_status: user?.status,
        reason: reason,
        comments: comments
      });
      history.push(`${Routes.BILLING}?session_id=true`);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const renderFirstStep = (): ReactElement => (
    <>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon name='question' />
        </div>
        <h3>{step1Description}</h3>
        <div className={styles.reasons}>
          {reasons.map(({ id, body, value }) => (
            <div key={id}>
              <Radio
                required
                name='reason'
                control={control}
                id={id}
                value={value}
                label={body}
                labelClassName={styles.reason}
              />
            </div>
          ))}
          <textarea name='comments' placeholder='Tell us more.' {...register('comments')} />
        </div>
      </div>
      <div
        onClick={isReasonSelected ? goToStep(Steps.SECOND) : undefined}
        className={classNames(styles.footerButton, !isReasonSelected && styles.footerInactive)}
      >
        <span>Next {'->'}</span>
      </div>
    </>
  );

  const renderSecondStep = (): ReactElement => (
    <>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon name='offboardingWarning' />
        </div>
        <h3>{acknowledgements_description}</h3>
        <div className={styles.body}>
          {acknowledgement.map(({ id, body }, idx) => (
            <CustomCheckbox
              key={id}
              required
              name={`acknowledgement_${idx}`}
              label={body}
              control={control}
              containerClassName={styles.checkbox}
              labelClassName={styles.checkboxLabel}
            />
          ))}
        </div>
        <h3>{benefits_description}</h3>
        <div className={styles.body}>
          {benefits.map(({ id, body }, idx) => (
            <CustomCheckbox
              key={id}
              required
              name={`benefits_${idx}`}
              label={body}
              control={control}
              containerClassName={styles.checkbox}
              labelClassName={styles.checkboxLabel}
            />
          ))}
        </div>
      </div>
      <button
        type='submit'
        className={classNames(styles.footerButton, !isAllCheckboxSelected && styles.footerInactive)}
      >
        <span>Confirm cancelation</span>
      </button>
    </>
  );

  const renderThirdStep = (): ReactElement => (
    <div className={styles.content}>
      <div className={styles.icon}>
        <Icon name='brokenHeart' />
      </div>
      {user.subscription?.legacy ? (
        <div className={styles.finalStepBody}>
          <h3>{step3BTitle}</h3>
          <div className={styles.finalStepContent} dangerouslySetInnerHTML={{ __html: step3BBody }} />
        </div>
      ) : (
        <div className={styles.finalStepBody}>
          <h3>{step3title}</h3>
          <div className={styles.finalStepContent} dangerouslySetInnerHTML={{ __html: step3body }} />
        </div>
      )}
    </div>
  );

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
      {[Steps.FIRST, Steps.SECOND].includes(step) && (
        <p className={styles.counter}>
          {step}/{MAX_STEP}
        </p>
      )}
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {step === Steps.FIRST && renderFirstStep()}
        {step === Steps.SECOND && renderSecondStep()}
        {step === Steps.THIRD && renderThirdStep()}
      </form>
    </ModalWrapper>
  );
};

export default OffboardingModal;
