import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import avatarImg from '../../../../../../assets/images/avatar.png';
import { CustomerSessionDetailsTabs } from '../../../../../enums/customer-session-details-tabs.enum';
import { EditSessionTabs } from '../../../../../enums/edit-session-tabs.enum';
import { ServiceType } from '../../../../../enums/service-type';
import { SessionStatus } from '../../../../../enums/session-status.enum';
import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import SessionsService from '../../../../../services/sessions/sessions.service';
import { getSingleSession } from '../../../../../store/actions/sessions.actions';
import { getMe } from '../../../../../store/actions/user.actions';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import { selectSession } from '../../../../../store/slices/sessions.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import { selectLimitations } from '../../../../../store/slices/limitations.slice';
import { customerStatusMapping } from '../../../../../views/main/components/sessions/sessions.const';
import BookSessionForm from '../../../../Molecules/BookSessionForm/BookSessionForm';
import Messages from '../../../../Molecules/Messages/Messages';
import RatingCard from '../../../../Atoms/RatingCard/RatingCard';
import FileUploadsWarning from '../../../../Atoms/FileUploadsWarning/FileUploadsWarning';
import { Alert, NavTabs } from '@musiversal/musiversal-components';
import { useDownloadAllFiles } from '../../../../Molecules/SessionFilesModal/useDownloadAllFiles';
import AddToGoogleCalendarButton from '../../../AddToGoogleCalendarButton/AddToGoogleCalendarButton';
import FileUploadDropzone from '../../../FileUploadDropzone/FileUploadDropzone';
import Icon from '../../../Icon/Icon';
import MusicianImage from '../../../MusicianImage/MusicianImage';
import { articleLink, attendanceLivestream, editAllowedStatuses } from '../../SessionDetails.const';
import {
  setIsShowCancelSessionModal,
  setIsShowRatingModal,
  setIsUpsellPlansModal,
  setIsShowSubscriptionModal
} from '../../../../../store/slices/modals.slice';
import UserService from '../../../../../services/user/user.service';
import { hideToast, showToast } from '../../../../../store/slices/toast.slice';
import styles from '../../SessionDetails.module.scss';

interface CustomerSessionDetailsInterface {
  musicianConfiguration: any;
  onPanelClose: () => void;
}

const CustomerSessionDetails = ({
  musicianConfiguration,
  onPanelClose
}: CustomerSessionDetailsInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const limitations = useSelector(selectLimitations, shallowEqual);
  const session = useSelector(selectSession, shallowEqual);
  const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const selectedService =
    artistConfig?.services?.servicesList.find(({ value }) => value === session?.session_info?.options) || {};
  const formToggles = selectedService?.form || {};
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(CustomerSessionDetailsTabs.SESSION_DETAILS);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeStep, setEditModeStep] = useState<EditSessionTabs>(EditSessionTabs.TRACK_DETAILS);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const copyToClipboard = useCopyToClipboard();
  const { tab } = useParams<{ tab: string }>();
  const tabs = Object.values(CustomerSessionDetailsTabs);

  const timezone = user.timezone || moment.tz.guess();

  const sessionTime = `${moment(session.start_date).tz(timezone).format('HH:mm')} (${timezone})`;
  const sessionDate = moment(session.start_date).tz(timezone).format("ddd DD MMM 'YY");

  const {
    id: sessionId,
    partner: { name: sessionName, avatar: sessionAvatar },
    attendance: sessionAttendance,
    status: sessionStatus,
    session_info: {
      options: sessionInstrument,
      title: sessionTrack,
      meter: sessionMeter,
      bpm: sessionMBpm,
      track_genre: sessionGenre,
      reference_track: sessionReferenceTrack,
      sampleRate
    },
    files_format: filesFormat
  } = session;

  const { generateDownloadLink, downloadUrl, generatingDownloadLink } = useDownloadAllFiles(sessionId);

  const isAllowUpload = [
    SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED,
    SessionStatus.CLIENT_FILES_PENDING_REVIEW,
    SessionStatus.CLIENT_FILES_UPDATE_REQUIRED,
    SessionStatus.PARTNER_READY_TO_GO,
    SessionStatus.PARTNER_UPLOAD_REQUIRED
  ].includes(sessionStatus);

  const changeToEditMode = (step: EditSessionTabs): void => {
    setIsEditMode(true);
    setEditModeStep(step);
    toggleDropdown();
  };

  const openUpsellModal = (): void => {
    dispatch(setIsUpsellPlansModal(true));
  };

  useEffect((): void => {
    if (!tab) {
      return;
    }
    if (tabs.includes(tab as CustomerSessionDetailsTabs)) {
      setCurrentTab(tab as CustomerSessionDetailsTabs);
    }
    if (tab === 'edit' && !isEditMode) {
      setIsEditMode(true);
    }
  }, [isEditMode, tab, tabs]);

  const getHelpForThisSessionLink = useMemo((): string => {
    const adminInfo = `
    Id of the user: ${user.id}\n
    Session Id: ${session.id}\n
    Session date and time: ${session.start_date}\n
    timezone: ${timezone}\n
    Name of the Partner: ${session.partner.name}\n
    Instrument: ${session.session_info.options}\n
    Session status: ${sessionStatus}
    `;
    return `https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a?ticket_funnel_options=Sessions%20issues&your_name=${user.name}&email=${user.email}&admin_info=${adminInfo}`;
  }, [user, session, sessionStatus, timezone]);

  const exitEditMode = (): void => setIsEditMode(false);

  const onCancelSession = async (): Promise<void> => {
    toggleDropdown();
    const { cancellationRecreditLimit } = musicianConfiguration;
    dispatch(setIsShowCancelSessionModal({ isShown: true, cancellationRecreditLimit: cancellationRecreditLimit }));
  };

  const toggleDropdown = (): void => setIsDropdownOpened(!isDropdownOpened);

  const openRatingModal = (e): void => {
    e.stopPropagation();
    dispatch(setIsShowRatingModal({ isShown: true, sessionId: session?.id }));
  };

  const renderEditMode = (): ReactElement => (
    <>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>Edit Session</h3>
        {editAllowedStatuses.includes(session?.status) && (
          <div className={styles.dropdownContainer}>
            <OutsideClickHandler onOutsideClick={(): void => setIsDropdownOpened(false)}>
              <button onClick={toggleDropdown} className={styles.dotsButton}>
                <Icon name='dots-menu' />
              </button>
              <div className={classNames(styles.dropdownMenu, isDropdownOpened && styles.dropdownMenuOpened)}>
                <ul>
                  <li>
                    <a onClick={onCancelSession}>
                      <span className={styles.menuItemIcon}>
                        <Icon name='c-delete' />
                      </span>
                      Cancel session
                    </a>
                  </li>
                </ul>
              </div>
            </OutsideClickHandler>
          </div>
        )}
        <Icon onClick={onPanelClose} className={styles.closeIcon} name={'xClose'} />
      </div>
      <BookSessionForm
        initialState={editModeStep}
        isEdit
        musician={session.partner}
        musicianConfiguration={musicianConfiguration}
        onSubmit={exitEditMode}
        onCancel={exitEditMode}
        onClose={onPanelClose}
      />
    </>
  );

  const renderMainHeader = (): ReactElement => (
    <div className={styles.header}>
      <h3 className={styles.sessionDetail__headerTitle}>
        Session
        <span
          onClick={() => copyToClipboard(window.location.href)}
          data-tip={'Copy to clipboard'}
          className={styles.headerTitleSessionID}
        >
          #{session.id}
        </span>
      </h3>
      {customerStatusMapping[sessionStatus] && (
        <span className={classNames(styles.sessionStatus, styles[customerStatusMapping[sessionStatus][0]])}>
          {customerStatusMapping[sessionStatus][1]}
        </span>
      )}
      {editAllowedStatuses.includes(session?.status) && (
        <div className={styles.dropdownContainer}>
          <OutsideClickHandler onOutsideClick={(): void => setIsDropdownOpened(false)}>
            <button onClick={toggleDropdown} className={styles.dotsButton}>
              <Icon name='dots-menu' />
            </button>
            <div className={classNames(styles.dropdownMenu, isDropdownOpened && styles.dropdownMenuOpened)}>
              <ul>
                <li onClick={toggleDropdown}>
                  <AddToGoogleCalendarButton
                    partnerName={sessionName}
                    instrument={sessionInstrument}
                    trackName={session?.session_info?.title}
                    start_date={moment(session?.start_date).toISOString()}
                    end_date={moment(session?.start_date).add(session.duration, 'minutes').toISOString()}
                    styling={false}
                  />
                </li>
                <li onClick={toggleDropdown}>
                  <a href={getHelpForThisSessionLink} target='_blank' rel='noreferrer'>
                    <span className={styles.menuItemIcon}>
                      <Icon name='getHelp' />
                    </span>
                    Get help for this session
                  </a>
                </li>
                <li>
                  <a onClick={() => changeToEditMode(EditSessionTabs.TRACK_DETAILS)}>
                    <span className={styles.menuItemIcon}>
                      <Icon name='pen-01' />
                    </span>
                    Edit session
                  </a>
                </li>
                <li>
                  <a onClick={onCancelSession}>
                    <span className={styles.menuItemIcon}>
                      <Icon name='c-delete' />
                    </span>
                    Cancel session
                  </a>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      <Icon onClick={onPanelClose} className={styles.closeIcon} name={'xClose'} />
    </div>
  );

  const renderTabs = (): ReactElement => (
    <div className={styles.tabs}>
      {tabs.map(headerTab => (
        <div key={headerTab} className={classNames(styles.tabsItem, currentTab === headerTab && styles.isActive)}>
          <a onClick={(): void => setCurrentTab(headerTab)}>{headerTab}</a>
        </div>
      ))}
    </div>
  );

  const renderSessionDetailsRegularItem = ({
    icon,
    label,
    value,
    isLink,
    tooltipContent
  }: {
    icon: string;
    label: string;
    value: string;
    isLink?: boolean;
    tooltipContent?: ReactElement | string;
  }): ReactElement => (
    <div className={styles.item}>
      <div className={styles.icon}>
        <Icon name={icon} width={24} height={24} viewBox='0 0 24 24' />
      </div>
      <div className={styles.itemLabelValueContainer} data-tip={tooltipContent}>
        <h3>{label}</h3>
        {isLink ? (
          <a target='_blank' rel='noreferrer' href={value}>
            {value}
          </a>
        ) : (
          <p>{value}</p>
        )}
      </div>
    </div>
  );

  const renderRememberText = (): ReactElement => (
    <div className={styles.rememberText}>
      <p className={styles.reminder}>
        <strong>Remember:</strong> You must upload your pre-session files at least 24 hours before the session start
        time, otherwise your session will be cancelled (48 hours for mix advice/ mix or master sessions).
      </p>
      <p className={styles.reminder}>
        For guidance,{' '}
        <a href={articleLink} target='_blank' rel='noreferrer'>
          click here
        </a>
        .
      </p>
    </div>
  );

  const renderSessionDetails = (): ReactElement => (
    <div className={styles.sessionDetails}>
      <div className={styles.musician}>
        <div className={styles.musicianAvatar}>
          <MusicianImage
            id={sessionName}
            avatar={`../../../../assets/images/musicians-avatars/${sessionName}.png`}
            width={56}
            height={56}
            name={sessionName}
            altUrl={sessionAvatar || avatarImg}
          />
        </div>
        <p className={styles.musicianMetaTitle}>{sessionName}</p>
      </div>
      <div className={styles.items}>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({ icon: 'calendar', label: 'Date', value: sessionDate })}
          {renderSessionDetailsRegularItem({ icon: 'clock', label: 'Starts', value: sessionTime })}
        </div>
        <div className={styles.itemRow}>
          {selectedService.session_type !== ServiceType.OFFLINE &&
            renderSessionDetailsRegularItem({
              icon: 'timer',
              label: 'Session Length',
              value: `${session.duration} min`
            })}
          {![ServiceType.OFFLINE, ServiceType.LIVE].includes(selectedService.session_type) &&
            renderSessionDetailsRegularItem({
              icon: 'live-stream',
              label: 'Attending Livestream',
              value: attendanceLivestream[sessionAttendance]
            })}
        </div>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({
            icon: 'music',
            label: 'Service',
            value: sessionInstrument
          })}
          {(formToggles.deliverables ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'attach',
              label: 'Deliverables format',
              value: filesFormat
            })}
        </div>
        {sessionStatus !== SessionStatus.CANCELLED && (
          <div className={styles.itemRow}>
            {selectedService.session_type !== ServiceType.OFFLINE &&
              renderSessionDetailsRegularItem({
                icon: 'streaming',
                label: 'Livestream link',
                value: musicianConfiguration?.zoomLink,
                isLink: true
              })}
            {selectedService.session_type !== ServiceType.OFFLINE && musicianConfiguration?.audiomoversLink &&
              renderSessionDetailsRegularItem({
                icon: 'streaming',
                label: 'Audiomovers link',
                value: musicianConfiguration?.audiomoversLink,
                isLink: true,
                tooltipContent: `Password: ${session?.user?.name?.split(' ')[0]}`
              })}
          </div>
        )}
      </div>
      {session && session?.rating !== null && session?.rating?.status !== 'disabled' && (
        <div className={styles.rating}>
          <RatingCard
            rating={session?.rating?.rating}
            comment={session?.rating?.comment}
          />
        </div>
      )}
      {sessionStatus !== SessionStatus.CANCELLED && session?.type === 'past' && session?.rating == null && user.status !== UserStatuses.CANCELLED &&(
        <button className={styles.singleActionButton} onClick={openRatingModal}>
          Rate your session
        </button>
      )}
    </div>
  );

  const renderTrackDetails = (): ReactElement => (
    <div className={styles.sessionDetails}>
      <div className={styles.items}>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({ icon: 'music', label: 'Track', value: sessionTrack })}
          {(formToggles.track_genre ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'music-album',
              label: 'Genre',
              value: sessionGenre
            })}
        </div>
        <div className={styles.itemRow}>
          {(formToggles.meter ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'sound-wave',
              label: 'Meter',
              value: sessionMeter
            })}
          {(formToggles.bpm ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'bpm',
              label: 'BPM',
              value: sessionMBpm
            })}
        </div>
        {(formToggles.reference_track ?? true) && (
          <div className={styles.itemRow}>
            <div className={classNames(styles.item, styles.fullWidthItem)}>
              <div className={styles.icon}>
                <Icon name={'folder-music'} width={24} height={24} viewBox='0 0 24 24' />
              </div>
              <div>
                <h3>Reference Track</h3>
                <p>
                  {sessionReferenceTrack ? (
                    <a href={sessionReferenceTrack} className={styles.referenceTrack} target='_blank' rel='noreferrer'>
                      {sessionReferenceTrack}
                    </a>
                  ) : (
                    'No reference track was provided'
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.itemRow}>
          {(formToggles.sample_rate ?? true) &&
            renderSessionDetailsRegularItem({ icon: 'sound-wave', label: 'Sample Rate', value: sampleRate })}
        </div>
      </div>
    </div>
  );

  const renderFilesManagement = (limitations): ReactElement => {
    const endOfDeliveryRestriction = moment(session.start_date).add(parseInt(limitations?.express_files_delivery_time), 'minutes');
    const endOfDownloadRestriction = moment(session.start_date).add(parseInt(limitations?.files_expiration_time), 'days');
    const isNotFilesDeliveryTime = endOfDeliveryRestriction > moment();
    const cannotDownloadFilesRestriction = [UserStatuses.CANCELLED, UserStatuses.UNSUBSCRIBED].includes(user.status); // endOfDownloadRestriction < moment();

    const sendToast = (message, toastType, delay = 4000) => {
      const payload = {
        message,
        toastType
      };
      dispatch(showToast(payload));
      setTimeout(() => {
        dispatch(hideToast());
      }, delay);
    };

    const openSubscribeModalOrJoinWaitlist = async () => {
      if (user?.allow_subscribe) {
        dispatch(setIsShowSubscriptionModal(true));
      } else {
        const response = await UserService.rejoinWaitlistNotification({});
        sendToast(response?.data?.msg, 'success');
      }
    };
    return (
      <div className={styles.sessionDetails}>
        <FileUploadDropzone isAllowUpload={isAllowUpload} />
        {sessionStatus === SessionStatus.PARTNER_DELIVERABLES_READY && (
            <>
              <div className={styles.warningNotification}>
                {/* {isNotFilesDeliveryTime && (
                  <FileUploadsWarning
                    hasLimitations={(user.has_limitations || !user.has_limitations)}
                    icon={'zap'}
                    warningHeader={limitations?.express_files_delivery_header}
                    warningBody={limitations?.express_files_delivery_body}
                  />
                )} */}
                { cannotDownloadFilesRestriction && (
                  <Alert
                    icon={'archive'}
                    title={'NEED TO STORE YOUR FILES INDEFINITELY?'}
                    body={'Subscribe and get access to permanent files storage and other features.'}
                    hasLinkAtEnd={true}
                    linkText={user?.allow_subscribe ? 'Resubscribe' : 'Join waitlist'}
                    clickLinkFn={() => openSubscribeModalOrJoinWaitlist()}
                    template={'success'}
                  />
                )}
              </div>
              { !isNotFilesDeliveryTime && !cannotDownloadFilesRestriction && (
                <>
                  {downloadUrl ? (
                    <a href={downloadUrl} className={styles.singleActionButton} download>
                      <Icon name='folder-download' />
                      <span>Click to download recording files</span>
                    </a>
                  ) : (
                    <a onClick={generateDownloadLink} className={styles.singleActionButton} download>
                      <Icon name='folder-download' />
                      <span>
                        {downloadUrl === null && generatingDownloadLink === true
                          ? 'Preparing your files...'
                          : 'Get your recording files'}
                      </span>
                    </a>
                  )}
                  {/* <div className={styles.limitationWarningText}>
                    <div className={styles.warningDatedText}>
                      Files are stored until {endOfDownloadRestriction.tz(timezone).format("ddd DD MMM 'YY")}.
                    </div>
                    <div className={styles.memberInvitationText}>
                      <a onClick={openUpsellModal}>Become a member</a> and store your files indefinitely.
                    </div>
                  </div> */}
                </>
              )}
            </>
          )
          // : (
          //   <>
          //     {downloadUrl ? (
          //       <a href={downloadUrl} className={styles.singleActionButton} download>
          //         <Icon name='folder-download' />
          //         <span>Click to download recording files</span>
          //       </a>
          //     ) : (
          //       <a onClick={generateDownloadLink} className={styles.singleActionButton} download>
          //         <Icon name='folder-download' />
          //         <span>
          //           {downloadUrl === null && generatingDownloadLink === true
          //             ? 'Preparing your files...'
          //             : 'Get your recording files'}
          //         </span>
          //       </a>
          //     )}
          //   </>
          // )
        // )
        }
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {isEditMode ? (
        renderEditMode()
      ) : (
        <>
          {renderMainHeader()}
          <div className={styles.body}>
            {renderTabs()}
            {/* <NavTabs
            tabs={[
              {
                label: 'Session',
                selected: true,
                onClick: () => alert('hey')
              },
              {
                label: 'Track',
                selected: false,
                onClick: () => alert('hey')
              },
              {
                label: 'Files',
                selected: false,
                onClick: () => alert('hey')
              },
              {
                label: 'Messages',
                selected: false,
                onClick: () => alert('hey')
              }
            ]}
          /> */}
            <div
              className={classNames(
                currentTab !== CustomerSessionDetailsTabs.SESSION_DETAILS && styles.isHidden,
                styles.bodyContent
              )}
            >
              {renderSessionDetails()}
            </div>
            <div
              className={classNames(
                currentTab !== CustomerSessionDetailsTabs.TRACK_DETAILS && styles.isHidden,
                styles.bodyContent
              )}
            >
              {renderTrackDetails()}
            </div>
            <div
              className={classNames(
                currentTab !== CustomerSessionDetailsTabs.FILES_MANAGEMENT && styles.isHidden,
                styles.bodyContent
              )}
            >
              {renderFilesManagement(limitations)}
            </div>
            <div
              className={classNames(
                currentTab !== CustomerSessionDetailsTabs.MESSAGES && styles.isHidden,
                styles.bodyContent
              )}
            >
              <Messages />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerSessionDetails;
