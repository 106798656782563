import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button_v2 from '../../../components/Atoms/Button_v2/button';
import Icon from '../../../components/Atoms/Icon/Icon';
import InputError from '../../../components/Atoms/InputError/InputError';
import Spinner from '../../../components/Atoms/Spinner/Spinner';
import VATBreakdown from '../../../components/Atoms/VATBreakdown/VATBreakdown';
import SecurityCountryFooter from '../../../components/Atoms/SecurityCountryFooter/SecurityCountryFooter';
import StyledInput from '../../../components/Atoms/StyledInput/StyledInput';
import { ButtonSizes } from '../../../enums/button-sizes.enum';
import { MixPanelSignUpEvents } from '../../../enums/mixpanel-events.enum';
import { EuropeanTaxableCountriesEnum } from '../../../constants/european-taxable-countries.const';
import { PORTUGUESE_TAX } from '../../../constants/european-tax.const';
import { MixPanelAddedPaymentMethodProperties } from '../../../enums/mixpanel-properties.enum';
import { StyledInputTypes } from '../../../enums/styled-input-types.enum';
import { Mixpanel } from '../../../helpers/mixpanel.helper';
import {
  applyText, bullet0,
  bullet1,
  bullet2,
  bullet4,
  bullet5,
  creditCardTextPaymentOption,
  haveACouponText,
  paypalTextPaymentOption,
  removeText,
  submitButtonText,
  submittingButtonText
} from '../payment-details.const';
import styles from './CheckoutForm.module.scss';
import { useHistory } from 'react-router-dom';
import SubscriptionsService from '../../../services/subscriptions/stripe.service';
import PaypalService from '../../../services/subscriptions/paypal.service';
import { getInstruments, getMusicians } from '../../../store/actions/musicians.actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { PaymentOptions } from '../../../enums/payment-options.enum';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectSelectedPlan, selectSelectedProduct, setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import countryList from 'country-list';
import StyledSelect from '../../../components/Atoms/StyledSelect/StyledSelect';
import { editedCountryNames } from '../../../constants/country-list-rename.const';
import { postalCodeValidators } from '../../../constants/postal-code-validators.const';
import { postalCodePlaceholders } from '../../../constants/postal-code-placeholders.const';
import { Colors } from '../../../enums/colors.enum';
import { ProductTypes } from '../../../enums/product-types.enum';
import { selectUser } from '../../../store/slices/user.slice';

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData();

countries.sort((a, b) => {
  if (a.name === 'United States' ||
    a.name === 'Canada' ||
    a.name === 'United Kingdom') {
    return -1;
  } else if (
    b.name === 'United States' ||
    b.name === 'Canada' ||
    b.name === 'United Kingdom') {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
});
const countryCodesThatRequireZip = ['US', 'GB', 'CA'];

const style = {
  base: {
    color: '#E6E6E5',
    fontSize: '16px',
    fontSmoothing: 'antialiased'
  },
  invalid: {
    color: '#B84C33',
    ':focus': {
      color: '#B84C33'
    }
  }
};

const redirectRoute = '/thank-you';

const getBulletElement = ({ bullet, price, coupon }) => {
  let spanElement;
  if (coupon) {
    const {
      terms_message: { old_price: oldPrice, new_price: newPrice, date }
    } = coupon;
    spanElement = (
      <span>
        You will be charged <span className={styles.crossed}>${oldPrice}</span> ${newPrice} on {date}
      </span>
    );
  } else if (price) {
    const TWO_WEEKS = 14;
    const date = moment().add(TWO_WEEKS, 'days').format('MMM D, YYYY');
    spanElement = (
      <span>
        You will be charged ${price} on {date}
      </span>
    );
  }
  return (
    <div key={bullet}>
      <Icon name={'gold-tick'} className={styles.tick} />
      {spanElement || <span>{bullet}</span>}
    </div>
  );
};

const CheckoutForm = ({ user, isPlanSelection, selectedProduct, selectedProductType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const history = useHistory();
  const selectedPlan = useSelector(selectSelectedPlan, shallowEqual);
  // const selectedProduct = useSelector(selectSelectedProduct, shallowEqual);
  const { country: userCountry } = useSelector(selectUser, shallowEqual);

  const [stripeErrors, setStripeErrors] = useState({ cardNumber: null, cardExpiry: null, cardCvc: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isShowCoupon, setIsShowCoupon] = useState(false);
  const [validCoupon, setValidCoupon] = useState(null);
  const [country, setCountry] = useState(countries[0].code);
  const [isShowPostalCode, setIsShowPostalCode] = useState(false);
  const [paymentOption, setPaymentOption] = useState(PaymentOptions.CREDIT_CARD);
  const { register, setError, formState: { errors }, clearErrors, getValues, reset, watch, handleSubmit } =
    useForm({ defaultValues: { country: userCountry ?? countries[0].code } });

  const { paypalPayments, studioTrialsOnSignup } = useFlags();
  const showVATBreakdown = useMemo(
    () =>
      EuropeanTaxableCountriesEnum.includes(userCountry),
    [userCountry]
  );

  const stripePriceId = useMemo(
    () =>
      process.env.REACT_APP_NODE_ENV === 'production' ? selectedPlan?.stripeProdPriceId : selectedPlan?.stripeDevPriceId,
    [selectedPlan]
  );

  const paypalPriceId = useMemo(
    () =>
      process.env.REACT_APP_NODE_ENV === 'production' ? selectedPlan?.paypalProdPriceId : selectedPlan?.paypalDevPriceId,
    [selectedPlan]
  );

  const handleErrors = ({ error, elementType }) => {
    setStripeErrors({ ...stripeErrors, ...{ [elementType]: error?.message } });
  };

  const countryWatch = watch('country');

  useEffect(() => {
    const countryFromFrom = getValues('country');
    setCountry(countryFromFrom);
    setIsShowPostalCode(countryCodesThatRequireZip.includes(countryFromFrom));
  }, [countryWatch, getValues]);

  const removeCoupon = useCallback(() => {
    setValidCoupon(null);
    reset({ coupon: '' });
  }, [reset]);

  useEffect(() => {
    removeCoupon();
  }, [isPlanSelection, removeCoupon]);

  const redirectToThankYou = () => {
    setIsSubmitting(false);
    history.push(redirectRoute);
  };

  const onSubmit = async data => {

    if (!stripe || !elements || Object.keys(errors).length) {
      return;
    }

    setIsSubmitting(true);

    const { postalCode, countryFromFrom } = data;

    const cardElement = elements.getElement(CardNumberElement);
    const paymentMethodData = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: user?.name,
        email: user?.email,
        address: {
          country: countryFromFrom
        }
      }
    };
    if (isShowPostalCode && postalCode) {
      paymentMethodData.billing_details.address.postal_code = postalCode;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod(paymentMethodData);

    if (error) {
      console.error(error);
    } else {
      Mixpanel.track(MixPanelSignUpEvents.ADDED_PAYMENT_METHOD, {
        [MixPanelAddedPaymentMethodProperties.PLAN]: selectedPlan.title,
        [MixPanelAddedPaymentMethodProperties.COUPON_CODE]: validCoupon?.code,
        [MixPanelAddedPaymentMethodProperties.COUPON_OFFER]: validCoupon?.message
      });
    }

    let err;
    try {
      const payload = { paymentMethodId: paymentMethod.id, priceId: stripePriceId, couponId: validCoupon?.couponId };
      await SubscriptionsService.createSubscription(payload);
      redirectToThankYou();
      Mixpanel.track(MixPanelSignUpEvents.TRIAL_STARTED);
    } catch (e) {
      err = e.response?.data?.error;
      setIsSubmitting(false);
    }
    if (err) {
      handleErrors({ error: err, elementType: 'cardNumber' });
    }
  };

  const checkCoupon = async () => {
    let err;
    const couponField = 'coupon';
    const couponCode = getValues(couponField);
    if (!couponCode) {
      return;
    }
    setIsCouponLoading(true);
    try {
      const response = await SubscriptionsService.validateCoupon({ couponCode, priceId: stripePriceId });
      setValidCoupon(response?.data);
    } catch (e) {
      err = e.response;
    } finally {
      setIsCouponLoading(false);
    }
    if (err) {
      const { message } = err?.data?.error;
      setError(couponField, { type: 'manual', message });
      setValidCoupon(null);
      setIsCouponLoading(false);
    }
  };

  const bullets = studioTrialsOnSignup ? [
    { bullet: bullet0 },
    { bullet: bullet1 },
    { bullet: bullet2 },
    { coupon: validCoupon, price: selectedPlan.price },
    { bullet: bullet4 }
  ] : selectedProductType === ProductTypes.MEMBERSHIPS ? [
    { bullet: bullet0 },
    { bullet: bullet5 }
  ] : [
    { bullet: bullet0 }
  ];

  const onPayPalCreateSubscription = useCallback((data, actions) => {
    const withTrial = studioTrialsOnSignup;
    const selectedCountry = getValues('country');

    let paypalCreationParams = {
      plan_id: paypalPriceId,
      custom_id: user.id
    };

    if (studioTrialsOnSignup) {
      const TRIAL_DAYS = 14;
      const trialEnd = moment().add(TRIAL_DAYS, 'days').toISOString();
      paypalCreationParams.start_time = trialEnd;
    }

    if (EuropeanTaxableCountriesEnum.includes(selectedCountry)) {
      paypalCreationParams = {
        ...paypalCreationParams,
        plan: {
          taxes: {
              percentage: PORTUGUESE_TAX,
              inclusive: false
          }
        }
      };
    }

    return actions.subscription.create(paypalCreationParams);
  }, [paypalPriceId, user]);

  const onPayPalPurchaseBundle = async (data, actions) => {
    const selectedCountry = getValues('country');

    dispatch(setIsShowGlobalLoader(true));
    // // Process request
    // const data = getValues();
    // const { paymentsProvider } = getValues();
    // localStorage.setItem(localStorageKeys.SESSIONS_LEFT, getUserCreditsLeftHelper(user).toString());
    try {
      const res = await PaypalService.purchaseBundle(selectedProduct.id, selectedCountry);
      window.location.href = res.data.url;
      dispatch(setIsShowGlobalLoader(false));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }

    // if (EuropeanTaxableCountriesEnum.includes(selectedCountry)) {
    //   paypalCreationParams = {
    //     ...paypalCreationParams,
    //     plan: {
    //       taxes: {
    //           percentage: PORTUGUESE_TAX,
    //           inclusive: false
    //       }
    //     }
    //   };
    // }

    // return actions.subscription.create(paypalCreationParams);
  };//, [paypalPriceId, user]);

  const onPayPalApproved = async (data, actions) => {
    try {
      const { isActive } = (await PaypalService.checkSubscription(data.subscriptionID)).data;
      if (isActive) {
        const redirectRoute = '/thank-you';
        history.push(redirectRoute);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isMembershipPurchase = selectedProductType === ProductTypes.MEMBERSHIPS;

  const onStripeButtonClick = async () => {
    setIsSubmitting(true);
    if (isMembershipPurchase) {
      try {
        const { url } = (await SubscriptionsService.stripeCreateSubscriptionWithCheckoutSession(stripePriceId)).data;
        location.href = url;
      } catch (e) {
        console.error(e);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const { data } = await SubscriptionsService.purchaseBundle(selectedProduct.id);
        const { url } = data;
        location.href = url;
      } catch (e) {
        console.error(e);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const isPaymentOptionSelected = option => paymentOption === option;

  const onPaymentOptionChange = option => () => setPaymentOption(option);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {paypalPayments && <div className={styles.paymentOptionsContainer}>
        <div
          onClick={onPaymentOptionChange(PaymentOptions.CREDIT_CARD)}
          className={classNames(styles.paymentOption, isPaymentOptionSelected(PaymentOptions.CREDIT_CARD) && styles.paymentOptionSelected)}
        >
          <div>
            <div className={classNames(styles.checkbox, styles.checkboxChecked)} />
            <span>{creditCardTextPaymentOption}</span>
          </div>
          <Icon
            name={isPaymentOptionSelected(PaymentOptions.CREDIT_CARD) ? 'creditCardActive' : 'creditCardInactive'} />
        </div>
        <div
          onClick={onPaymentOptionChange(PaymentOptions.PAYPAL)}
          className={classNames(styles.paymentOption, isPaymentOptionSelected(PaymentOptions.PAYPAL) && styles.paymentOptionSelected)}
        >
          <div>
            <div className={classNames(styles.checkbox, styles.checkboxChecked)} />
            <span>{paypalTextPaymentOption}</span>
          </div>
          <Icon
            name={isPaymentOptionSelected(PaymentOptions.PAYPAL) ? 'paypalActive' : 'paypalInactive'} />
        </div>
      </div>}
      {/*<div hidden={paymentOption !== PaymentOptions.CREDIT_CARD}>*/}
      {/*  <div className={styles.customInputContainer}>*/}
      {/*    <label>Card Number</label>*/}
      {/*    <CardNumberElement onChange={handleErrors} className={styles.customInput} options={{ style }} />*/}
      {/*    {stripeErrors?.cardNumber && <InputError color={Colors.ERROR} message={stripeErrors.cardNumber} />}*/}
      {/*  </div>*/}
      {/*  <div className={styles.customMergedInputs}>*/}
      {/*    <div className={styles.customInputContainer}>*/}
      {/*      <label>Expiry Date</label>*/}
      {/*      <CardExpiryElement onChange={handleErrors} className={styles.customInput} options={{ style }} />*/}
      {/*      {stripeErrors?.cardExpiry && <InputError color={Colors.ERROR} message={stripeErrors.cardExpiry} />}*/}
      {/*    </div>*/}
      {/*    <div className={styles.customInputContainer}>*/}
      {/*      <label>CVC</label>*/}
      {/*      <CardCvcElement onChange={handleErrors} className={styles.customInput} options={{ style }} />*/}
      {/*      {stripeErrors?.cardCvc && <InputError color={Colors.ERROR} message={stripeErrors.cardCvc} />}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <StyledSelect*/}
      {/*    className={styles.stripeInput}*/}
      {/*    style={{ marginTop: '16px' }}*/}
      {/*    width={'100%'}*/}
      {/*    height={'51px'}*/}
      {/*    label={'Country'}*/}
      {/*    name={'country'}*/}
      {/*    id={'country'}*/}
      {/*    registerRef={register('country')}*/}
      {/*    errors={[errors.country?.message]}*/}
      {/*    clearErrors={() => clearErrors()}*/}
      {/*    isNoFocus*/}
      {/*    options={countries}*/}
      {/*    labelValueReplacements={['name', 'code']}*/}
      {/*    defaultValue={countries[0].code}*/}
      {/*  />*/}
      {/*  {isShowPostalCode && <StyledInput*/}
      {/*    className={styles.stripeInput}*/}
      {/*    style={{ marginTop: '16px' }}*/}
      {/*    placeholder={postalCodePlaceholders[country]}*/}
      {/*    width={'100%'}*/}
      {/*    height={'51px'}*/}
      {/*    label={'Postal code'}*/}
      {/*    type={StyledInputTypes.TEXT}*/}
      {/*    name={'postalCode'}*/}
      {/*    id={'postalCode'}*/}
      {/*    registerRef={register('postalCode', {*/}
      {/*      required: 'Required',*/}
      {/*      pattern: {*/}
      {/*        value: postalCodeValidators[country],*/}
      {/*        message: 'Invalid postal code'*/}
      {/*      }*/}
      {/*    })}*/}
      {/*    errors={[errors.postalCode?.message]}*/}
      {/*    clearErrors={() => clearErrors()}*/}
      {/*    isNoFocus*/}
      {/*  />}*/}
      {/*</div>*/}
      <div hidden={paymentOption !== PaymentOptions.PAYPAL} className={styles.paypalContainer}>
        {paypalPayments && (
          <>
            <StyledSelect
              className={styles.stripeInput}
              style={{ marginTop: '16px', marginBottom: '16px' }}
              width={'100%'}
              height={'51px'}
              label={'Billing Country'}
              name={'country'}
              id={'country'}
              registerRef={register('country')}
              errors={[errors.country?.message]}
              clearErrors={() => clearErrors()}
              isNoFocus
              options={countries}
              labelValueReplacements={['name', 'code']}
              defaultValue={countries[0].code}
            />

            { EuropeanTaxableCountriesEnum.includes(country) && paymentOption == PaymentOptions.PAYPAL && (
              <div className={styles.VATBreakdownContainer}>
                <VATBreakdown amount={selectedProduct?.price ?? 0} />
              </div>
            )}

            <PayPalButtons
              forceReRender={[paypalPriceId, selectedProduct]}
              style={{ layout: 'horizontal', tagline: false }}
              createSubscription={isMembershipPurchase ? onPayPalCreateSubscription : onPayPalPurchaseBundle}
              onApprove={onPayPalApproved}
            />
          </>
        )}
      </div>
      { showVATBreakdown && paymentOption !== PaymentOptions.PAYPAL && (
        <div className={styles.VATBreakdownContainer}>
          <VATBreakdown amount={selectedProduct?.price ?? 0} />
        </div>
      )}
      {/*<div className={styles.couponContainer}>*/}
      {/*  {isShowCoupon ? (*/}
      {/*    validCoupon ? (*/}
      {/*      <div className={styles.validCouponContainer}>*/}
      {/*        <Icon name={'tags'} />*/}
      {/*        <div className={styles.descriptions}>*/}
      {/*          <p>{validCoupon.code}</p>*/}
      {/*          <span>{validCoupon.message}</span>*/}
      {/*        </div>*/}
      {/*        <span onClick={removeCoupon} className={styles.remove}>*/}
      {/*          {removeText}*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <div className={styles.couponInputContainer}>*/}
      {/*        <div className={styles.couponInput}>*/}
      {/*          <StyledInput*/}
      {/*            style={{ marginTop: '16px' }}*/}
      {/*            placeholder={'COUPON CODE'}*/}
      {/*            width={'100%'}*/}
      {/*            height={'47px'}*/}
      {/*            label={'Enter coupon'}*/}
      {/*            type={StyledInputTypes.TEXT}*/}
      {/*            name={'coupon'}*/}
      {/*            id={'coupon'}*/}
      {/*            registerRef={register('coupon')}*/}
      {/*            errors={[errors.coupon?.message]}*/}
      {/*            clearErrors={() => clearErrors()}*/}
      {/*          >*/}
      {/*            {isCouponLoading ? (*/}
      {/*              <Spinner className={styles.couponSpinner} />*/}
      {/*            ) : (*/}
      {/*              <span className={styles.apply} onClick={checkCoupon}>*/}
      {/*                {applyText}*/}
      {/*              </span>*/}
      {/*            )}*/}
      {/*          </StyledInput>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  ) : (*/}
      {/*    isPaymentOptionSelected(PaymentOptions.CREDIT_CARD) &&*/}
      {/*    <div className={styles.haveACoupon} onClick={() => setIsShowCoupon(true)}>*/}
      {/*      <Icon name={'gift'} />*/}
      {/*      <span>{haveACouponText}</span>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      {isPaymentOptionSelected(PaymentOptions.CREDIT_CARD) &&
      <div className={styles.buttonContainer}>
        <Button_v2
          // isSubmit
          onClick={onStripeButtonClick}
          disabled={!stripe || !elements || isSubmitting}
          width={'100%'}
          height={'60px'}
          buttonSize={ButtonSizes.MEDIUM}
          buttonText={isSubmitting ? submittingButtonText : submitButtonText(studioTrialsOnSignup, selectedProductType)}
        />
      </div>}
      {/*<div className={styles.secureMessageContainer}>*/}
      {/*  <div className={styles.secureMessage}>*/}
      {/*  <span className={styles.secureMessageIcon}>*/}
      {/*  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>*/}
      {/*  <g stroke='#3daf5d' strokeLinecap='round' strokeLinejoin='round'>*/}
      {/*  <g fill='#3daf5d' fillOpacity='.1'>*/}
      {/*  <path*/}
      {/*    d='m13.5 9.5c0 1.5913-.6321 3.1174-1.7574 4.2426-1.1252 1.1253-2.6513 1.7574-4.2426 1.7574s-3.11742-.6321-4.24264-1.7574c-1.12522-1.1252-1.75736-2.6513-1.75736-4.2426v-8l6-1 6 1z' />*/}
      {/*  <path d='m10.5 7.5h-6v4h6z' />*/}
      {/*  <path*/}
      {/*    d='m5.5 7.5v-2c0-.53043.21071-1.03914.58579-1.41421.37507-.37508.88378-.58579 1.41421-.58579s1.03914.21071 1.41421.58579c.37508.37507.58579.88378.58579 1.41421v2' />*/}
      {/*  </g>*/}
      {/*  <path*/}
      {/*    d='m5.5 7.5v-2c0-.53043.21071-1.03914.58579-1.41421.37507-.37508.88378-.58579 1.41421-.58579.53043 0 1.03914.21071 1.41421.58579.37508.37507.58579.88378.58579 1.41421v2' />*/}
      {/*  </g>*/}
      {/*  </svg>*/}
      {/*  </span>*/}
      {/*    <p className={styles.secureMessageText}>Your card details are secured and encrypted*/}
      {/*      by {isPaymentOptionSelected(PaymentOptions.CREDIT_CARD) ? 'Stripe' : 'Paypal'}</p>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={styles.countryWarning}>
        <SecurityCountryFooter country_code={country}/>
      </div>

      {/* <div className={styles.bullets}>
        {bullets.map((bullet, idx) => (
          <div key={idx}>{getBulletElement({ ...bullet })}</div>
        ))}
      </div> */}
    </form>
  );
};

export default CheckoutForm;
