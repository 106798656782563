import { createAsyncThunk } from '@reduxjs/toolkit';

import { ArtistConfigInterface } from '../../interfaces/artist-config.interface';
import MusiciansService from '../../services/musicians/musicians.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getMusicians = createAsyncThunk(
  `${SlicesNames.MUSICIANS_SLICE}/getMusicians`,
  async (): Promise<any> => {
    try {
      const { partners } = (await MusiciansService.getPartnersRequest()).data;
      return partners.map(m => {
        m.image_url = m.image;
        m.bio = m.ShortBio ?? '';
        return m;
      });
    } catch (e) {
      return;
    }
  }
);

export const getInstruments = createAsyncThunk(
  `${SlicesNames.MUSICIANS_SLICE}/getInstruments`,
  async (): Promise<any> => {
    try {
      const { instruments } = (await MusiciansService.getInstrumentsRequest()).data;
      return instruments.map(i => {
        i.musicians = i.musicians.map(m => m.name);
        return i;
      });
    } catch (e) {
      return;
    }
  }
);

export const getCurrentlyBookingArtistConfig = createAsyncThunk(
  `${SlicesNames.MUSICIANS_SLICE}/getCurrentlyBookingArtistConfig`,
  async (slug: string): Promise<ArtistConfigInterface> => {
    try {
      return (await MusiciansService.getArtistConfigRequest(slug)).data[0];
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const getExploreFilters = createAsyncThunk(
  `${SlicesNames.MUSICIANS_SLICE}/getExploreFilters`,
  async (): Promise<any> => {
    try {
      const { services } = (await MusiciansService.getExploreFilters()).data;
      return services;
    } catch (e) {
      return;
    }
  }
);

export const getNextAvailableSessions = createAsyncThunk(
  `${SlicesNames.MUSICIANS_SLICE}/getNextAvailableSessions`,
  async (): Promise<any> => {
    try {
      const response = (await MusiciansService.getNextAvailableSessions()).data;
      return response;
    } catch (e) {
      return;
    }
  }
);
