import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ThreadStatus } from '../../../enums/thread-status.enum';
import { ToastTypes } from '../../../enums/toast-types.enum';
import { useToast } from '../../../hooks/useToast';
import MessagesService from '../../../services/messages/messages.service';
import { getThread } from '../../../store/actions/messages.actions';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { addNewMessage, selectThread, updateThread } from '../../../store/slices/messages.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Spinner from '../../Atoms/Spinner/Spinner';
import styles from './Messages.module.scss';
import SingleMessage from './SingleMessage/SingleMessage';

const Messages = (): ReactElement => {
  const thread = useSelector(selectThread, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);
  const timezone = user?.timezone || moment.tz.guess();
  const dispatch = useDispatch();

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { sendToast } = useToast();

  const [message, setMessage] = useState<string>('');
  const [isSending, setIsSending] = useState(false);

  const isThreadOpened = thread?.status === ThreadStatus.OPEN;

  useEffect((): (() => void) => {
    const ONE_MINUTE_INTERVAL = 60 * 1000;
    const interval = setInterval(async (): Promise<void> => {
      dispatch(getThread({ threadId: thread?.id }));
    }, ONE_MINUTE_INTERVAL);

    return (): void => {
      clearInterval(interval);
    };
  }, [thread, dispatch]);

  if (!thread) {
    return <></>;
  }

  const renderWarning = (): ReactElement => (
    <div className={styles.externalNote}>
      <div className={styles.externalNoteIcon}>
        <Icon name='flag-diagonal' />
      </div>
      <div className={styles.externalNoteBody}>
        {isThreadOpened ? (
          <>
            <h4 className={styles.externalNoteTitle}>Be mindful of the musician’s time</h4>
            <p>
              Before engaging in a conversation, be sure to check our{' '}
              <a href={'https://help.musiversal.com/sessions'} target='_blank' rel='noreferrer'>
                help center
              </a>{' '}
              articles. Use Messages to talk briefly with the artist about your session and music files. For anything
              else, use{' '}
              <a href='https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a' target='_blank' rel='noreferrer'>
                this support form
              </a>{' '}
              (avg response below 24h).
            </p>
          </>
        ) : (
          <h4>
            For further assistance, fill this{' '}
            <a href={'https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a'} target='_blank' rel='noreferrer'>
              form
            </a>
            .
          </h4>
        )}
      </div>
    </div>
  );

  const onTextareaChange = (evt: React.ChangeEvent<HTMLTextAreaElement>): void => setMessage(evt.target.value);

  const sendMessage = async (): Promise<void> => {
    setIsSending(true);
    try {
      const { message: msg } = (await MessagesService.createMessage({ thread_id: thread.id, body: message })).data;
      dispatch(addNewMessage(msg));
      setMessage('');
      textareaRef.current.focus();
    } catch (e) {
      sendToast({ message: 'Failed to send message', toastType: ToastTypes.DANGER });
      console.error(e);
    } finally {
      setIsSending(false);
    }
  };

  const toggleCloseConversation = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const status = thread.status === ThreadStatus.OPEN ? ThreadStatus.CLOSED : ThreadStatus.OPEN;
      const { thread: updatedThread } = (await MessagesService.editThread({ thread_id: thread.id, status })).data;
      dispatch(updateThread(updatedThread));
    } catch (e) {
      sendToast({ message: 'Failure', toastType: ToastTypes.DANGER });
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  return (
    <div className={styles.container}>
      {renderWarning()}
      {isThreadOpened && (
        <div className={styles.message}>
          <textarea
            maxLength={1000}
            className={styles.input}
            id='message'
            name='message'
            value={message}
            onChange={onTextareaChange}
            placeholder={'Your message for the artist here.'}
            ref={textareaRef}
          />
          <Button
            className={styles.button}
            value={isSending ? 'Sending' : 'Send Message'}
            onClick={sendMessage}
            disabled={!message}
          >
            {isSending && <Spinner className={styles.spinner} />}
          </Button>
        </div>
      )}
      <div className={styles.messages}>
        {thread.messages.map((msg, idx) => (
          <div key={`${msg.id}_${idx}`}>
            <SingleMessage {...msg} timezone={timezone} />
          </div>
        ))}
        {!thread.messages.length && <span className={styles.noMessages}>No messages</span>}
      </div>
    </div>
  );
};

export default Messages;
