import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translateUserSubscription } from '../../../../helpers/translate-user-subscription';
import SubscriptionsService from '../../../../services/subscriptions/stripe.service';
import { selectPlans } from '../../../../store/slices/global.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ReactTooltip from 'react-tooltip';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import BillingDetails from './BillingDetails/BillingDetails';
import TopNote from './TopNote/TopNote';
import AttentionMessage from '../../../Atoms/AttentionMessage/AttentionMessage';
import { restartBillingCycleHelper } from '../../../../helpers/restart-billing-cycle.helper';
import { useToast } from '../../../../hooks/useToast';
import { finishTrialHelper } from '../../../../helpers/finish-trial.helper';
import { setIsShowServiceUpdateModal } from '../../../../store/slices/modals.slice';
import { getPlans } from '../../../../store/actions/global.actions';
import { getUserCreditsLeftHelper } from '../../../../helpers/get-user-credits-left.helper';

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

export default function Billing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);

  // useEffect(() => {
  //   if (plans?.length) {
  //     return;
  //   }
  //   dispatch(getPlans(user));
  // }, [dispatch, plans]);

  const {
    sessionsLeft,
    sessionAllowance,
    isTrial,
    paymentsProvider: userPaymentsProvider,
    subscriptionId,
    creditsBank
  } = translateUserSubscription(user);

  const [customerPortalURL, setCustomerPortalURL] = useState(null);
  const [paymentsProvider, setPaymentsProvider] = useState(userPaymentsProvider || PaymentProviders.STRIPE);

  const { sendToast } = useToast();

  useEffect(() => setPaymentsProvider(userPaymentsProvider), [userPaymentsProvider]);

  const getCustomerPortalUrl = async () => {
    try {
      const returnUrl = `${window.location.origin}/profile#billing`;
      const res = await SubscriptionsService.createCustomerPortalSession(returnUrl);
      setCustomerPortalURL(res.data.dashboard_url);
    } catch (err) {
      console.error(err);
    }
  };

  const finishTrial = async () => {
    await finishTrialHelper({ dispatch, user, sendToast, history });
  };

  const restartBillingCycle = async () => {
    if (user.cohorts?.hasExclusivePrices) {
      dispatch(setIsShowServiceUpdateModal(true));
      return;
    }
    await restartBillingCycleHelper({ plans, user, dispatch, stripePromise });
  };

  const redirectToCustomerPortal = () => {
    window.location.href = customerPortalURL;
  };

  const redirectToPaypal = () => {
    const url = process.env.REACT_APP_NODE_ENV === 'production' ?
      'https://www.paypal.com/myaccount/autopay/connect/' :
      'https://www.sandbox.paypal.com/myaccount/autopay/connect/';
    window.location.href = `${url}${subscriptionId}`;
  };

  useEffect(() => {
    getCustomerPortalUrl().finally();
  }, []);

  return (
    <>
    { [UserStatuses.UNSUBSCRIBED, UserStatuses.WAITLISTER, UserStatuses.LEAD, UserStatuses.INVITED].includes(user.status) ? (<></>) : (
      <>
        {/* <AttentionMessage userStatus={user.status}/> */}
        {/* Removed in S20-1124 */}
        {/*
          <TopNote userStatus={user.status} />
        */}
        <BillingDetails
          creditsUsed={sessionAllowance + creditsBank - sessionsLeft}
          creditsTotal={getUserCreditsLeftHelper(user)}
          isTrial={isTrial}
          finishTrial={finishTrial}
          restartBillingCycle={restartBillingCycle}
          paymentsProvider={paymentsProvider}
          redirectToPortal={paymentsProvider === PaymentProviders.PAYPAL ? redirectToPaypal : redirectToCustomerPortal}
        />
        <ReactTooltip place='top' delayHide={200} textColor='#FFF' backgroundColor='#4B5563' effect='solid' html />
        </>
      )}
    </>
  );
}
