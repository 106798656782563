import axios, { AxiosPromise } from 'axios';

import { FileDownloadStatusEnum } from '../../enums/file-download-status.enum';
import { SessionFilesInterface } from '../../interfaces/session-files.interface';
import {
  ConfirmBookingPayloadInterface,
  CreateFileUploadLinkPayloadInterface,
  SendFeedbackPayloadInterface,
  SessionInterface,
  UpdateSessionFilesInfoPayloadInterface
} from '../../interfaces/session.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const getSessionsRequest = (): AxiosPromise => axiosInstance.get('/sessions');

const getSingleSessionRequest = (sessionId: number): AxiosPromise => axiosInstance.get(`/v2/sessions/${sessionId}`);

const getSessionFilesRequest = (sessionId: number, folder: string): AxiosPromise<{ files: SessionFilesInterface[] }> =>
  axiosInstance.get('/sessions/list-files', {
    params: {
      session_id: sessionId,
      folder
    }
  });

const getDownloadLink = (sessionId: number, path: string): AxiosPromise<{ downloadUrl: string; filename: string }> =>
  axiosInstance.get('/files/get-download-link', {
    params: {
      session_id: sessionId,
      path
    }
  });

const getSessionFileDownloadLink = (
  sessionId: number
): AxiosPromise<{ downloadUrl: string; code: FileDownloadStatusEnum }> =>
  axiosInstance.post('/sessions/file-download-link', {
    session_id: sessionId
  });

const approveSession = (sessionId: number): Promise<void> =>
  axiosInstance.post('/sessions/approve', {
    session_id: sessionId
  });

const closeSession = (sessionId: number): Promise<void> =>
  axiosInstance.post('/sessions/close', {
    session_id: sessionId
  });

const cancelSession = (data: { session_id: number; feedback_type: string }): Promise<void> =>
  axiosInstance.post('/v2/sessions/cancel', data);

const afterSessionReview = ({ sessionId, message }: { sessionId: string; message: string }): AxiosPromise =>
  axiosInstance.post('/sessions/after-session-review', {
    session_id: sessionId,
    message
  });

const deleteSessionFiles = (sessionId: number): AxiosPromise =>
  axiosInstance.post('/sessions/delete-session-folder', {
    session_id: sessionId
  });

const confirmBooking = (payload: ConfirmBookingPayloadInterface): AxiosPromise<SessionInterface> =>
  axiosInstance.post('v2/sessions', payload);

const updateSession = (payload: ConfirmBookingPayloadInterface): AxiosPromise<SessionInterface> =>
  axiosInstance.put('v2/sessions', payload);

const updateSessionFilesInfo = (payload: UpdateSessionFilesInfoPayloadInterface): AxiosPromise<SessionInterface> =>
  axiosInstance.post('v2/session_files_info', payload);

const sendFeedback = (payload: SendFeedbackPayloadInterface): AxiosPromise<SessionInterface> =>
  axiosInstance.post('v2/sessions/feedback', payload);

const createFileUploadLink = (
  payload: CreateFileUploadLinkPayloadInterface
): AxiosPromise<{ urls: { url: string; key: string; path: string }[] }> =>
  axiosInstance.post('sessions/file-upload-link', payload);

export default {
  getSessionsRequest,
  getSingleSessionRequest,
  getSessionFilesRequest,
  getDownloadLink,
  afterSessionReview,
  deleteSessionFiles,
  getSessionFileDownloadLink,
  approveSession,
  cancelSession,
  closeSession,
  confirmBooking,
  updateSession,
  updateSessionFilesInfo,
  createFileUploadLink,
  sendFeedback
};
