export const headerTitle = "Let's get started!";
export const headerSubtitle = 'what are you looking for?';
export const linkText = "I'll continue on my own";
export const message = 'You can always choose later';
export const options = [
  {
    id: 1,
    title: 'Book Sessions',
    description: 'I want to directly book sessions with musicians and audio engineers.',
    ilustrationIcon: 'calendar-ilustration',
    action: 'openVideo'
  },
  {
    id: 2,
    title: 'Music Producer',
    description: 'I need a producer who can help me bring out the best in my music.',
    ilustrationIcon: 'headphones-ilustration',
    action: 'openHireProducerModal'
  },
  {
    id: 3,
    title: 'Meet with us',
    description: 'I want to book a meeting with the team to learn more.',
    ilustrationIcon: 'chat-ilustration',
    action: 'openCalendly'
  }
];
export const optionsSongwriter = [
  {
    id: 1,
    title: 'Songwriting Advice',
    description: 'Unlock your song with tips on lyrics, melody, harmony, and structure.',
    image: 'https://dfulleue7uvkm.cloudfront.net/Avatar_183f3403f9.jpg',
    action: 'openArtist',
    artistSlug: 'clare-dove'
  },
  {
    id: 2,
    title: 'Production Advice',
    description: 'Get insights on production strategy, instrument selection, and recording order.',
    image: 'https://unlimited-strapi-prod.s3.eu-west-2.amazonaws.com/Avatar_44_b8cd7798e0.webp',
    action: 'openArtist',
    artistSlug: 'devin-malloy'
  },
  {
    id: 3,
    title: 'Pre-Production',
    description: 'Your booking aid: chord charts and audio stems, ready for sessions.',
    image: 'https://unlimited-strapi-prod.s3.eu-west-2.amazonaws.com/Avatar_01_d839011310.webp',
    action: 'openArtist',
    artistSlug: 'pedro-araujo'
  }
];