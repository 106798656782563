import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../../Icon/Icon';
import styles from './IlustrationButton.module.scss';

interface IlustrationButtonInterface {
  onClick: (args?: any) => void;
  title: string;
  description: string;
  ilustrationIcon: string;
}

const IlustrationButton = ({ onClick, title, description, ilustrationIcon }: IlustrationButtonInterface): ReactElement => (
  <div className={styles.container} onClick={onClick}>
    <Icon name={ilustrationIcon} className={styles.icon} />
    <div className={styles.copy}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.description}>
        {description}
      </div>
    </div>
  </div>
);

export default IlustrationButton;

