import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { selectOnboardingData } from '../../store/slices/global.slice';

import {
  reviewQuote,
  reviewImage,
  reviewName,
  reviewSubtitle,
  reviewIcon
} from './../review-and-quote.const';

import { SplitContainer, QuoteAndReview, Alert } from '@musiversal/musiversal-components';
import { useTrackPageViewedEvent } from '../../hooks/useTrackPageView';
import Icon from '../../components/Atoms/Icon/Icon';
import Button from '../../components/Atoms/v3/Button/Button';
import Input from '../../components/Atoms/v3/Input/Input';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { InputTypes } from '../../enums/input-types.enum';
import AuthService from '../../services/auth/auth.service';
import styles from './ResetPassword.module.scss';
import {
  backToLoginText,
  buttonSubmittingText,
  buttonText,
  header,
  infoText,
  instructionsSentText
} from './reset-password.const';

const ResetPassword = (): ReactElement => {
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  useTrackPageViewedEvent();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [resetInstructionsSent, setResetInstructionsSent] = useState(false);

  const onSubmit = async data => {
    setSubmitting(true);

    const email = data.email?.toLowerCase();

    try {
      const res = await AuthService.resetPasswordInstructions(email);
      if (res.status === 200) {
        setSubmitting(false);
        setResetInstructionsSent(true);
        return;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          <div className={styles.logo}>
            <Icon name={'musiversal-logo'} />
          </div>
          <div className={styles.signupHeader}>
            <h1>{header}</h1>
            <div className={styles.infoText}>{infoText}</div>
          </div>
          <div className={styles.form}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <Input
                placeholder={'Email Address'}
                type={InputTypes.EMAIL}
                name={'email'}
                id={'email'}
                registerRef={register('email', {
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address'
                  }
                })}
                errors={[errors.email?.message, errors.server?.message]}
                clearErrors={() => clearErrors()}
              />
              <div className={styles.button}>
               <Button
                  disabled={submitting}
                  width={'100%'}
                  buttonSize={ButtonSizes.MEDIUM}
                  buttonText={submitting ? buttonSubmittingText : buttonText}
                  onClick={handleSubmit(onSubmit)}
                />
                {resetInstructionsSent && (
                  <Alert
                    body={instructionsSentText}
                    template={'success'}
                  />
                )}
              </div>
            </form>
          </div>
          <Link to='/login' className={styles.backToLogin}>
            {backToLoginText}
          </Link>
        </div>
      }
      rightColumn={
          <QuoteAndReview
            quote={onboardingData?.persona?.Review?.review_copy ?? reviewQuote}
            image={onboardingData?.persona?.Review?.avatar?.url ?? reviewImage}
            name={onboardingData?.persona?.Review?.user_name ?? reviewName}
            subtitle={onboardingData?.persona?.Review?.user_description ?? reviewSubtitle}
            icon={reviewIcon}
          />
      }
    />
  );
};

export default ResetPassword;