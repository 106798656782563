//Components
import { Button } from '@musiversal/musiversal-components';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ToastTypes } from '../../../../enums/toast-types.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { useToast } from '../../../../hooks/useToast';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import UserService from '../../../../services/user/user.service';
import { setIsShowLeadToWaitlisterModal, selectLeadToWatilisterModalParams } from '../../../../store/slices/modals.slice';
import { getMe, getPermissions } from '../../../../store/actions/user.actions';
import { selectUser } from '../../../../store/slices/user.slice';
import { Mixpanel } from '../../../../helpers/mixpanel.helper';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
//Images
import BgDesktop from './BG_Desktop.webp';
import BgMobile from './BG_Mobile.webp';
import HeroDesk from './Hero_Desk.webp';
import HeroMobile from './Hero_Mobile.webp';
//Styles
import styles from './LeadToWaitlisterModal.module.scss';
//Const
import { COPY } from './LeadToWaitlisterModalCopy';

const LeadToWaitlisterModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const { sendToast } = useToast();
  const user = useSelector(selectUser, shallowEqual);
  const { isShown, source } = useSelector(selectLeadToWatilisterModalParams, shallowEqual);

  useEffect(() => {
    if (isShown) {
      Mixpanel.track('LeadToWaitlister Modal Shown', {
        'source': source
      });
    }
  }, [source, isShown]);

  const closeLeadToWaitlisterModal = () => {
    dispatch(setIsShowLeadToWaitlisterModal({ isShown: false }));
  };

  const leadToWaitlistConversion = async () => {
    try {
      const response = await UserService.leadToWaitlistRequest({});
      if (response?.status === 200) {
        sendToast({ message: response?.data?.msg, toastType: ToastTypes.SUCCESS });
        await dispatch(getMe());
        dispatch(setIsShowLeadToWaitlisterModal({ isShown: false }));
      } else {
        sendToast({ message: response?.data?.msg, toastType: ToastTypes.DANGER });
      }
    } catch (error) {
      sendToast({ message: "Couldn't join waitlist", toastType: ToastTypes.DANGER });
    }
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        modalType={ModalTypes.FULLSCREEN}
        onRequestClose={closeLeadToWaitlisterModal}
        className={styles.modal}
      >
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.category}>{COPY.category}</div>
            <h1 className={styles.title}>{COPY.title}</h1>
            <h2 className={styles.subtitle}>
              {COPY.subtitle} <span className={styles.span}>{COPY.price}</span>
            </h2>
            <div className={styles.description}>
              {user.status === UserStatuses.UNSUBSCRIBED ? (
                COPY.unsubscribedMessage
              ) : (
                <>
                  {COPY.defaultDescription}{' '}
                  <a href={COPY.learnMoreLink.href} rel='noreferrer' target='_blank' className={styles.learnMoreLink}>
                    {COPY.learnMoreLink.text}
                  </a>
                </>
              )}
            </div>
            <div className={styles.button}>
              <Button
                onClick={leadToWaitlistConversion}
                className={styles.getFullAccessButton}
                buttonText={COPY.button}
                width={'100%'}
              />
            </div>
          </div>
          <div className={styles.right}>
            <picture>
              <source media='(max-width: 576px)' srcSet={HeroMobile} />
              <img src={HeroDesk} alt='Description' className={styles.rightImage} />
            </picture>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default LeadToWaitlisterModal;
