import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TagManager from 'react-gtm-module';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import Icon from '../../components/Atoms/Icon/Icon';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { parseQuery } from '../../helpers/url.helper';
import { selectUser } from '../../store/slices/user.slice';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { getMe } from '../../store/actions/user.actions';
import styles from './ThankYou.module.scss';
import { bookSessionText, paragraphText } from './thank-you.const';

const ThankYou = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const { studioTrialsOnSignup } = useFlags();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const paramsObj = parseQuery(history.location.search);
    if (paramsObj?.session_id) {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            transaction_id: paramsObj?.session_id,
            value: paramsObj?.product_price,
            currency: 'USD',
            payment_type: paramsObj?.payment_provider,
            items: [
              {
                item_name: paramsObj?.product_name,
                item_category: paramsObj?.product_type,
                price: paramsObj?.product_price,
                quantity: 1
              }
            ]
          },
          credits: paramsObj?.credits / 1
        }

      });
    }
    // if (performance?.navigation.type === 0) {
    // }
  }, [history]);

  const proceedToStudio = async () => {
    dispatch(getMe());
    history.push('/explore');
  };

  useIsPartnerGuard();

  return (
    <div className={styles.container}>
      <SignUpFlowNavbar isFinalStep />
      <div className={styles.leftImage}>
        <div className={styles.bg}/>
      </div>
      <div className={styles.rightImage}>
        <div className={styles.bg}/>
      </div>
      <div className={styles.content}>
        <Icon name={'green-tick'} />
        <h2>Nice, {user.name.split(' ')[0]}!</h2>
        <p>{paragraphText}</p>
        <Button_v2
          onClick={proceedToStudio}
          buttonSize={ButtonSizes.MEDIUM}
          buttonText={bookSessionText(studioTrialsOnSignup)}
        />
      </div>
    </div>
  );
};

export default ThankYou;
