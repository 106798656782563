import React, { ReactElement, useEffect, useState } from 'react';
import { CircleImage, Button } from '@musiversal/musiversal-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import { setIsShowArtistProfileModal, selectShowArtistProfileParams } from '../../../../store/slices/modals.slice';
import cmsService from '../../../../services/cms/cms.service';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import styles from './ArtistProfileModal.module.scss';

const ArtistProfileModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const { isShown, artistSlug, buttonCallback } = useSelector(selectShowArtistProfileParams, shallowEqual);
  const [artistData, setArtistData] = useState({});

  const closeArtistProfileModal = () => {
    dispatch(setIsShowArtistProfileModal({ isShown: false }));
  };

  const goToArtist = () => {
    buttonCallback();
    dispatch(setIsShowArtistProfileModal({ isShown: false }));
  };
  // we fetch the artist data here
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await cmsService.getArtistSimpleRequest(artistSlug);
      setArtistData(data[0]);
    };
    if (isShown) {
      fetchData();
    }
  }, [artistSlug, isShown]);

  return (
      <ModalWrapper isOpen={isOpen} modalType={ModalTypes.DEFAULT} onRequestClose={closeArtistProfileModal}>
        <div className={styles.container}>
          <CircleImage
            image={artistData?.ProfileImage?.url}
            name={artistData?.Name}
            size={144}
          />
          <div className={styles.titleContainer}>
            <div className={styles.name}>{artistData?.Name}</div>
            <div className={styles.title}>{artistData?.Title}</div>
          </div>
          <div className={styles.description}>{artistData?.extended_bio}</div>
          <Button
            className={styles.button}
            onClick={goToArtist}
            variant='secondary'
            size='large'
            width='100%'
            buttonText={`Book a session with ${artistData?.artistShortName}`}
          />
        </div>
      </ModalWrapper>
  );
};

export default ArtistProfileModal;
