import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentProviders } from '../../enums/payment-providers.enum';
import { UserInterface } from '../../interfaces/user.interface';
import { OnboardingDataInterface } from '../../interfaces/onboarding-data.interface';
import CmsService from '../../services/cms/cms.service';
import SubscriptionService from '../../services/subscriptions/stripe.service';
import { getUserCohort } from '../../helpers/cohorts.helper';
import { UserCohorts } from '../../enums/cohorts.enum';
import { PricingPlanInterface } from '../../views/payment-details/pricing-slider/pricing-slider.interface';
import { SlicesNames } from '../slices/slices-names.enum';

export const getPlans = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getPlans`,
  async (user: UserInterface): Promise<PricingPlanInterface[]> => {
    try {
      if (!user) {
        return (await SubscriptionService.getPlansRequest()).data.pricing_plans;
      }
      const cohort = getUserCohort(user);
      const { data } = await SubscriptionService.getPlansRequest();
      const response = {
        memberships: [],
        bundles: [],
        orchestras: []
      };
      switch (cohort) {
        case UserCohorts.PUBLIC:
          response.memberships = data.public.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
          response.bundles = data.public.bundles.map(x => {
            const pp = x.pricing_bundle;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
          response.orchestras = data.public.orchestras.map(x => {
            const pp = x.pricing_external;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        case UserCohorts.CANCELLED:
          return data.cancelled.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        case UserCohorts.MEMBER:
          return data.member.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        default:
          return data.pricing_plans;
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const getCurrentPlan = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getCurrentPlan`,
  async (user: UserInterface): Promise<PricingPlanInterface> => {
    if (!user?.subscription) {
      return;
    }
    try {
      const { currentPrice, paymentsProvider } = user.subscription;
      if (!currentPrice) {
        return null;
      }
      const params = {};
      if (paymentsProvider === PaymentProviders.PAYPAL) {
        params[
          process.env.REACT_APP_NODE_ENV === 'production' ? 'paypalProdPriceId_eq' : 'paypalDevPriceId_eq'
        ] = currentPrice;
      } else {
        params[
          process.env.REACT_APP_NODE_ENV === 'production' ? 'stripeProdPriceId_eq' : 'stripeDevPriceId_eq'
        ] = currentPrice;
      }
      return (await SubscriptionService.getCurrentPlanRequest(params)).data?.[0] || null;
    } catch (e) {
      console.error(e);
    }
  }
);

export const getBannerContent = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getBannerContent`,
  async (): Promise<string> => {
    try {
      return (await CmsService.getBannerContentRequest()).data.body;
    } catch (e) {
      console.error(e);
    }
  }
);

const presentStudioBullet = sb => {
  console.log(sb);
  if (!sb) {
    return;
  }
  const obj = {
    id: sb.id,
    title: sb.bullet,
    description: sb.description,
    icon: sb.icon
  };
  return obj;
};

const presentVideoOrBullet = b => {
  const sb = b?.unlimited_bullet ?? b?.video;
  if (!sb) {
    return;
  }
  if (b?.video) {
    const video = b?.video;
    const videoObj = {
      id: video?.id,
      type: 'PlayerVideo',
      title: video?.title,
      videoId: video?.video_id,
      videoPlatform: video?.video_provider
    };
    return videoObj;
  }
  const obj = {
    id: sb.id,
    type: 'FeatureHighlight',
    title: sb.bullet,
    description: sb.description,
    icon: sb.icon
  };
  return obj;
};

export const getOnboardingData = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getOnboardingData`,
  async (): Promise<OnboardingDataInterface> => {
    try {
      const onboardingD = (await CmsService.getOnboardingDataRequest()).data;
      return {
        joinWaitlist: {
          bullets: (onboardingD?.joinWaitlist?.bullets?.map(b => presentVideoOrBullet(b)))
        },
        signup: {
          bullets: (onboardingD?.signup?.bullets?.map(b => presentVideoOrBullet(b)))
        },
        music_producer: {
          bullets: onboardingD?.music_producer?.bullets?.map(b => presentVideoOrBullet(b))
        },
        persona: onboardingD?.persona,
        starting: onboardingD?.starting
      };
    } catch (e) {
      console.error(e);
    }
  }
);