export const mainTitle = "Welcome to Musiversal! Let's set up your account";
export const timezoneSectionTitle = 'Select your current timezone';
export const timezoneSectionSubtitle = 'We use this  information to set up your booking calendar';
export const personaSurveyTitle = 'How do you identify yourself?';
export const buttonSubmittingText = 'Setting up account...';
export const buttonText = 'Finish setup';

export const personaOptions = [
  {
    id: 1,
    isDefaultChecked: true,
    value: 'professional',
    title: 'Professional Creator',
    description: 'I create a wide variety of music for various mediums such as film, television, video games, and live performance.'
  },
  {
    id: 2,
    isDefaultChecked: false,
    value: 'aspiring',
    title: 'Aspiring Creator',
    description: 'I create music in my spare time with the goal of turning my passion into a full-time career.'
  },
  {
    id: 3,
    isDefaultChecked: false,
    value: 'casual',
    title: 'Casual Creator',
    description: 'I create music for my own enjoyment and personal growth.'
  }
];


