export enum ToastTypes {
  SUCCESS = 'success',
  DANGER = 'danger'
}

export enum ToastPositions {
  BOTTOM_RIGHT = 'bottom_right',
  TOP_RIGHT = 'top_right',
  TOP = 'top'
}
