import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';

import { ButtonSizes } from '../../../../enums/button-sizes.enum';
import { ButtonTypes } from '../../../../enums/button-types.enum';
import Icon from '../../Icon/Icon';
import Spinner from '../../Spinner/Spinner';
import { ButtonInterface } from './button.interface';
import styles from './Button.module.scss';

const Button = ({
  onClick,
  width,
  height,
  buttonType = ButtonTypes.DEFAULT,
  className,
  contentClassname,
  buttonText,
  buttonContent,
  buttonSize,
  isArrow,
  disabled,
  isSubmit
}: ButtonInterface): ReactElement => {
  const buttonTypeClassname = useMemo((): string => {
    switch (buttonType) {
      case ButtonTypes.DEFAULT:
        return styles.default;
      case ButtonTypes.OUTLINE:
        return styles.outline;
      case ButtonTypes.SECONDARY:
          return styles.secondary;
      case ButtonTypes.TERTIARY:
        return styles.tertiary;
      case ButtonTypes.ICON:
        return classnames(styles.secondary, styles.icon);
      default:
        return styles.default;
    }
  }, [buttonType]);

  const buttonSizeClassname = useMemo((): string => {
    switch (buttonSize) {
      case ButtonSizes.LARGE:
        return styles.large;
      // case ButtonSizes.MEDIUM:
      //   return styles.medium;
      case ButtonSizes.SMALL:
        return styles.small;
      default:
        return styles.default;
    }
  }, [buttonSize]);

  const renderContent = (): ReactElement =>
    buttonContent ? (
      buttonContent
    ) : isArrow ? (
      <div className={styles.contentContainer}>
        <span className={classnames(styles.buttonText, contentClassname)}>{buttonText}</span>
      </div>
    ) : (
      <span className={classnames(styles.buttonText, contentClassname)}>{buttonText}</span>
    );

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      className={classnames(
        styles.button,
        isArrow && styles.arrowButton,
        buttonTypeClassname,
        buttonSizeClassname,
        disabled ? styles.disabled : '',
        (onClick || isSubmit) && styles.clickable,
        className
      )}
      style={{ width, height }}
    >
      {/* {disabled && <Spinner className={styles.spinner} />} */}
      {renderContent()}
    </button>
  );
};

export default Button;
