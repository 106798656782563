import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../../Icon/Icon';
import styles from './FilePreview.module.scss';

interface FilePreviewInterface {
  filename: string;
  hasError: boolean;
  sizeInBytes: number;
  onAction?: any;
  actionText?: string;
  errorMessage?: string;
}

const FilePreview = ({ filename, hasError = false, sizeInBytes = 0, onAction, actionText = 'Delete', errorMessage }: FilePreviewInterface): ReactElement => {
  const showBytesInHumanReadableFormat = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  };

  return (
    <span className={classNames(styles.container, hasError && styles.hasError)}>
      <div className={classNames(styles.filenameContainer, hasError && styles.hasError)}>
        <Icon name={hasError ? 'alert-circle' : 'file'} className={classNames(styles.icon, hasError && styles.hasError)} />
        <span className={styles.folderName}>{filename} ({showBytesInHumanReadableFormat(sizeInBytes)})</span>
      </div>
      <div className={styles.actionContainer}>
        { hasError && (
          <div className={styles.errorMessage}>
            {errorMessage}
          </div>
        )}
        <div
          className={classNames(styles.action, hasError && styles.hasError)}
          onClick={onAction}>
            {actionText}
        </div>
      </div>
    </span>
  );
};

export default FilePreview;