import { Toast } from '@musiversal/musiversal-components';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ToastPositions } from 'app/enums/toast-types.enum';

import { useIsMobileResolution } from '../../../../hooks/useIsMobileResolution';
import { hideToast, selectToast } from '../../../../store/slices/toast.slice';
import styles from './ToastPortal.module.scss';

const ToastPortal = (): ReactElement => {
  const dispatch = useDispatch();
  const { toastType, message, show, position } = useSelector(selectToast, shallowEqual);

  const isMobileResolution = useIsMobileResolution();
  const closeToast = () => dispatch(hideToast());

  return (
    <>
      {show && (
        <div className={classNames(styles.toastPosition, styles[isMobileResolution ? ToastPositions.TOP : position])}>
          <Toast toastType={toastType} message={message} onClose={closeToast} />
        </div>
      )}
    </>
  );
};

export default ToastPortal;
