import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserInterface, PermissionsInterface } from '../../interfaces/user.interface';
import UserService from '../../services/user/user.service';
import { SlicesNames } from '../slices/slices-names.enum';
import { Mixpanel } from '../../helpers/mixpanel.helper';

export const getMe = createAsyncThunk(
  `${SlicesNames.USER_SLICE}/getMe`,
  async (): Promise<UserInterface> => {
    try {
      const user = (await UserService.getMeRequest()).data.user;
      if (user?.id) {
        Mixpanel.identify(user?.id);
      }
      return user;
    } catch (e) {
      return;
    }
  }
);

export const getPermissions = createAsyncThunk(
  `${SlicesNames.USER_SLICE}/getPermissions`,
  async (): Promise<PermissionsInterface> => {
    try {
      return (await UserService.getPermissionsRequest()).data;
    } catch (e) {
      return;
    }
  }
);
