import moment from 'moment-timezone';
import React from 'react';

import Icon from '../Icon/Icon';
import styles from './AddToGoogleCalendarButton.module.css';

const AddToGoogleCalendarButton = ({ partnerName, instrument, trackName, start_date, end_date, styling = true }) => {
  const generateGoogleCalendarLink = () =>
    'https://calendar.google.com/calendar/render?action=TEMPLATE&text=' +
    `Musiversal - ${instrument} session with ${partnerName} (${trackName})` +
    '&dates=' +
    `${moment(start_date).format('YYYYMMDDTHHmmss')}` +
    '/' +
    `${moment(end_date).format('YYYYMMDDTHHmmss')}`;
  return (
    <>
      <a
        className={styling ? styles.confirmation__calendarButton : ''}
        href={generateGoogleCalendarLink()}
        target='_blank'
        rel='noreferrer'
      >
        {styling && <Icon name='google-calendar' />}
        Add into Google Calendar
      </a>
    </>
  );
};

export default AddToGoogleCalendarButton;
