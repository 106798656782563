import countryList from 'country-list';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CountrySelect from 'app/components/Atoms/CountryPicker/CountrySelect';

import { editedCountryNames } from '../../../constants/country-list-rename.const';
import { ModalInterface } from '../../../interfaces/modal.interface';
import UserService from '../../../services/user/user.service';
import { setIsShowBillingAddressModal, setIsShowPaywallModal } from '../../../store/slices/modals.slice';
import { hideToast, showToast } from '../../../store/slices/toast.slice';
import { selectUser, setUser } from '../../../store/slices/user.slice';
import { UserStatuses } from '../../../enums/user-statuses.enum';
import Button from '../../Atoms/Button/Button';
import Input from '../../Atoms/Input/Input';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './BillingAddressModal.module.scss';

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData().map(c => ({ value: c.code, label: c.name }));

const BillingAddressModal = ({ isOpen }: ModalInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const { billing_address } = user;
  const country = user.country ?? 'US';
  const { address, vat_number, company_name, zipcode, city } = billing_address || {};
  const dispatch = useDispatch();
  const { register, handleSubmit, control } = useForm<any>({
    defaultValues: {
      country: { value: country, label: countries.find(({ value }) => value === country)?.label },
      vat_number,
      address,
      company_name,
      zipcode,
      city
    } as any
  });

  const sendToast = (message, toastType, delay = 4000) => {
    const payload = {
      message,
      toastType
    };
    dispatch(showToast(payload));
    setTimeout(() => {
      dispatch(hideToast());
    }, delay);
  };

  const onRequestClose = (): void => {
    if (user.status === UserStatuses.INVITED && user.paywall !== 'none') {
      dispatch(setIsShowPaywallModal(true));
    }
    dispatch(setIsShowBillingAddressModal(false));
  };

  const submitInformation = async (data: any): Promise<void> => {
    try {
      const payload = {
        user: {
          billing_address: {
            address: data.address,
            city: data.city,
            company_name: data.company_name,
            country: data.country?.value,
            vat_number: data.vat_number,
            zipcode: data.zipcode
          }
        }
      };
      const { user: updatedUser } = (await UserService.updateBillingAddress(payload)).data;
      dispatch(setUser({ ...user, ...payload.user, ...updatedUser }));
      sendToast('Billing Information updated.', 'success');
      onRequestClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
      <p className={styles.title}>Edit Billing Info</p>
      <form onSubmit={handleSubmit(submitInformation)}>
        <Input
          label={'Company name'}
          className={styles.input}
          register={register('company_name')}
          containerClassName={styles.marginBottom}
        />
        <CountrySelect
          className={styles.select}
          labelClassName={styles.label}
          control={control}
          containerClassName={styles.marginBottom}
          stylesOverride={{
            bgColor: '#ffffff',
            color: 'Billing Country',
            textAlign: 'left',
            fontSize: '16px',
            padding: '12px'
          }}
        />
        <Input
          className={styles.input}
          label={'Address'}
          register={register('address')}
          containerClassName={styles.marginBottom}
        />
        <div className={styles.row}>
          <Input
            className={styles.input}
            label={'City'}
            register={register('city')}
            containerClassName={styles.marginBottom}
          />
          <Input
            className={styles.input}
            label={'ZIP code'}
            register={register('zipcode')}
            containerClassName={styles.marginBottom}
          />
        </div>
        <Input
          className={styles.input}
          label={'VAT number'}
          register={register('vat_number')}
          containerClassName={styles.marginBottom}
        />
        <Button value='Save changes' className={styles.button} />
      </form>
    </ModalWrapper>
  );
};

export default BillingAddressModal;
