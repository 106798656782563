import React, { ReactElement, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import GlobalLoader from '../../components/Atoms/GlobalLoader/global-loader';
import Navbar from '../../components/Atoms/v3/Navbar/Navbar';
import ModalsContainer from '../../components/Molecules/ModalsContainer/ModalsContainer';
import ParallelUpload from '../../components/Molecules/ParallelUpload/parallel-upload';
import StudioBanner from '../../components/Molecules/StudioBanner/StudioBanner';
import { PaywallTypes } from '../../enums/paywall-types.enum';
import { Routes } from '../../enums/routes.enum';
import { UserStatuses } from '../../enums/user-statuses.enum';
import { getReferralData } from '../../store/actions/referral.actions';
import {
  setIsShowOnboardingModal,
  setIsShowTimezoneModal,
  setIsSelectionModal,
  setIsShowWaitlistModal,
  setIsShowPaywallModal,
  setIsShowInvitationExpiredModal,
  setShowPaypalConfirmationModal
} from '../../store/slices/modals.slice';
import { selectReferralData } from '../../store/slices/referral.slice';
import { selectUser } from '../../store/slices/user.slice';
import Explore from '../main/components/explore/Explore';
import Profile from './components/profile/Profile';
import Resources from './components/resources/Resources';
import Sessions from './components/sessions/Sessions';

export const getDefaultRoutePath = (userStatus: string): string => {
  if (userStatus === UserStatuses.LEAD) {
    return Routes.RESOURCES;
  }
  return Routes.EXPLORE;
};

const Main = (): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const referralData = useSelector(selectReferralData, shallowEqual);
  const dispatch = useDispatch();

  useEffect((): void => {
    if (!user.timezone) {
      dispatch(setIsShowTimezoneModal(true));
    }

    if ([UserStatuses.WAITLISTER].includes(user.status)) {
      if (!user.onboarding_survey_id) {
        dispatch(setIsShowOnboardingModal(true));
      } else {
        dispatch(setIsShowWaitlistModal(true));
      }
      // Add at the end of the current URL ?chat=on
      window.history.pushState({}, '', `${window.location.pathname}?unlimited_chat=enabled`);
      return;
    }

    // Show onboarding modal to unsubscribed users
    if (!user.onboarding && !user.onboarding_survey_id && [UserStatuses.UNSUBSCRIBED].includes(user.status)) {
      dispatch(setIsShowOnboardingModal(true));
      return;
    }

    // Paywall Modal
    if ([UserStatuses.INVITED, UserStatuses.UNSUBSCRIBED].includes(user.status) && user.allow_subscribe) {
      // if (user.paywall && user.paywall !== PaywallTypes.NONE && window.location.pathname !== Routes.PAYPAL_CONFIRMATION) {
      if (user.paywall && window.location.pathname !== Routes.PAYPAL_CONFIRMATION) {
        dispatch(setIsShowPaywallModal(true));
      }
      if (window.location.pathname === Routes.PAYPAL_CONFIRMATION) {
        dispatch(setShowPaypalConfirmationModal(true));
        return;
      }
      // Add at the end of the current URL ?chat=on
      window.history.pushState({}, '', `${window.location.pathname}?unlimited_chat=enabled`);
      return;
    }

    if ([UserStatuses.INVITED, UserStatuses.UNSUBSCRIBED].includes(user.status) && !user.allow_subscribe) {
      // Add at the end of the current URL ?chat=on
      window.history.pushState({}, '', `${window.location.pathname}?unlimited_chat=enabled`);
      dispatch(setIsShowInvitationExpiredModal(true));
      return;
    }

    if (!user.onboarding && !user.onboarding_survey_id && [UserStatuses.UNLIMITED].includes(user.status)) {
      dispatch(setIsShowOnboardingModal(true));
      return;
    }

    if (!user.onboarding && user.onboarding_survey_id && [UserStatuses.UNLIMITED].includes(user.status)) {
      //  dispatch(setIsSelectionModal(true));
      return;
    }
  }, [user, dispatch]);

  return (
    <>
      {user.status === UserStatuses.SIGNUP ? (
        <Redirect to='/account-setup' />
      ) : (
        <>
          <StudioBanner />
          <Navbar currentPath='musicians' spaceBottom={false} isTrial={false} />
          <Switch>
            <Route path={Routes.RESOURCES} component={Resources} />
            <Route path={Routes.PROFILE} component={Profile} />
            <Route path={Routes.REFERRAL} component={Profile} />
            <Route path='/sessions/:session_id/:tab' component={Sessions} />
            <Route path='/sessions/:session_id/edit' component={Sessions} />
            <Route path='/sessions/:session_id' component={Sessions} />
            <Route path='/sessions' component={Sessions} />
            <Route path='/offers' component={Explore} />
            <Route path='/musicproduction' component={Explore} />
            <Route path='/subscribe' component={Explore} />
            <Route path='/subscribe-now' component={Explore} />
            <Route path={`${Routes.EXPLORE}/:slug`} component={Explore} />
            <Route path={Routes.EXPLORE} component={Explore} />
            <Route path={Routes.PAYPAL_CONFIRMATION} component={Explore} />
            <Route render={() => <Redirect to={getDefaultRoutePath(user.status)} />} />
          </Switch>
          <GlobalLoader />
          <ParallelUpload />
          <ModalsContainer />
        </>
      )}
    </>
  );
};

export default Main;
