export const TEXT_VALUES = {
  default: {
      imageUrl: '../../../../../assets/images/paywall-modal/ticket-paywall.png',
      title: 'Mics are up, {firstName}!',
      subtitle: "Pick a payment and let's record.",
      icon1: 'check',
      bullet1: 'Your invitation expires on {date}',
      icon2: 'dollar_sign',
      bullet2: '30 Day Money-Back Guarantee',
      icon3: 'check',
      bullet3: 'Cancel Anytime',
      button: 'Proceed to Checkout',
      closeButton: 'Skip'
  },
  discount: {
      imageUrl: '../../../../../assets/images/paywall-modal/ticket.webp',
      title: 'Black Friday Exclusive Deal!',
      subtitle: '25% off the first 3 months',
      icon1: 'check',
      bullet1: 'Offer expires on Dec 5',
      icon2: 'dollar_sign',
      bullet2: '30 Day Money-Back Guarantee',
      icon3: 'check',
      bullet3: 'Cancel Anytime',
      button: 'Claim Black Friday Deal!',
      closeButton: 'Skip Deal'
  }
};