import { Spinner } from '@musiversal/musiversal-components';
import React, { useEffect, useRef, useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes } from 'app/enums/routes.enum';
import { getDefaultRoutePath } from 'app/views/main/Main';
import Resources from 'app/views/main/components/resources/Resources';

import { localStorageKeys } from '../../../../constants/localStorageKeys.const';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { ToastTypes } from '../../../../enums/toast-types.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { useToast } from '../../../../hooks/useToast';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import { getMe } from '../../../../store/actions/user.actions';
import { setShowPaypalConfirmationModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import { RootState } from '../../../../store/store';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import styles from './PaypalConfirmationModal.module.scss';

const INTERVAL_PERIOD_IN_MS = 2000;
const GET_ME_LIMIT = 150;

const PaypalConfirmationModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const { isShowPaypalConfirmationModal } = useSelector((state: RootState) => state.modalsSlice, shallowEqual);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual) || {};
  const { sendToast } = useToast();

  const getMeInterval = useRef();
  const getMeRequestCounter = useRef(0);

  const getMeCallback = useCallback(() => {
    if (getMeRequestCounter.current > GET_ME_LIMIT && user.subscription.paymentsProvider !== PaymentProviders.PAYPAL) {
      dispatch(setShowPaypalConfirmationModal(false));
      clearInterval(getMeInterval.current);
      return;
    }
    if (user.status !== UserStatuses.UNLIMITED) {
      dispatch(getMe());
    } else {
      history.push('/explore');
    }
  }, [dispatch, user.subscription, getMeRequestCounter, history]);

  useEffect(() => {
    if (isShowPaypalConfirmationModal) {
      getMeInterval.current = setInterval(() => {
        getMeRequestCounter.current += 1;
        getMeCallback();
      }, INTERVAL_PERIOD_IN_MS);
    }
    return () => {
      clearInterval(getMeInterval.current);
    };
  }, [location.hash, dispatch, user.subscription, isShowPaypalConfirmationModal, getMeCallback]);

  useEffect(() => {
    const currentUserStatus = localStorage.getItem(localStorageKeys.USER_STATUS);
    const stopInterval = () => {
      clearInterval(getMeInterval.current);
      history.push(getDefaultRoutePath(user.status));
    };

    if (user && user?.status !== currentUserStatus) {
      if (user.status === UserStatuses.UNLIMITED) {
        try {
          TagManager.dataLayer({
            dataLayer: {
              event: 'subscriber_acquired',
              method: 'paypal',
              user_id: user?.id
            }
          });
        } catch (err) {
          console.error(err);
        }
        sendToast({ message: 'Subscription enabled', toastType: ToastTypes.SUCCESS });
      }
      clearInterval(getMeInterval.current);
      dispatch(setShowPaypalConfirmationModal(false));
      localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
      stopInterval();
    }
    //eslint-disable-next-line
  }, [user, location.pathname]);

  const closePaypalConfirmationModal = () => {
    dispatch(setShowPaypalConfirmationModal(false));
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        modalType={ModalTypes.DIALOG}
        isPreventClosing={true}
        onRequestClose={closePaypalConfirmationModal}
      >
        <div className={styles.container}>
          <Spinner bigSize={true} />
          <div className={styles.titleBody}>
            <div className={styles.title}>Your Payment is Being Processed</div>
            <div className={styles.body}>
              {
                "We're currently verifying your transaction with PayPal. This could take a few moments, typically up to 5 minutes."
              }
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default PaypalConfirmationModal;
