import classNames from 'classnames';
import React, { ReactElement } from 'react';
import countryList from 'country-list';

import Icon from '../Icon/Icon';
import styles from './SecurityCountryFooter.module.scss';

interface SecurityCountryFooterInterface {
  country_code: string;
  template: string;
}

const SecurityCountryFooter = ({ country_code = 'US', template = 'dark' }: SecurityCountryFooterInterface): ReactElement => {
  const countries = countryList.getData();
  // const countryName = countries.find(x => x.code == country).name;
  const countryName = countries.find(x => x.code === country_code)?.name;

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Billing Country:
      </div>
      <div className={styles.countryLink}>
        {countryName}
      </div>
      <div className={styles.lockIcon}>
        <Icon name={'full-lock'} />
      </div>
      <div className={styles.text}>
        Secure Checkout
      </div>
    </div>
  );
};

export default SecurityCountryFooter;