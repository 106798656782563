import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import Icon from '../../Icon/Icon';
import styles from './RadioWithIcon.module.scss';

interface RadioWithIconInterface {
  name: string;
  id: string;
  value: any;
  label: string;
  icon: string;
  control: Control<any>;
  containerClassName?: string;
  labelClassName?: string;
  required?: boolean;
  defaultChecked?: boolean;
  infoContent?: string;
}

const RadioWithIcon = ({
  name,
  id,
  value,
  label,
  icon,
  containerClassName,
  labelClassName,
  control,
  required,
  defaultChecked,
  infoContent
}: RadioInterface): ReactElement => (
  <Controller
    control={control}
    name={name}
    rules={{
      required
    }}
    render={({ field: { onChange, value: selectedValue } }) => (
      <div key={id} className={classNames(styles.radio, containerClassName)}>
        <input
          type='radio'
          name={name}
          id={id}
          value={value}
          defaultChecked={defaultChecked}
          onChange={val => onChange(val)}
        />
        <label htmlFor={id}>
          <span className={classNames(styles.label, labelClassName)}>
            {label}
          </span>
          <span className={styles.iconz}>{icon && <Icon name={(value == selectedValue) || (!selectedValue && defaultChecked) ? `${icon}-red` : `${icon}-grey`} height={24} width={24} />}</span>
        </label>
        <ReactTooltip
          clickable
          html
          place='right'
          delayHide={1000}
          textColor='#FFF'
          backgroundColor='#4B5563'
          effect='solid'
        />
      </div>
    )}
  />
);

export default RadioWithIcon;
