import { CustomPhoneInput, Button, ButtonTypes } from '@musiversal/musiversal-components';
import moment from 'moment-timezone';
import React, { ReactElement, useState, useEffect, useMemo } from 'react';
// Monkey patch to get the UI working
import 'react-phone-number-input/style.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import imageUrl from '../../../../../assets/images/waitlist-modal/waitlist-modal.png';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import StrapiService from '../../../../services/cms/cms.service';
import UserService from '../../../../services/user/user.service';
import { setIsShowWaitlistModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import {
  title,
  getBody,
  bodyPhone,
  ctaPhone,
  ctaLink,
  circleURL,
  bodyCall1,
  bodyCall2,
  inviteDate
} from './WaitlistModal.const';
import styles from './WaitlistModal.module.scss';
import { set } from 'lodash';

const WaitlistModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const [defaultCountry, setDefaultCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [hasPhone, setHasPhone] = useState(false);
  const [joinedWaitlistDate, setJoinedWaitlistDate] = useState(null);
  const [waitlistDates, setWaitlistDates] = useState([]);
  const [invitationDate, setInvitationDate] = useState(null);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [waitlistModalData, setWaitlistModalData] = useState(null);

  useEffect(() => {
    const getGeoInfo = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const data = response.data;
        setDefaultCountry(data.country_code);
      } catch (error) {
        console.log(error);
        setDefaultCountry('US');
      }
    };
    const fetchWaitlistStatus = async () => {
      try {
        await getGeoInfo();
        const res = await UserService.getWaitlistStatus();
        if (res?.status === 200) {
          const { data } = res;
          setHasPhone(data?.has_phone);
          setJoinedWaitlistDate(data?.join_date);
          if (!data?.has_phone) {
            getGeoInfo();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getWaitlistData = async () => {
      try {
        const data = (await StrapiService.getWaitlistDataRequest()).data;
        setWaitlistModalData(data);
      } catch (e) {
        console.error(e);
      }
    };

    const getWaitlistDates = async () => {
      try {
        const data = (await StrapiService.getWaitlistDatesRequest()).data;
        const { Date: dates } = data;
        setWaitlistDates(dates);
        // sort array of dates by future dates, filtering out past dates
        const joinedW = moment(joinedWaitlistDate);
        const nextDates = dates
          .filter(d => moment(d.invitation_date).isAfter(moment(joinedWaitlistDate)))
          .sort((a, b) => moment(a.invitation_date).diff(moment(b.invitation_date)));
        // if joinedW is greater than the first deadline then use the second item in the nextDates array
        if (joinedW.isAfter(moment(nextDates[0]?.deadline))) {
          setInvitationDate(moment(nextDates[1]?.invitation_date));
          return;
        }
        const nextDate = nextDates[0];
        setInvitationDate(moment(nextDate?.invitation_date));
        return;
      } catch (e) {
        console.error(e);
      }
    };

    getWaitlistData();
    getWaitlistDates();
    fetchWaitlistStatus();
  }, []);

  const handleGoExternalLink = (url: string) => {
    window.open(url || circleURL, '_blank');
  };

  const nextDate = useMemo(() => {
    if (!joinedWaitlistDate) {
      return;
    }
    const joinedW = moment(joinedWaitlistDate);
    const nextDates = waitlistDates
      ?.filter(d => moment(d.invitation_date).isAfter(joinedW))
      ?.sort((a, b) => moment(a.invitation_date).diff(moment(b.invitation_date)));
    if (joinedW?.isAfter(moment(nextDates[0]?.deadline))) {
      return moment(nextDates[1]?.invitation_date);
    }
    return moment(nextDates[0]?.invitation_date);
  }, [waitlistDates, joinedWaitlistDate]);

  const handleAddNumber = async () => {
    try {
      const phoneInfo = {
        phone: phoneNumber
      };
      const res = await UserService.updatePhoneNumber(phoneInfo);
      if (res?.status === 200) {
        setHasPhone(true);
        dispatch(setIsShowWaitlistModal(false));
      } else {
        setHasPhone(false);
      }
    } catch (err) {
      setHasPhone(false);
    }
  };

  const waitlistData = useMemo(() => (hasPhone ? waitlistModalData?.withPhone : waitlistModalData?.withoutPhone), [
    hasPhone,
    waitlistModalData
  ]);

  const getInviteDurationText = (): string => {
    // get weeks from inviteDate to now
    const invitationExpectedDate = moment(joinedWaitlistDate).add(3, 'weeks').add(1, 'day');
    const weeks = invitationExpectedDate.diff(moment(), 'weeks');
    if (weeks > 1) {
      return getBody(`${weeks} week${weeks > 1 ? 's' : ''}`);
    }
    return 'Your journey begins soon.';
  };

  const applyInviteDateToText = (text: string): string => {
    const token = '$inviteDate';
    return text?.replace(token, nextDate?.format('MMM Do'));
  };

  const closeWaitlistModal = () => {
    dispatch(setIsShowWaitlistModal(false));
  };

  return (
    waitlistModalData && (
      <>
        <ModalWrapper isOpen={isOpen} modalType={ModalTypes.DEFAULT} onRequestClose={closeWaitlistModal}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.image}>
                <img src={imageUrl} alt='Waitlist image' style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            </div>
            <div className={styles.titleTime}>
              <div className={styles.title}>{waitlistData?.title || title}</div>
              <div className={styles.time}>{applyInviteDateToText(waitlistData?.description)}</div>
              <div className={styles.bodyCall}>
                {hasPhone && <div>{waitlistData?.button_description || bodyCall1}</div>}
              </div>
            </div>
            <div className={styles.buttons}>
              {hasPhone ? (
                <>
                  <Button
                    buttonType={ButtonTypes.PRIMARY}
                    buttonText={waitlistData?.button?.label || ctaLink}
                    hasIcon={waitlistData?.button?.showIcon}
                    iconName={waitlistData?.button?.icon}
                    onClick={() => handleGoExternalLink(waitlistData?.button_link)}
                  />
                  <Button
                    buttonType={ButtonTypes.NOBG}
                    buttonText={'Close'}
                    onClick={closeWaitlistModal}
                  />
                </>
              ) : (
                <>
                  <div className={styles.phoneCopy}>{waitlistData?.phone_description || bodyPhone}</div>
                  <div className={styles.phoneButton}>
                    <CustomPhoneInput
                      phoneNumber={phoneNumber}
                      defaultCountry={defaultCountry}
                      setPhoneNumber={setPhoneNumber}
                      setHasStartedTyping={setHasStartedTyping}
                      onCountryChange={() => console.log('country changed')}
                      hasStartedTyping={hasStartedTyping}
                    />
                    <div className={styles.buttons}>
                      <Button
                        buttonText={waitlistData?.button?.label || ctaPhone}
                        hasIcon={waitlistData?.button?.showIcon}
                        iconName={waitlistData?.button?.icon}
                        onClick={handleAddNumber}
                      />
                      <Button
                        buttonType={ButtonTypes.NOBG}
                        buttonText={"I'll do it later"}
                        onClick={closeWaitlistModal}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalWrapper>
      </>
    )
  );
};

export default WaitlistModal;
