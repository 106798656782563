import mixpanel from 'mixpanel-browser';

import { MixPanelEventTypes } from '../enums/mixpanel-events.enum';
import { MixPanelParamMap } from '../interfaces/mixpnale-event-params.interface';

function identifyUser(
  userId: number
): void {
  mixpanel.identify(userId);
}
function track_pageview(
  page: any,
  properties?: any
): void {
  mixpanel.track_pageview({
    page,
    properties
  });
}

function trackEvent(
  type: MixPanelEventTypes,
  params?: MixPanelParamMap[MixPanelEventTypes],
  callback?: (args?: any) => void
): void {
  mixpanel.track(type, params, callback);
}

export const Mixpanel = {
  track: trackEvent,
  identify: identifyUser,
  track_pageview: track_pageview
};
