import { SplitContainer, QuoteAndReview, Button, Input } from '@musiversal/musiversal-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import React, { useEffect, ReactElement, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// import Input from '../../components/Atoms/v3/Input/Input';
import Icon from '../../components/Atoms/Icon/Icon';
import FacebookLogin from '../../components/Atoms/v3/FacebookLogin/FacebookLogin';
import GoogleLoginButton from '../../components/Atoms/v3/GoogleLoginButton/GoogleLoginButton';
import { InputTypes } from '../../enums/input-types.enum';
import { MixPanelSignUpEvents } from '../../enums/mixpanel-events.enum';
import { SignupMethods } from '../../enums/signup-methods.enum';
import { Mixpanel } from '../../helpers/mixpanel.helper';
import { useTrackPageViewedEvent } from '../../hooks/useTrackPageView';
import AuthService from '../../services/auth/auth.service';
import { getOnboardingData } from '../../store/actions/global.actions';
import { getMe } from '../../store/actions/user.actions';
import { selectIsPartnersDashboard, selectOnboardingData } from '../../store/slices/global.slice';
import { reviewQuote, reviewImage, reviewName, reviewSubtitle, reviewIcon } from './../review-and-quote.const';
import { BF23_INVITATION_CODE } from './../signup/signup.const';
import styles from './Login.module.scss';
import {
  signupSuggestion,
  signupLinkText,
  buttonSubmittingText,
  buttonText,
  forgotPasswordText,
  orText,
  welcomeBack,
  rightColumnTitle
} from './login.const';

const typeformWaitlistLink = 'https://musiversal.typeform.com/to/QQpqJtVo';

const Login = (): ReactElement => {
  const history = useHistory();
  useTrackPageViewedEvent();
  const { loginWithFacebook, loginWithGoogle, TypeformWaitlist, bf23 } = useFlags();
  const dispatch = useDispatch();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm();
  const [submitting, setSubmitting] = useState(false);

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
    };

    init().finally();
  }, [dispatch]);

  const redirectRoute = isPartnersDashboard ? '/sessions' : '/';

  const redirectToPrivateArea = (): void => {
    setSubmitting(false);
    history.push(redirectRoute);
  };

  const onSubmit = async data => {
    setSubmitting(true);

    const user = {
      user: {
        email: data.email?.toLowerCase(),
        password: data.password
      }
    };

    try {
      await AuthService.logout();
      const res = await AuthService.login(user);
      localStorage.setItem('timezone', res.data.user.timezone ? res.data.user.timezone : moment.tz.guess());
      await dispatch(getMe());

      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          method: SignupMethods.EMAIL,
          user_id: res?.data?.user?.id
        }
      });

      try {
        Mixpanel.identify(res?.data?.user?.id);
        Mixpanel.track(MixPanelSignUpEvents.LOGIN, {
          user_id: res?.data?.user?.id,
          method: SignupMethods.EMAIL
        });
      } catch (err) {
        console.log('Mixpanel not initialized');
        console.error(err);
      }
      await redirectToPrivateArea();
    } catch (err) {
      if (err?.response?.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else if (err?.response?.status === 422) {
        setError('provider', {
          type: 'manual',
          message: 'Your need to login using your Google or Facebook account.'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later or contact support.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          <div className={styles.logo}>
            <Icon name={'musiversal-logo'} />
          </div>
          <div className={styles.signupHeader}>
            <h1>{welcomeBack}</h1>
            <div className={styles.loginParagraph}>
              <div className={styles.loginMessage}>{signupSuggestion}</div>
              <div className={styles.link}>
                {/* <a
                      href={
                        TypeformWaitlist
                          ? 'https://musiversal.typeform.com/to/QQpqJtVo#lander=login'
                          : '/join-waitlist?from=login'
                      }
                    > */}
                <a href={typeformWaitlistLink}>
                  <span className={styles.login}>{signupLinkText}</span>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.socialLogins}>
              {loginWithFacebook && (
                <FacebookLogin callback={redirectToPrivateArea} setError={setError} buttonText='Login with Facebook' />
              )}
              {loginWithGoogle && (
                <GoogleLoginButton
                  callback={redirectToPrivateArea}
                  setError={setError}
                  buttonText='Login with Google'
                />
              )}
            </div>
            {(loginWithFacebook || loginWithGoogle) && (
              <div className={styles.divider}>
                <div className={styles.divLine} />
                <div className={styles.orText}>{orText}</div>
                <div className={styles.divLine} />
              </div>
            )}
            <form className={styles.formInner} onSubmit={handleSubmit(onSubmit)}>
              <Input
                placeholder={'Email Address'}
                type={InputTypes.EMAIL}
                name={'email'}
                id={'email'}
                registerRef={register('email', {
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address'
                  }
                })}
                errors={[errors.email?.message]}
                clearErrors={() => clearErrors()}
              />
              <Input
                placeholder={'Password'}
                type={InputTypes.PASSWORD}
                name={'password'}
                id={'password'}
                registerRef={register('password', { required: 'Required' })}
                errors={[errors.password?.message, errors.provider?.message, errors.server?.message]}
                clearErrors={() => clearErrors()}
              />
              <div className={styles.forgotPassword}>
                <Link to={'/reset-password'} className={styles.link}>
                  {forgotPasswordText}
                </Link>
              </div>
              <div className={styles.button}>
                <Button
                  isSubmit
                  disabled={submitting}
                  width={'100%'}
                  buttonText={submitting ? buttonSubmittingText : buttonText}
                />
              </div>
            </form>
          </div>
        </div>
      }
      rightColumn={
        <QuoteAndReview
          quote={onboardingData?.persona?.Review?.review_copy ?? reviewQuote}
          image={onboardingData?.persona?.Review?.avatar?.url ?? reviewImage}
          name={onboardingData?.persona?.Review?.user_name ?? reviewName}
          subtitle={onboardingData?.persona?.Review?.user_description ?? reviewSubtitle}
          icon={reviewIcon}
        />
      }
    />
  );
};

export default Login;
