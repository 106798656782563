import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../../Icon/Icon';
import { helpCenterArticle } from './ActiveSessionsCounter.const';
import styles from './ActiveSessionsCounter.module.scss';

interface ActiveSessionsCounterInterface {
  sessionsUsed: number;
  sessionsTotal: number;
}

const MAX_SESSIONS = 4;
const ActiveSessionsCounter = ({ sessionsUsed = 0, sessionsTotal = 0 }: ActiveSessionsCounterInterface): ReactElement => {
  const getIcon = () => {
    switch (sessionsUsed) {
      case 0:
        return 'full-circle';
      case 1:
        return 'one-quarter-circle';
      case 2:
        return 'half-circle';
      case 3:
        return 'three-quarter-circle';
      case 4:
        return 'paused-circle';
      default:
        return 'danger';
    }
    if (sessionsUsed == MAX_SESSIONS) {
      return 'something-else';
    }
    return 'danger';
  };

  const openCenterArticle = () => {
    window.open(helpCenterArticle);
  };

  return (
    sessionsTotal > 0 ? (
      <div className={styles.container}>
        <div className={classNames(styles.counter, sessionsUsed === MAX_SESSIONS && styles.maxedOut)}>
          <div className={styles.icon}>
            <Icon name={getIcon()}/>
          </div>
          <div className={classNames(styles.speed, sessionsUsed === MAX_SESSIONS ? styles.maxedOut : '')}>{sessionsUsed}/{sessionsTotal}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.label}>
            Active sessions
          </div>
          <div className={styles.link} onClick={openCenterArticle} target='_blank' rel='noreferrer'>
            Learn more
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export default ActiveSessionsCounter;

