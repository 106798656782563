import {
  Button,
  Tag,
  TagSizes,
  TagTypes,
  ButtonTypes,
  ButtonSizes,
  ButtonRatingStars
} from '@musiversal/musiversal-components';
import React, { ReactElement } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { CustomerSessionDetailsTabs } from '../../../../enums/customer-session-details-tabs.enum';
import { SessionStatus } from '../../../../enums/session-status.enum';
import { SessionType } from '../../../../enums/session-type.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { useIsMobileResolution } from '../../../../hooks/useIsMobileResolution';
import { setIsShowRatingModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import styles from './Shortcuts.module.scss';

interface ShortcutsInterface {
  session: any;
  feedback?: 'none' | 'filesUploaded' | 'awaitingUpload';
}

const createActionButton = (sessionStatus: SessionStatus, onButtonClick: any) => {
  const buttonProps = {
    hasIcon: true,
    buttonSize: ButtonSizes.SMALL,
    buttonType: ButtonTypes.PRIMARY,
    disabled: false
  };

  switch (sessionStatus) {
    case 'client_files_upload_required':
      return (
        <Button {...buttonProps} onClick={onButtonClick} width='100%' iconName='upload' buttonText='Upload files' />
      );
    default:
      return null;
  }
};

const Shortcuts = ({
  session,
  feedback = 'none'
}: ShortcutsInterface): ReactElement => {
  const isMobileDevice = useIsMobileResolution();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const isRated = session?.rating?.rating;
  const isFinished = session.type !== SessionType.UPCOMING;
  const isCancelled = session?.status === SessionStatus.CANCELLED;
  const partnerFilesAvailable = session?.status === SessionStatus.PARTNER_DELIVERABLES_READY;
  const buttonLabel = isMobileDevice ? (partnerFilesAvailable ? 'Download files' : 'Files pending') : '';

  const onOpenRatingButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (user.status !== UserStatuses.CANCELLED) {
      dispatch(setIsShowRatingModal({ isShown: true, sessionId: session?.id }));
    }
  };

  const onUploadFilesButtonClick = () =>
    history.push(`/sessions/${session?.id}/${CustomerSessionDetailsTabs.FILES_MANAGEMENT}`);

  if (isCancelled) {
    return (
      <div className={styles.container}>
        <Tag label='Cancelled' tagSize={TagSizes.DEFAULT} tagType={TagTypes.GREY} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {isFinished ? (
        <>
          <div className={styles.downloadReview}>
            {partnerFilesAvailable ? (
              <Button
                hasIcon={true}
                onClick={onUploadFilesButtonClick}
                iconName='download'
                buttonText={buttonLabel}
                buttonSize={ButtonSizes.SMALL}
                buttonType={ButtonTypes.TERTIARY}
              />
            ) : (
              <Button
                hasIcon={true}
                iconName='clock'
                buttonText={buttonLabel}
                buttonSize={ButtonSizes.SMALL}
                buttonType={ButtonTypes.TERTIARY}
                disabled={true}
              />
            )}
            <ButtonRatingStars isRated={isRated} rating={isRated && session?.rating?.rating} onOpenRatingButtonClick={onOpenRatingButtonClick} />
          </div>
        </>
      ) : (
        <span className={styles.btns}>{createActionButton(session?.status, onUploadFilesButtonClick)}</span>
      )}
    </div>
  );
};

export default Shortcuts;
