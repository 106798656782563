import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import countryList from 'country-list';

import { localStorageKeys } from '../../../../constants/localStorageKeys.const';
import { EuropeanTaxableCountriesEnum } from '../../../../constants/european-taxable-countries.const';
import { PORTUGUESE_TAX } from '../../../../constants/european-tax.const';
import { editedCountryNames } from '../../../../constants/country-list-rename.const';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { getPlanPriceIdByEnvHelper } from '../../../../helpers/get-plan-price-id-by-env.helper';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import PaypalService from '../../../../services/subscriptions/paypal.service';
import StripeService from '../../../../services/subscriptions/stripe.service';
import { selectPlans, setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { getPlans } from '../../../../store/actions/global.actions';
import { setIsShowSubscriptionModal, setIsShowBillingAddressModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';

import Icon from '../../../Atoms/Icon/Icon';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { showPaymentMethodModalStatuses } from '../../PlansModal/PlansModal';

import RadioWithIcon from '../../../Atoms/v3/RadioWithIcon/RadioWithIcon';
import Button from '../../../Atoms/v3/Button/Button';
import VATBreakdown from '../../../Atoms/v3/VATBreakdown/VATBreakdown';

import styles from './SubscriptionModal.module.scss';

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData();

countries.sort((a, b) => {
  if (a.name === 'United States' ||
    a.name === 'Canada' ||
    a.name === 'United Kingdom') {
    return -1;
  } else if (
    b.name === 'United States' ||
    b.name === 'Canada' ||
    b.name === 'United Kingdom') {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
});

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

const SubscriptionModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const plans = useSelector(selectPlans, shallowEqual);
  const [unlimitedPlan, setUnlimitedPlan] = useState(null);

  useEffect(() => {
    if (plans && plans?.length >= 0 && plans[0]?.memberships?.length) {
      return;
    }
    // if (user.status !== 'staff' && user.status === 'waitlister' && user.status === 'invited') {
      dispatch(getPlans(user));
    // }
  }, [isOpen]);

  useEffect(() => {
    setUnlimitedPlan(plans?.length > 0 ? plans[0] : null);
  }, [plans]);

  const {
    topup_price: topupPrice,
    country: userCountry
  } = user;

  const { handleSubmit, control, getValues, watch, formState: { errors }, clearErrors, register, reset } = useForm({ paymentsProvider: PaymentProviders.STRIPE });

  const ppWatch = watch('paymentsProvider');

  // const setLocalStorageCurrentPlanId = () => localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, unlimitedPlan);

  const onRequestClose = (): void => {
    reset();
    dispatch(setIsShowSubscriptionModal(false));
  };

  const openEditAddress = (): void => {
    // dispatch(setIsShowSubscriptionModal(false));
    dispatch(setIsShowBillingAddressModal(true));
  };

  const showVAT = EuropeanTaxableCountriesEnum.includes(userCountry) ?? false;

  const applyTax = (): void => {
    if (EuropeanTaxableCountriesEnum.includes(userCountry)) {
      return 1 + (PORTUGUESE_TAX / 100);
    }
    return 1;
  };

  const total = (): ReactElement => {
      const crossedOut = unlimitedPlan?.discounted ? (
        <span className={styles.crossedOut}>${(unlimitedPlan?.old_price * applyTax()).toFixed(2)}</span>
      ) : (
        ''
      );
    return (
      <span className={styles.totalPrice}>
        Pay {crossedOut} ${((unlimitedPlan?.price ?? 0) * applyTax()).toFixed(2)} per month
      </span>
    );
  };

  const redirectToPlanUrl = async planId => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const res = await StripeService.createCheckoutSession(planId);
      if (res.status === 200) {
        const stripe = await stripePromise;

        const { sessionId } = res.data;
        // setLocalStorageCurrentPlanId();
        stripe
          .redirectToCheckout({
            sessionId
          })
          .finally();
      }
    } catch (err) {
      console.error(err);
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const onSubscribeClick = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    // Process request
    const data = getValues();
    let { paymentsProvider } = getValues();
    if (!paymentsProvider) {
      paymentsProvider = PaymentProviders.STRIPE;
    }
    try {
      const priceId = getPlanPriceIdByEnvHelper(unlimitedPlan, paymentsProvider);
      if (paymentsProvider === PaymentProviders.PAYPAL) {
        dispatch(setIsShowGlobalLoader(true));
        const { url } = (await PaypalService.createPaypalSubscription(priceId)).data;
        window.location.href = url;
        dispatch(setIsShowGlobalLoader(false));
        return;
      }
      await redirectToPlanUrl(priceId);
      // onRequestClose();
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const paymentOptions = [
    {
      id: PaymentProviders.STRIPE,
      value: PaymentProviders.STRIPE,
      label: 'Credit Card',
      icon: 'credit-card'
    },
    {
      id: PaymentProviders.PAYPAL,
      value: PaymentProviders.PAYPAL,
      label: 'Paypal',
      icon: PaymentProviders.PAYPAL
    }
  ];

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} modalType={ModalTypes.DIALOG} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.headerImage}>
        </div>
        <div className={styles.headerSection}>
          <div className={styles.title}>
            Join Musiversal
          </div>
          <div className={styles.subtitle}>
            <p>You need to subscribe to be able to book sessions.</p>
            <p>Choose your payment method:</p>
          </div>
        </div>
        <div className={styles.paymentOptions}>
          {paymentOptions.map(({ id, label, value, icon }) => (
            <div key={id} className={styles.radioOption}>
              <RadioWithIcon
                required
                name='paymentsProvider'
                control={control}
                id={id}
                value={value}
                label={label}
                icon={icon}
                defaultChecked={value === PaymentProviders.STRIPE}
                labelClassName={styles.reason}
              />
            </div>
          ))}
        </div>
        { showVAT && (
          <div className={styles.VATBreakdown}>
            <VATBreakdown amount={unlimitedPlan?.price} />
          </div>
        )}
        <div className={styles.modalButtons}>
          <Button
            className={styles.payButton}
            onClick={onSubscribeClick}
            width={'100%'}
            buttonText={total()}
          />

          <a className={styles.cancelButton} onClick={onRequestClose}>
            Cancel
          </a>
        </div>
        <div className={styles.footer}>
          <div className={styles.secureCheckout}>
            Billing Country: <a onClick={openEditAddress}>{countries.find(({ code }) => code === userCountry)?.name}</a>
          </div>
          <div className={styles.secureCheckout}>
            <Icon name='lock' className={styles.lockIcon} /> Secure checkout
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SubscriptionModal;
