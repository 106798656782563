import axios, { AxiosPromise } from 'axios';

import { UserCohorts } from '../../enums/cohorts.enum';
import { BillboardDataInterface } from '../../interfaces/billboard-data.interface';
import { BillingAreaDataInterface } from '../../interfaces/billing-area-data.interface';
import { OffboardingModalInterface } from '../../interfaces/offboarding-modal.interface';
import { OnboardingDataInterface } from '../../interfaces/onboarding-data.interface';
import { OnboardingModalInterface } from '../../interfaces/onboarding-modal.interface';
import { PricingV3DataInterface, PricingV4DataInterface } from '../../interfaces/pricing-data.interface';
import { ReferralDataInterface } from '../../interfaces/referral-data.interface';
import { ResourcesPageDataInterface } from '../../interfaces/resources-data.interface';
import { WaitlistModalDataInterface, WaitlisDatesInterface } from '../../interfaces/waitlist-modal-data.interface';

const getOffboardingDataRequest = (): AxiosPromise<OffboardingModalInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/offboarding-modal`);

const getOnboardingModalDataRequest = (): AxiosPromise<OnboardingModalInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/onboarding-modal`);

const getBannerContentRequest = (): AxiosPromise<{ body: string }> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/studio-banner`);

const getOnboardingDataRequest = (): AxiosPromise<OnboardingDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/onboarding`);

const getReferralDataRequest = (): AxiosPromise<ReferralDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/referral`);

const getWaitlistDataRequest = (): AxiosPromise<WaitlistModalDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/waitlist-modal`);

const getWaitlistDatesRequest = (): AxiosPromise<WaitlisDatesInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/waitlist-dates`);

const getResourcesPageRequest = (): AxiosPromise<ResourcesPageDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/resources-page`);

const getPricingPlanFromCodePageRequest = (code: string): AxiosPromise<any> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-plans?invitation_code_eq=${code}`);

const getAnnouncementsRequest = (cohort = 'default'): AxiosPromise<BillboardDataInterface> => {
  switch (cohort) {
    case UserCohorts.CANCELLED:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-cancelled`);
      break;
    case UserCohorts.ONDEMAND: // Unsubscribed
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-ondemand`);
      break;
    case UserCohorts.LEGACY: // Not allowed to subscribe
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-legacy`);
      break;
    case UserCohorts.MEMBER: // Unlimited
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-members`);
      break;
    default:
      // Pending cancellation
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard`);
      break;
  }
};

const getBillingAreaRequest = (): AxiosPromise<BillingAreaDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/billing-area`);

const getPricingV3StudioRequest = (): AxiosPromise<PricingV3DataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-v3?mode=studio`);

const getPricingV4StudioRequest = (): AxiosPromise<PricingV4DataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-v4?mode=studio`);

const getArtistSimpleRequest = (slug: string): AxiosPromise<any> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/artists/${slug}?mode=simple`);

export default {
  getOffboardingDataRequest,
  getBannerContentRequest,
  getResourcesPageRequest,
  getOnboardingDataRequest,
  getWaitlistDataRequest,
  getWaitlistDatesRequest,
  getOnboardingModalDataRequest,
  getReferralDataRequest,
  getAnnouncementsRequest,
  getBillingAreaRequest,
  getPricingV4StudioRequest,
  getPricingPlanFromCodePageRequest,
  getArtistSimpleRequest
};
