import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArtistConfigInterface } from '../../interfaces/artist-config.interface';
import { ServiceInterface } from '../../interfaces/service.interface';
import { NextAvailableDatesInterface } from '../../interfaces/next-available-dates.interface';
import {
  getCurrentlyBookingArtistConfig,
  getExploreFilters,
  getNextAvailableSessions,
  getInstruments,
  getMusicians
} from '../actions/musicians.actions';
import { RootState } from '../store';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  musicians: any[];
  instruments: any[];
  currentlyBookingArtistConfig: ArtistConfigInterface;
  exploreFilters: ServiceInterface[];
  nextAvailableSessions: NextAvailableDatesInterface[];
};

export const musicians = createSlice({
  name: SlicesNames.MUSICIANS_SLICE,
  initialState: {
    musicians: [],
    instruments: [],
    currentlyBookingArtistConfig: null,
    exploreFilters: [],
    nextAvailableSessions: []
  },
  reducers: {},
  extraReducers: {
    [getMusicians.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.musicians = payload;
    },
    [getInstruments.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.instruments = payload;
    },
    [getExploreFilters.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.exploreFilters = payload;
    },
    [getNextAvailableSessions.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.nextAvailableSessions = payload;
    },
    [getCurrentlyBookingArtistConfig.fulfilled.type]: (
      state: SliceState,
      { payload }: PayloadAction<ArtistConfigInterface>
    ): void => {
      state.currentlyBookingArtistConfig = payload;
    }
  }
});

export const selectCurrentlyBookingArtistConfig = (state: RootState) =>
  state.musiciansSlice.currentlyBookingArtistConfig;

export const selectExploreFilters = (state: RootState) => state.musiciansSlice.exploreFilters;

export const selectNextAvailableSessions = (state: RootState) => state.musiciansSlice.nextAvailableSessions;

export default musicians.reducer;
