export const rightColumnTitle =
  'In Harmony with Your Unique Needs';
export const headerTitle = 'Need a Music Producer to Handle Your Project?';
export const formTitle = 'Describe your music vision';
export const trustedByMessage = "Trusted by professionals working for the world's biggest media platforms:";
export const buttonText = 'Request a Quote';
export const submmitingMessage = 'Submitting...';
export const submmitingFilesMessage = 'Uploading files...';
export const message = 'You can always choose later';
export const addOnDisclaimer = 'This an optional service, charged separately from your subscription.';
export const options = [
  {
    id: 1,
    title: 'HireProducerModal',
    description: 'HireProducerModal HireProducerModal HireProducerModal HireProducerModal',
    ilustrationIcon: 'calendar-ilustration',
    action: 'openVideo'
  },
  {
    id: 2,
    title: 'HireProducerModal',
    description: 'HireProducerModal HireProducerModal HireProducerModal HireProducerModal',
    ilustrationIcon: 'headphones-ilustration',
    action: 'openHireProducerModal'
  },
  {
    id: 3,
    title: 'HireProducerModal',
    description: 'HireProducerModal HireProducerModal HireProducerModal HireProducerModal',
    ilustrationIcon: 'chat-ilustration',
    action: 'openCalendly'
  }
];