import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import React, { ReactElement, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { localStorageKeys } from '../../../../../constants/localStorageKeys.const';
import { PaymentProviders } from '../../../../../enums/payment-providers.enum';
import { ModalWarningTypes } from '../../../../../enums/modal-types.enum';
import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { StrapiIcons } from '../../../../../enums/strapi-icons.enum';
import { getUserCreditsLeftHelper } from '../../../../../helpers/get-user-credits-left.helper';
import { useIsMobileResolution } from '../../../../../hooks/useIsMobileResolution';
import { useToast } from '../../../../../hooks/useToast';
import SubscriptionService from '../../../../../services/subscriptions/stripe.service';
import { getOffboardingModalData } from '../../../../../store/actions/modals.actions';
import { selectCurrentPlan, selectPlans, setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import {
  setIsShowOffboardingModal,
  setIsShowSubscriptionModal,
  setIsShowRejoinWaitlistModal,
  setIsShowBillingAddressModal
} from '../../../../../store/slices/modals.slice';
import { executeResumeSubscription } from '../../../../../helpers/paymentActions.helper';
import UserService from '../../../../../services/user/user.service';
import { hideToast, showToast } from '../../../../../store/slices/toast.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import Icon from '../../../../Atoms/Icon/Icon';
import { Alert } from '@musiversal/musiversal-components';
import { WARNING_MESSAGE } from './BillingDetails.const';
import styles from './BillingDetails.module.scss';

interface BillingDetailsInterface {
  creditsUsed: number;
  creditsTotal: number;
  isTrial: boolean;
  finishTrial: () => void;
  restartBillingCycle: () => void;
  redirectToPortal: () => void;
  paymentsProvider: PaymentProviders;
}

const restartTooltipContent =
  'Need more credits? Pay now to restart your billing cycle<br> and get your credits instantly.';
const startTooltipContent =
  'Need more credits? Finish off your trial and get your<br> credits instantly by initiating your membership.';

const showInvoiceStatuses = [UserStatuses.PENDING_CANCELLATION, UserStatuses.UNLIMITED, UserStatuses.WIX_USER];

const BillingDetails = ({
  isTrial,
  finishTrial,
  restartBillingCycle,
  paymentsProvider = PaymentProviders.STRIPE,
  redirectToPortal
}: BillingDetailsInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentPlan = useSelector(selectCurrentPlan, shallowEqual);
  const plans = useSelector(selectPlans, shallowEqual);
  const { restartYourBillingCycleNow, allowTrialCancellations, blackFridaySale } = useFlags();
  const isMobileResolution = useIsMobileResolution();

  const timezone = user.timezone || moment.tz.guess();

  const nextBillingDate = user?.subscription?.next_billing_date;
  const cancellationDate = user?.subscription?.cancellation_date;
  const topupsOnly = [UserStatuses.ONDEMAND, UserStatuses.CANCELLED].includes(user.status);
  const isLegacy = topupsOnly ? false : true; // user.subscription?.legacy; // Now all memberships are legacy in terms of billing area
  const isPendingCancellation = user.status === UserStatuses.PENDING_CANCELLATION;
  const isCancelled = user.status === UserStatuses.CANCELLED;
  const isShowTopUpButton = true; // user.allow_topups;
  const allowTopups = true; // user.allow_topups;
  const sendToast = useToast();

  const { country } = user;

  const { zipcode, address, city, company_name } = user.billing_address || {};

  const resumeSubscription = async (): Promise<void> => {
    await executeResumeSubscription(user, plans, dispatch, history);
  };

  const cancelSubscription = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    await dispatch(getOffboardingModalData());
    dispatch(setIsShowGlobalLoader(false));
    dispatch(setIsShowOffboardingModal(true));
  };

  const changeBillingAddress = (): void => {
    dispatch(setIsShowBillingAddressModal(true));
  };


  const submitWaitlistRequest = async (): Promise<void> => {
    const response = await UserService.rejoinWaitlistNotification({});
    sendToast(response?.data?.msg, 'success');
  };

  const openJoinWaitlistModal = (): void => {
    user?.allow_subscribe ?
    dispatch(setIsShowSubscriptionModal(true)) :
      user.status === UserStatuses.CANCELLED ?
      submitWaitlistRequest() :
      dispatch(setIsShowRejoinWaitlistModal(true));
  };

  // const openPlansModal = (): void => {
  //   if (!isLegacy) {
  //     // if (blackFridaySale) {
  //     //   dispatch(setIsUpsellPlansModal(true));
  //     // } else {
  //     //   dispatch(setIsShowPlansModal(true));
  //     // }
  //     dispatch(setIsUpsellPlansModal(true));
  //   } else {
  //     dispatch(setIsUpsellPlansModal(true));
  //   }
  // };

  // const renderActionButton = (): ReactElement => {
  //   switch (user.status) {
  //     case UserStatuses.TRIAL:
  //       return (
  //         <div className={styles.buttonContainer}>
  //           <div className={styles.tooltip} data-tip={!isMobileResolution ? startTooltipContent : ''}>
  //             <Icon name={'question-mark'} />
  //           </div>
  //           <button className={styles.renewSubscriptionButton} onClick={finishTrial}>
  //             {'Start membership now'}
  //           </button>
  //         </div>
  //       );
  //     case UserStatuses.UNLIMITED:
  //       return (
  //         restartYourBillingCycleNow && (
  //           <div className={styles.buttonContainer}>
  //             <div className={styles.tooltip} data-tip={!isMobileResolution ? restartTooltipContent : ''}>
  //               <Icon name={'question-mark'} />
  //             </div>
  //             <button className={styles.renewSubscriptionButton} onClick={restartBillingCycle}>
  //               {'Restart Billing Cycle now'}
  //             </button>
  //           </div>
  //         )
  //       );
  //     case UserStatuses.PENDING_CANCELLATION:
  //       return (
  //         <>
  //           {paymentsProvider === PaymentProviders.STRIPE && !user.subscription?.legacy && (
  //             <div className={styles.buttonContainer}>
  //               <button className={styles.renewSubscriptionButton} onClick={resumeSubscription}>
  //                 {'Resume membership'}
  //               </button>
  //             </div>
  //           )}
  //         </>
  //       );
  //     // case UserStatuses.CANCELLED:
  //     //   return (
  //     //     <div className={styles.buttonContainer}>
  //     //       <button className={styles.renewSubscriptionButton} onClick={openPlansModal}>
  //     //         {'Start membership'}
  //     //       </button>
  //     //     </div>
  //     //   );
  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <div className={styles.container}>
      { user?.status === UserStatuses.PENDING_CANCELLATION && (
        <div className={styles.alert}>
          <Alert
            icon={'alert-triangle'}
            body={`Your subscription will expire on ${moment(nextBillingDate).tz(timezone).format('MM/DD/YYYY')}. After that, you can't book sessions or access your files. To use our services again, you'll have to join the waitlist.`}
            hasLinkAtEnd={true}
            linkText={'Resume subscription'}
            clickLinkFn={resumeSubscription}
            template={'attention'}
          />
        </div>
      )}

      { user?.status === UserStatuses.CANCELLED && (
        <div className={styles.alert}>
          <Alert
            icon={'alert-triangle'}
            body={`Your subscription expired on ${moment(cancellationDate).tz(timezone).format('MM/DD/YYYY')}. You need to have an active subscription to book sessions and access your files.`}
            hasLinkAtEnd={true}
            linkText={'Join the waitlist.'}
            clickLinkFn={openJoinWaitlistModal}
            template={'attention'}
          />
        </div>
      )}
      <div className={styles.group}>
        <h3 className={styles.title}>
          MANAGE CREDITS
        </h3>

        <div className={styles.options}>
          <div className={styles.item}>
            <div className={styles.actions}>
              <div className={styles.flexItemTitle}>
                Current Plan
                { user?.status === UserStatuses.UNLIMITED && (
                  <a onClick={cancelSubscription} className={styles.smallLink}>
                    Cancel subscription
                  </a>
                )}
                { user?.status === UserStatuses.PENDING_CANCELLATION && (
                  <a onClick={() => resumeSubscription()} className={styles.smallLink}>
                    Resume subscription
                  </a>
                )}
                { user?.status === UserStatuses.CANCELLED && (
                  <a onClick={openJoinWaitlistModal} className={styles.smallLink}>
                    Join waitlist
                  </a>
                )}
              </div>
            </div>

            <div className={styles.planNameContainer}>
              <div className={styles.planName}>{user?.subscription?.plan_name}{user?.status === UserStatuses.CANCELLED && ' (Expired)'}</div>
              { user?.status === UserStatuses.UNLIMITED && (
                <div className={styles.date}>
                  Next Billing Date: {moment(nextBillingDate).tz(timezone).format('MM/DD/YYYY')}
                </div>
              )}
              { user?.status === UserStatuses.PENDING_CANCELLATION && (
                <div className={styles.date}>
                  Expires on: {moment(nextBillingDate).tz(timezone).format('MM/DD/YYYY')}
                </div>
              )}
              { user?.status === UserStatuses.CANCELLED && (
                <div className={styles.date}>
                  Expired on: {moment(nextBillingDate).tz(timezone).format('MM/DD/YYYY')}
                </div>
              )}
            </div>
          </div>

          { user?.status !== UserStatuses.CANCELLED && (
            <>
              <div className={styles.divider} />

              <div className={styles.item}>
                <div className={styles.actions}>
                  <div className={styles.flexItemTitle}>
                    Payment Method:
                    <a onClick={redirectToPortal} className={styles.smallLink}>
                      Edit
                    </a>
                  </div>
                </div>
                <div className={styles.paymentMethod}>
                  <div className={styles.paymentProvider}>
                    {paymentsProvider === PaymentProviders.PAYPAL ? 'PayPal' : 'Credit Card'}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      { user?.status !== UserStatuses.CANCELLED && (
        <div className={styles.group}>
          <h3 className={styles.title}>BILLING INFORMATION</h3>

          <div className={styles.item}>
            <div className={styles.actions}>
              <div className={styles.flexItemTitle}>
                Billing Address:
                <a onClick={changeBillingAddress} className={styles.smallLink}>
                  Edit
                </a>
              </div>
            </div>
            <div className={styles.billingAddress}>
              <div className={styles.companyName}>{company_name}
              </div>
              <div className={styles.address}>
                {`${address}, ${zipcode} ${city}, ${country}`}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        {showInvoiceStatuses.includes(user.status) && (
          <p>
            Do you need an invoice?{' '}
            <span>
              <a rel='noreferrer' href='https://musiversal.typeform.com/to/qTzTovUE' target='_blank'>
                Please fill out this form
              </a>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default BillingDetails;
