import classnames from 'classnames';
import React, { ReactElement, useMemo, useState } from 'react';

import { Colors } from '../../../../enums/colors.enum';
import { InputTypes } from '../../../../enums/input-types.enum';
import Icon from '../../Icon/Icon';
import InputError from '../InputError/InputError';
import { InputInterface } from './Input.interface';
import styles from './TextArea.module.scss';

const TextArea = ({
  label,
  type,
  name,
  id,
  registerRef,
  errors,
  clearErrors,
  width,
  height,
  style,
  placeholder,
  children,
  className,
  isNoFocus,
  isDisabled,
  defaultValue
}: InputInterface): ReactElement => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const toggleIsShowPassword = (): void => setIsShowPassword(!isShowPassword);

  const errs = useMemo((): string[] => errors?.filter(err => !!err), [errors]);

  return (
    <div className={classnames(styles.container, className)} style={style}>
      <div className={classnames(styles.inputContainer, isNoFocus && styles.noFocus, isDisabled && styles.disabled)}>
        <textarea
          className={classnames(errs?.length && styles.hasErrors)}
          id={id}
          type={
            type === InputTypes.PASSWORD && !isShowPassword ? InputTypes.PASSWORD : InputTypes.TEXT
          }
          placeholder={placeholder}
          name={name}
          disabled={isDisabled}
          defaultValue={defaultValue ?? null}
          onClick={clearErrors}
          {...registerRef}
        />
        {children}
      </div>
      {!!errs?.length && (
        <div className={styles.errors}>
          {errs.map((error, idx) => (
            <InputError key={`${error}_${idx}`} message={errors} color={Colors.ERROR} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TextArea;
