import axios, { AxiosPromise } from 'axios';

import { TrackFilesInterface } from '../../interfaces/track-files.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2/track_files/file-upload-link`;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const getTrackFileUploadUrl = (payload: TrackFilesInterface): AxiosPromise<TrackFilesInterface> => axiosInstance.post('', payload);

export default {
  getTrackFileUploadUrl
};
