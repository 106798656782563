export const headerTitle = 'Help & Support';
export const headerSubtitle = '';
export const linkText = "I'll continue on my own";
export const message = 'You can always choose later';
export const options = [
  {
    id: 1,
    title: 'Help center',
    description: "Looking for answers? We've got you covered.",
    ilustrationIcon: 'book-ilustration',
    action: 'openHelpCenter'
  },
  {
    id: 2,
    title: 'Need assistance?',
    description: "Fill out our form and we'll get in touch ASAP",
    ilustrationIcon: 'buoy-ilustration',
    action: 'openSupportForm'
  },
  {
    id: 3,
    title: "Let's meet!",
    description: 'Schedule a meeting with our team to discuss your options',
    ilustrationIcon: 'chat-ilustration',
    action: 'openCalendly'
  }
];