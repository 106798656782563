import { GoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';

import { MixPanelSignUpEvents } from '../../../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../../../enums/mixpanel-properties.enum';
import { SignupMethods } from '../../../../enums/signup-methods.enum';
import { Mixpanel } from '../../../../helpers/mixpanel.helper';

import styles from './GoogleLoginButton.module.scss';

interface GoogleLoginButtonInterface {
  referral_code?: string;
  invitationCode?: string;
  timezone?: string;
  isSignup?: boolean;
  isJoinWaitlist?: boolean;
  isAccess?: boolean;
  urlParams?: any;
  extraParams?: any;
  setError: any;
  callback: any;
  buttonText?: string;
}
const GoogleLoginButton = ({
  buttonText,
  isJoinWaitlist,
  isAccess = false,
  referral_code,
  isSignup,
  setError,
  invitationCode,
  urlParams,
  extraParams,
  timezone,
  callback
}: GoogleLoginButtonInterface): ReactElement => {
  const handleGoogleLogin = async googleData => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/auth/google`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        token: googleData.credential,
        referral_code: referral_code,
        invitation_code: invitationCode,
        query_params: urlParams,
        extraParams,
        isJoinWaitlist,
        isAccess
      }),
      withCredentials: true
    });
    try {
      const response = await res.json();
      if (response.status === 422) {
        setError('provider', {
          type: 'manual',
          message: 'You need to use a different login provider.'
        });
      }
      try {
        Mixpanel.identify(res?.data?.user?.id);
      } catch (err) {
        console.log('Mixpanel not initialized');
        console.error(err);
      }
      try {
        if (isJoinWaitlist) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'waitlister_acquired',
              method: 'google',
              user_id: res?.data?.user?.id
            }
          });
        } else {
          try {
            Mixpanel.track(MixPanelSignUpEvents.LOGIN, {
              user_id: res?.data?.user?.id,
              method: SignupMethods.GOOGLE
            });
          } catch (err) {
            console.log('Mixpanel not initialized');
            console.error(err);
          }
        }
      } catch (err) {
        console.error(err);
      }
      await callback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleError = error => {
    setError({ error });
  };

  // const onButtonClick = () => {
  //   // if (isSignUp) {
  //   //   // Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
  //   //   //   [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.GOOGLE
  //   //   // });
  //   //   console.log('');
  //   // }
  // };

  return (
    // <div className={styles.container} onClick={onButtonClick}>
    <div className={styles.container}>
      <GoogleLogin
        onSuccess={handleGoogleLogin}
        onError={handleGoogleLogin}
        size={'large'}
        width={'100%'}
        text={'continue_with'}
      />
      {/* <GoogleLogin
        render={renderProps => (
          <button onClick={renderProps.onClick} className={styles.button} disabled={renderProps.disabled}>
            <div className={styles.buttonIcon}>
              <Icon name='google' />
            </div>
            {props.buttonText ? props.buttonText : 'Sign Up with Google'}
          </button>
        )}
        clientId={process.env.REACT_APP_GOOGLE_ID}
        buttonText='Sign In with Google'
        onSuccess={handleGoogleLogin}
        onFailure={handleGoogleLogin}
        cookiePolicy={'single_host_origin'}
      /> */}
    </div>
  );
};

export default GoogleLoginButton;
