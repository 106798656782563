import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

import axios, { AxiosRequestConfig } from 'axios';

import { SessionFilesStatus } from '../../enums/session-files-status.enum';
import { SessionType } from '../../enums/session-type.enum';
import { UploadStatus } from '../../enums/upload-status.enum';
import SessionsService from '../../services/sessions/sessions.service';
import { setSession } from '../slices/sessions.slice';
import { SlicesNames } from '../slices/slices-names.enum';
import { addUpload, updateUpload } from '../slices/uploads.slice';
import { getSingleSession } from './sessions.actions';

interface UploadFileInterface {
  fileToUpload;
  url: string;
  session: Record<string, any>;
}

export const uploadFile = createAsyncThunk(
  `${SlicesNames.UPLOADS_SLICE}/uploadFile`,
  async ({ fileToUpload, url, session }: UploadFileInterface, thunkAPI): Promise<void> => {
    const id = v4();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { id: sessionId } = session;

    const uploadedFile = {
      id,
      status: UploadStatus.UPLOADING,
      fileName: fileToUpload.name,
      loaded: 0,
      total: fileToUpload.size,
      sessionId,
      filesCount: 1,
      completedFilesCount: 0,
      uploadTimestamp: Date.now(),
      source
    };

    try {
      const onUploadProgress = async ({ loaded, total }: ProgressEvent) => {
        const isCompleted = loaded >= uploadedFile.total;
        const progressFile = {
          ...uploadedFile,
          loaded,
          total,
          status: isCompleted ? UploadStatus.COMPLETED : UploadStatus.UPLOADING
        };
        await thunkAPI.dispatch(updateUpload(progressFile));

        if (isCompleted) {
          const { type: sessionType } = session;

          const status =
            sessionType === SessionType.PAST
              ? SessionFilesStatus.SESSION_FILES_UPLOADED
              : SessionFilesStatus.PRESESSION_UPLODED;

          const updatePayload = {
            session_id: sessionId,
            files_info: {
              status
            }
          };

          await SessionsService.updateSessionFilesInfo(updatePayload);
          await thunkAPI.dispatch(getSingleSession(sessionId));
        }
      };

      const fileToUploadConfig = {
        method: 'put',
        url,
        headers: {
          'Content-Type': fileToUpload.type
        },
        data: fileToUpload,
        onUploadProgress
      };

      await thunkAPI.dispatch(addUpload(uploadedFile));
      await axios(fileToUploadConfig as AxiosRequestConfig);
    } catch (e) {
      const progressFile = { ...uploadedFile, status: UploadStatus.FAILED };
      await thunkAPI.dispatch(updateUpload(progressFile));
      console.error(e);
      throw new Error(e);
    }
  }
);

export const uploadTrackFile = createAsyncThunk(
  `${SlicesNames.UPLOADS_SLICE}/uploadTrackFile`,
  async ({ fileToUpload, url, trackId }: UploadFileInterface, thunkAPI): Promise<void> => {
    const id = v4();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const uploadedFile = {
      id,
      status: UploadStatus.UPLOADING,
      fileName: fileToUpload.name,
      loaded: 0,
      total: fileToUpload.size,
      trackId,
      filesCount: 1,
      completedFilesCount: 0,
      uploadTimestamp: Date.now(),
      source
    };

    try {
      // const onUploadProgress = async ({ loaded, total }: ProgressEvent) => {
      //   const isCompleted = loaded >= uploadedFile.total;
      //   const progressFile = {
      //     ...uploadedFile,
      //     loaded,
      //     total,
      //     status: isCompleted ? UploadStatus.COMPLETED : UploadStatus.UPLOADING
      //   };
      //   await thunkAPI.dispatch(updateUpload(progressFile));

      //   if (isCompleted) {
      //     console.log('Upload complete');
      //   }
      // };

      const fileToUploadConfig = {
        method: 'put',
        url,
        headers: {
          'Content-Type': fileToUpload.fileType
        },
        data: fileToUpload
      };
      // await thunkAPI.dispatch(addUpload(uploadedFile));
      const r = await axios(fileToUploadConfig as AxiosRequestConfig);
    } catch (e) {
      console.log('Error', e);
      const progressFile = { ...uploadedFile, status: UploadStatus.FAILED };
      await thunkAPI.dispatch(updateUpload(progressFile));
      console.error(e);
      throw new Error(e);
    }
  }
);
