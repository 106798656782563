import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactElement } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getUserCreditsLeftHelper } from '../../../../../helpers/get-user-credits-left.helper';
import { ConfirmBookingPayloadInterface, SessionInterface } from '../../../../../interfaces/session.interface';
import { SlotInterface } from '../../../../../interfaces/slot.interface';
import TracksService from '../../../../../services/tracks/tracks.service';
import { getSingleSession, getTracks } from '../../../../../store/actions/sessions.actions';
import { getMe } from '../../../../../store/actions/user.actions';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import { setSession } from '../../../../../store/slices/sessions.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import Button from '../../../../Atoms/Button/Button';
import FileUploadsWarning from '../../../../Atoms/FileUploadsWarning/FileUploadsWarning';
import PreConfirmationDetails from './components/PreConfirmationDetails/PreConfirmationDetails';
import Icon from '../../../../Atoms/Icon/Icon';
import {
  onBookSessionFormSubmit,
  prepareDeliverablesInfo,
  prepareTrackDetailsInfo
} from '../../utils/bookSessionForm.utils';
import { DEFAULT_FILES_DELIVERY } from './PreConfirmation.const';
import styles from './PreConfirmation.module.scss';

interface PreConfirmationInterface {
  session: SessionInterface;
  primarySlot: SlotInterface;
  musicianConfiguration: any;
  onBookingConfirmation: () => void;
  onGoBack: () => () => void;
  getValues: UseFormGetValues<any>;
}

const PreConfirmation = ({
  session,
  primarySlot,
  musicianConfiguration,
  onBookingConfirmation,
  onGoBack,
  getValues
}: PreConfirmationInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const { services: { servicesList }, Name: partnerName } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const { service } = getValues();
  const timezone = user.timezone;

  const selectedService = servicesList.filter(x => x.value === service)[0];
  const filesDelivery = servicesList.filter(x => x.value === service)[0]?.files_delivery ?? DEFAULT_FILES_DELIVERY;

  const { credits, duration, start_date } = session || {};

  const onConfirmBooking = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    const data = getValues();

    const { track_title: trackTitle, message: messageBody } = data;
    const trackDetailsInfo = prepareTrackDetailsInfo({
      data,
      session,
      extraConfig: musicianConfiguration
    });
    const deliverablesInfo = prepareDeliverablesInfo({
      data,
      session
    });

    const info = { ...deliverablesInfo } as ConfirmBookingPayloadInterface;

    if (trackTitle?.__isNew__ || trackTitle?.addedOnBlur) {
      try {
        const track = (await TracksService.createTrack(trackDetailsInfo.track)).data;
        info.track_id = track.id;
      } catch (e) {
        console.error(e);
      }
    } else {
      info.track_id = trackTitle?.value;
    }

    info.session_info = trackDetailsInfo.session_info;

    try {
      const message = {
        body: messageBody,
        thread_id: session.thread_id
      };
      const updatedSession = await onBookSessionFormSubmit({
        info,
        message
      });
      dispatch(setSession(updatedSession));
      await dispatch(getMe());
      await dispatch(getSingleSession(session.id));
      await dispatch(getTracks());
      onBookingConfirmation();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon name='calendar-question' />
        </div>
        <h1>
          You are about to book a session, please review the details before continuing
        </h1>

        {/* <Alert
          icon={'calendar'}
          body={`You must upload your files 24h prior to the session.`}
          hasLinkAtEnd={true}
          linkText={'Learn more'}
          clickLinkFn={openJoinWaitlistModal}
          template={'attention'}
        /> */}

        <div className={styles.preConfirmationDetails}>
          {selectedService.files_deadline_enabled === true && (
            <div className={styles.filesDeadlineWarning}>
              <FileUploadsWarning
                filesDeadline={selectedService.files_deadline}
              />
            </div>
          )}

          <PreConfirmationDetails
            session={session}
            credits={credits}
            creditsRemaining={getUserCreditsLeftHelper(user) - credits}
            getValues={getValues}
            serviceSessionType={selectedService?.session_type}
            filesDelivery={filesDelivery}
            timezone={timezone}
            selectedService={selectedService}
          />
        </div>
        <div className={styles.confirmation__actions}>
          <a className={styles.sessionForm__actionsButtonOutline}
            onClick={onGoBack} data-cy={'booking-back-button'}>
            {'<- Back'}
          </a>
          <Button
            data-cy={'booking-confirm-button'}
            className={classNames(styles.confirmButton, credits === 2 && styles.buttonNoMargin)}
            onClick={onConfirmBooking}
            value={'Confirm Booking'}
          />
        </div>
      </div>
    </div>
  );
};

export default PreConfirmation;
