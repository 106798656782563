import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store/store';

import { isPartnersDashboard } from '../../utils/scopeLimit';
import { PricingPlanInterface } from '../../views/payment-details/pricing-slider/pricing-slider.interface';
import { OnboardingDataInterface } from '../../interfaces/onboarding-data.interface';
import { getBannerContent, getCurrentPlan, getPlans, getOnboardingData } from '../actions/global.actions';
import { SlicesNames } from './slices-names.enum';
import { ProductTypes } from '../../enums/product-types.enum';

type SliceState = {
  isShowGlobalLoader: boolean;
  plans: PricingPlanInterface[];
  plansByPeriod: PricingPlanInterface[];
  selectedPlan: PricingPlanInterface;
  selectedProductType: ProductTypes;
  currentPlan: PricingPlanInterface;
  isPartnersDashboard: boolean;
  bannerContent: string;
  isReferralCodeValid: boolean;
  bannerHeight: number;
  onboardingData: OnboardingDataInterface;
  onboardingModalData: any;
};

export const global = createSlice({
  name: SlicesNames.GLOBAL_SLICE,
  initialState: {
    isShowGlobalLoader: false,
    plans: [],
    plansByPeriod: [],
    selectedPlan: null,
    selectedProduct: null,
    selectedProductType: null,
    currentPlan: null,
    isPartnersDashboard: isPartnersDashboard(),
    isShowFilesModal: false,
    bannerContent: null,
    isReferralCodeValid: false,
    bannerHeight: 0,
    onboardingData: null,
    onboardingModalData: null
  },
  reducers: {
    setIsShowGlobalLoader: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowGlobalLoader = payload;
    },
    setPlansByPeriod: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface[]>): void => {
      state.plansByPeriod = payload;
    },
    setSelectedPlan: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface>): void => {
      state.selectedPlan = payload;
    },
    setSelectedProduct: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface>): void => {
      state.selectedProduct = payload;
    },
    setSelectedProductType: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface>): void => {
      state.selectedProductType = payload;
    },
    setIsReferralCodeValid: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isReferralCodeValid = payload;
    },
    setBannerHeight: (state: SliceState, { payload }: PayloadAction<number>): void => {
      state.bannerHeight = payload;
    },
    setOnboardingData: (state: SliceState, { payload }: PayloadAction<OnboardingDataInterface>): void => {
      state.onboardingData = payload;
    },
    setOnboardingModalData: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.onboardingModalData = payload;
    }
  },
  extraReducers: {
    [getPlans.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface[]>): void => {
      state.plans = payload;
    },
    [getCurrentPlan.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<PricingPlanInterface>): void => {
      state.currentPlan = payload;
    },
    [getBannerContent.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<string>): void => {
      state.bannerContent = payload;
    },
    [getOnboardingData.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<OnboardingDataInterface>): void => {
      state.onboardingData = payload;
    }
  }
});

export const {
  setIsShowGlobalLoader,
  setPlansByPeriod,
  setSelectedPlan,
  setSelectedProduct,
  setSelectedProductType,
  setIsReferralCodeValid,
  setBannerHeight
} = global.actions;
export const selectSelectedPlan = (state: RootState) => state.globalSlice.selectedPlan;
export const selectSelectedProduct = (state: RootState) => state.globalSlice.selectedProduct;
export const selectSelectedProductType = (state: RootState) => state.globalSlice.selectedProductType;
export const selectIsShowGlobalLoader = (state: RootState) => state.globalSlice.isShowGlobalLoader;
export const selectPlans = (state: RootState) => state.globalSlice.plans;
export const selectCurrentPlan = (state: RootState) => state.globalSlice.currentPlan;
export const selectIsPartnersDashboard = (state: RootState) => state.globalSlice.isPartnersDashboard;
export const selectBannerContent = (state: RootState) => state.globalSlice.bannerContent;
export const selectIsReferralCodeValid = (state: RootState) => state.globalSlice.isReferralCodeValid;
export const selectBannerHeight = (state: RootState) => state.globalSlice.bannerHeight;
export const selectOnboardingData = (state: RootState) => state.globalSlice.onboardingData;

export default global.reducer;
