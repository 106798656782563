import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NavTabs, Spinner, CardResource } from '@musiversal/musiversal-components';

import { selectUser } from '../../../../store/slices/user.slice';
import StrapiService from '../../../../services/cms/cms.service';
import AlertsHandler from './../../../../components/Molecules/v3/AlertsHandler/AlertsHandler';
import { setIsVideoModal, setIsShowLeadToWaitlisterModal } from '../../../../store/slices/modals.slice';
import { figureOutVideoPlatform, getVideoId } from '../../../../helpers/video.helper';
import { ResourceTypes } from '../../../../enums/resources.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { Mixpanel } from '../../../../helpers/mixpanel.helper';

/* Styles */
import styles from './Resources.module.scss';

const Resources = (): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const [selectedTab, setSelectedTab] = useState(0);
  const [resourceTabs, setResourceTabs] = useState([]);
  const [loadingResources, setLoadingResources] = useState(true);

  const getResources = async () => {
    try {
      const { data } = await StrapiService.getResourcesPageRequest();
      setResourceTabs(data.ResourcesTab);
      setLoadingResources(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getResources();
  }, []);

  const changeSelectedTab = useCallback(
    selection => {
      if (selection === selectedTab) {
        return;
      }
      setSelectedTab(selection);
      try {
        if (selection) {
          Mixpanel.track('Resource Section Clicked', {
            'Section': resourceTabs[selection]?.name,
            'User Status': user?.status
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [selectedTab, resourceTabs]
  );

  useEffect(() => {
    // we select a tab depending on the slug in the URL
    const slug = window.location.pathname.split('/').pop();
    const index = resourceTabs.findIndex(rt => rt.slug === slug);
    if (index !== -1) {
      setSelectedTab(index);
    }
  }, [resourceTabs]);

  const downloadResourceOnClick = useCallback(
    async resource => {
      try {
        window.open(resource.files_url, '_blank');
        if (user?.status  === UserStatuses.LEAD) {
          dispatch(setIsShowLeadToWaitlisterModal({ isShown: true, source: 'file_resource' }));
        }
      } catch (e) {
        console.error(e);
      }
    }, []);


  const openVideoModalOnClick = useCallback(
    async resource => {
      try {
        dispatch(setIsVideoModal({
          isShown: true,
          videoOnly: true,
          videoId: getVideoId(resource.video_url),
          videoPlatform: figureOutVideoPlatform(resource.video_url),
          afterCloseCallback: () => {
            if (user?.status === UserStatuses.LEAD) {
              dispatch(setIsShowLeadToWaitlisterModal({ isShown: true, source: 'video_resource' }));
            }
          }
        }));
      } catch (e) {
        console.error(e);
      }
    }, []);

  const onResourceClick = useCallback(
    async resource => {
      try {
        Mixpanel.track('Resource Clicked', {
          'Section': resourceTabs[selectedTab]?.name,
          'Resource': resource.name,
          'Resource Type': resource.resource_type,
          'Resource Category': resource.category,
          'Resource ID': resource.id,
          'User Status': user?.status
        });
      } catch (err) {
        console.error(err);
      }
      if (resource.resource_type === ResourceTypes.DOWNLOAD) {
        return downloadResourceOnClick(resource);
      }
      return openVideoModalOnClick(resource);
    }
  , [resourceTabs, selectedTab]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <AlertsHandler section='resources' />
        {loadingResources ? (
          <Spinner className={styles.spinner} bigSize={true} />
        ) : (
          resourceTabs && (
            <NavTabs
              tabs={resourceTabs?.map(rt => ({
                label: rt.name,
                badge: `${rt?.resources_list?.length || 0}`,
                selected: resourceTabs.indexOf(rt) === selectedTab,
                onClick: () => changeSelectedTab(resourceTabs.indexOf(rt))
              }))}
            />
          )
        )}
        <div className={styles.resourcesList}>
          {resourceTabs && resourceTabs[selectedTab]?.resources_list?.map(({ resource }) => (
            <CardResource
              id={resource.id}
              key={resource.id}
              isNew={resource.is_new}
              isExclusive={false}
              resourcesType={resource.resource_type}
              category={resource.category}
              name={resource.name}
              description={resource.description}
              image={resource?.resource_image?.url}
              onButtonClick={() => onResourceClick(resource)}
            />
          ))}
        </div>
        {/* <ToastPortal /> */}
      </div>
    </div>
  );
};

export default Resources;
