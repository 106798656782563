import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Logout from './components/Atoms/Logout/Logout';
import { Routes } from './enums/routes.enum';
import { Mixpanel } from './helpers/mixpanel.helper';
import { useTrackPageViewedEvent } from './hooks/useTrackPageView';
import { getBannerContent, getOnboardingData } from './store/actions/global.actions';
import { getLimitations } from './store/actions/limitations.actions';
import { getMe, getPermissions } from './store/actions/user.actions';
import { selectUser } from './store/slices/user.slice';
import Access from './views/access/Access';
import AccountSetup from './views/account-setup/AccountSetup';
import JoinWaitlist from './views/join-waitlist/JoinWaitlist';
import Login from './views/login/Login';
import Main from './views/main/Main';
import PasswordValidate from './views/password-validate/PasswordValidate';
import PaymentDetails from './views/payment-details/PaymentDetails';
import ResetPassword from './views/reset-password/ResetPassword';
import Signup from './views/signup/Signup';
import ThankYou from './views/thank-you/ThankYou';

const GuarderRoutes = (): ReactElement => {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUser, shallowEqual);
  useTrackPageViewedEvent();

  const redirectRoute = location.pathname.includes('/explore') ? 'login' : 'login';

  useEffect((): void => {
    const getData = async (): Promise<void> => {
      await dispatch(getMe());
      await dispatch(getPermissions());
      await dispatch(getLimitations());
      await dispatch(getBannerContent());
      setIsReady(true);
    };

    const init = async (): Promise<void> => {
      if (user) {
        Mixpanel.identify(user.id);
        setIsReady(true);
        return;
      }
      await getData();
    };

    init().finally();
  }, [user, dispatch]);

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
    };

    init().finally();
  }, [dispatch]);

  return (
    isReady && (
      <>
        {user ? (
          <Switch>
            <Route path='/thank-you' component={ThankYou} />
            <Route path='/account-setup' component={AccountSetup} />
            <Route path='/payment-details' component={PaymentDetails} />
            <Route path='/logout' component={Logout} />
            <Main />
            <Route render={(): ReactElement => <Redirect to='/' />} />
          </Switch>
        ) : (
          <Redirect to={`/${redirectRoute}${location.search}`} />
        )}
      </>
    )
  );
};

const AppRouting = (): ReactElement => (
  <Router>
    <Switch>
      <Route path={Routes.LOGIN} component={Login} />
      <Route path={Routes.SIGNUP} component={Signup} />
      <Route path={Routes.ACCESS} component={Access} />
      <Route path={Routes.JOIN_WAITLIST} component={JoinWaitlist} />
      <Route path='/reset-password' component={ResetPassword} />
      <Route path='/password/validate/:token' component={PasswordValidate} />
      <Route path='/' component={GuarderRoutes} />
    </Switch>
  </Router>
);
export default AppRouting;
