import { Illustration, Button, ButtonTypes } from '@musiversal/musiversal-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import imageUrl from '../../../../../assets/images/invitation-expired-modal/expired_ticket.png';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import UserService from '../../../../services/user/user.service';
import { setIsShowInvitationExpiredModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
// Enums and Const
import { title, body, cta, titleRejoined, bodyRejoined, ctaRejoined } from './InvitationExpiredModal.const';
// Styles
import styles from './InvitationExpiredModal.module.scss';

const InvitationExpiredModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const { status } = useSelector(selectUser, shallowEqual) || {};
  const [rejoined, setRejoined] = useState(false);

  useEffect(() => {
    if (status === UserStatuses.WAITLISTER) {
      setRejoined(true);
    }
  }, [status]);

  const closeInvitationExpiredModal = () => {
    dispatch(setIsShowInvitationExpiredModal(false));
  };

  const joinWaitlistAgain = async () => {
    const response = await UserService.rejoinWaitlistNotification({});
    // sendToast({ message: notificationMsg, toastType: ToastTypes.SUCCESS });
    setRejoined(true);
  };

  return (
    <>
      <ModalWrapper
        isPreventClosing={true}
        isOpen={isOpen}
        modalType={ModalTypes.DIALOG}
        onRequestClose={closeInvitationExpiredModal}
      >
        {rejoined ? (
          <div className={styles.containerRejoined}>
            <Illustration name='tick' />
            <div className={styles.titleTime}>
              <div className={styles.title}>{titleRejoined}</div>
              <div className={styles.time}>{bodyRejoined}</div>
            </div>
            <div className={styles.phone}>
              <Button
                buttonText={ctaRejoined}
                onClick={closeInvitationExpiredModal}
                buttonType={ButtonTypes.PRIMARY_OUTLINE}
                hasIcon={true}
                iconName='external_link'
              />
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.image}>
                <img
                  src={imageUrl}
                  alt='Remote recording illustration'
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </div>
            </div>
            <div className={styles.titleTime}>
              <div className={styles.title}>{title}</div>
              <div className={styles.time}>{body}</div>
            </div>
            <div className={styles.phone}>
              <Button buttonText={cta} onClick={joinWaitlistAgain} />
            </div>
          </div>
        )}
      </ModalWrapper>
    </>
  );
};

export default InvitationExpiredModal;
