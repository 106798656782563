import React, { ReactElement, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { selectSession } from '../../../store/slices/sessions.slice';
import SessionFilesModal from '../../Molecules/SessionFilesModal/SessionFilesModal';
import CustomerSessionDetails from './components/CustomerSessionDetails/CustomerSessionDetails';

interface SessionDetailsInterface {
  musicianConfiguration: any;
  onPanelClose: () => void;
}

const SessionDetails = ({ musicianConfiguration, onPanelClose }: SessionDetailsInterface): ReactElement => {
  const session = useSelector(selectSession, shallowEqual);

  const sessionId = session.id;
  const sessionTrack = session.session_info?.title;

  useEffect((): void => {
    window.history.replaceState(null, '', `/sessions/${sessionId}`);
  }, [sessionId]);

  return (
    <>
      <CustomerSessionDetails
        musicianConfiguration={musicianConfiguration}
        onPanelClose={onPanelClose} />
      <SessionFilesModal
        customerName={session.user?.name}
        partnerName={session.partner?.name}
        trackName={sessionTrack}
        sessionId={session.id}
      />
      <ReactTooltip place='top' delayHide={200} textColor='#FFF' backgroundColor='#4B5563' effect='solid' />
    </>
  );
};

export default SessionDetails;
