import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { getSession, getSessions, getSingleSession, getTracks } from 'app/store/actions/sessions.actions';
import { RootState } from 'app/store/store';

import { SessionInterface } from '../../interfaces/session.interface';
import { TrackInterface } from '../../interfaces/track.interface';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  session: SessionInterface;
  sessions: SessionInterface[];
  tracks: TrackInterface[];
  isSessionsLoading: boolean;
};

export const sessions = createSlice({
  name: SlicesNames.SESSIONS_SLICE,
  initialState: {
    session: null,
    sessions: [],
    tracks: [],
    isSessionsLoading: false
  },
  reducers: {
    setSession: (state: SliceState, { payload }: PayloadAction<SessionInterface>): void => {
      state.session = payload;
    },
    resetSessions: (state: SliceState): void => {
      state.sessions = [];
      state.tracks = [];
    },
    setIsSessionsLoading: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isSessionsLoading = payload;
    }
  },
  extraReducers: {
    [getSession.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.sessions = payload;
    },
    [getSessions.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.sessions = payload;
    },
    [getTracks.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.tracks = payload;
    },
    [getSingleSession.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      const sessionToUpdate = state.sessions?.find(session => session?.id === payload?.id);
      if (!sessionToUpdate) {
        state.sessions = [...state.sessions, payload];
      } else {
        state.sessions = state.sessions.map(session => (session.id === payload?.id ? merge(session, payload) : session));
      }
    }
  }
});

export const { setSession, resetSessions, setIsSessionsLoading } = sessions.actions;

export const selectSession = (state: RootState) => state.sessionsSlice.session;
export const selectTracks = (state: RootState) => state.sessionsSlice.tracks;

export default sessions.reducer;
