import React from 'react';

import { ReactComponent as ArrowLeft } from '../../Icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../Icons/arrow-right.svg';
import { ReactComponent as FullArrowRight } from '../../Icons/full-arrow-right.svg';
import { ReactComponent as Attach } from '../../Icons/attach.svg';
import { ReactComponent as Bookmark } from '../../Icons/bookmark.svg';
import { ReactComponent as BPM } from '../../Icons/bpm.svg';
import { ReactComponent as CDelete } from '../../Icons/c-delete.svg';
import { ReactComponent as CClose } from '../../Icons/c-close.svg';
import { ReactComponent as Calendar } from '../../Icons/calendar.svg';
import { ReactComponent as Clock } from '../../Icons/clock.svg';
import { ReactComponent as ClosedEye } from '../../Icons/closed-eye.svg';
import { ReactComponent as ConstructionSign } from '../../Icons/construction-sign.svg';
import { ReactComponent as Discount } from '../../Icons/discount.svg';
import { ReactComponent as DotsMenu } from '../../Icons/dots-menu.svg';
import { ReactComponent as Facebook } from '../../Icons/facebook.svg';
import { ReactComponent as FlagDiagonal } from '../../Icons/flag-diagonal.svg';
import { ReactComponent as FolderDownload } from '../../Icons/folder-download.svg';
import { ReactComponent as FolderMusic } from '../../Icons/folder-music.svg';
import { ReactComponent as Gift } from '../../Icons/gift.svg';
import { ReactComponent as GoldTick } from '../../Icons/gold-tick.svg';
import { ReactComponent as TickSecondary } from '../../Icons/tick_secondary.svg';
import { ReactComponent as GCalendar } from '../../Icons/google-calendar.svg';
import { ReactComponent as Google } from '../../Icons/google.svg';
import { ReactComponent as GreenTick } from '../../Icons/green-tick.svg';
import { ReactComponent as Key } from '../../Icons/key.svg';
import { ReactComponent as LiveStream } from '../../Icons/live-stream.svg';
import { ReactComponent as Logo } from '../../Icons/logo.svg';
import { ReactComponent as LogoLong } from '../../Icons/logo_long.svg';
import { ReactComponent as Lock } from '../../Icons/lock.svg';
import { ReactComponent as MagnifiyingGlass } from '../../Icons/magnifying-glass.svg';
import { ReactComponent as MoveLayerDown } from '../../Icons/move-layer-down.svg';
import { ReactComponent as MusicAlbum } from '../../Icons/music-album.svg';
import { ReactComponent as Music } from '../../Icons/music.svg';
import { ReactComponent as MusiversalBlack } from '../../Icons/musiversal-black.svg';
import { ReactComponent as Musiversal } from '../../Icons/musiversal.svg';
import { ReactComponent as NewComment } from '../../Icons/new-comment.svg';
import { ReactComponent as OpenEye } from '../../Icons/open-eye.svg';
import { ReactComponent as Pen01 } from '../../Icons/pen-01.svg';
import { ReactComponent as QuestionMark } from '../../Icons/question-mark.svg';
import { ReactComponent as Info } from '../../Icons/info.svg';
import { ReactComponent as RChat } from '../../Icons/r-chat.svg';
import { ReactComponent as ReverseTime } from '../../Icons/reverse-time.svg';
import { ReactComponent as SoundWave } from '../../Icons/sound-wave.svg';
import { ReactComponent as Spinner } from '../../Icons/spinner.svg';
import { ReactComponent as SpinnerBlue } from '../../Icons/spinner_blue.svg';
import { ReactComponent as Tags } from '../../Icons/tags.svg';
import { ReactComponent as Tick } from '../../Icons/tick.svg';
import { ReactComponent as Warning } from '../../Icons/warning.svg';
import { ReactComponent as CreditCardActive } from '../../Icons/credit-card-active.svg';
import { ReactComponent as CreditCardInactive } from '../../Icons/credit-card-inactive.svg';
import { ReactComponent as PaypalActive } from '../../Icons/paypal-active.svg';
import { ReactComponent as PaypalInactive } from '../../Icons/paypal-inactive.svg';
import { ReactComponent as CreditCardRed } from '../../Icons/credit-card-red.svg';
import { ReactComponent as CreditCardGrey } from '../../Icons/credit-card-grey.svg';
import { ReactComponent as PaypalRed } from '../../Icons/paypal-red.svg';
import { ReactComponent as PaypalGrey } from '../../Icons/paypal-grey.svg';
import { ReactComponent as Error } from '../../Icons/error.svg';
import { ReactComponent as GreenSingleTick } from '../../Icons/green-single-tick.svg';
import { ReactComponent as GetHelp } from '../../Icons/get-help.svg';
import { ReactComponent as Globe } from '../../Icons/globe.svg';
import { ReactComponent as BigGlobe } from '../../Icons/big-globe.svg';
import { ReactComponent as Question } from '../../Icons/question.svg';
import { ReactComponent as OffboardingWarning } from '../../Icons/offboarding-warning.svg';
import { ReactComponent as CheckboxTick } from '../../Icons/checkbox-tick.svg';
import { ReactComponent as BrokenHeart } from '../../Icons/broken-heart.svg';
import { ReactComponent as GoToLink } from '../../Icons/go-to-link.svg';
import { ReactComponent as NextArrow } from '../../Icons/next-arrow.svg';
import { ReactComponent as Streaming } from '../../Icons/streaming.svg';
import { ReactComponent as Play } from '../../Icons/play.svg';
import { ReactComponent as Pause } from '../../Icons/pause.svg';
import { ReactComponent as StopWatch } from '../../Icons/stop-watch.svg';
import { ReactComponent as Dollar } from '../../Icons/dollar.svg';
import { ReactComponent as XClose } from '../../Icons/x-close.svg';
import { ReactComponent as Timer } from '../../Icons/timer.svg';
import { ReactComponent as Happy } from '../../Icons/happy.svg';
import { ReactComponent as Rocket } from '../../Icons/rocket.svg';
import { ReactComponent as Welcome } from '../../Icons/welcome.svg';
import { ReactComponent as Hi } from '../../Icons/hi.svg';
import { ReactComponent as BackArrow } from '../../Icons/backArrow.svg';
import { ReactComponent as GreenCircleTick } from '../../Icons/green-circle-tick.svg';
import { ReactComponent as Referral } from '../../Icons/referral.svg';
import { ReactComponent as Profile } from '../../Icons/user.svg';
import { ReactComponent as HelpCenter } from '../../Icons/help-circle.svg';
import { ReactComponent as ContactSupport } from '../../Icons/contact-support.svg';
import { ReactComponent as Logout } from '../../Icons/log-out.svg';
import { ReactComponent as NavbarLogo } from '../../Icons/navbar-logo.svg';
import { ReactComponent as Plus } from '../../Icons/plus.svg';
import { ReactComponent as Faq } from '../../Icons/faq.svg';
import { ReactComponent as DropdownArrow } from '../../Icons/dropdown-arrow.svg';
import { ReactComponent as MagGlass } from '../../Icons/mag-glass.svg';
import { ReactComponent as InfoIcon } from '../../Icons/info-icon.svg';
import { ReactComponent as PlusIcon } from '../../Icons/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../Icons/minus-icon.svg';
import { ReactComponent as Offline } from '../../Icons/offline.svg';
import { ReactComponent as CalendarCheck } from '../../Icons/calendar-check.svg';
import { ReactComponent as CalendarQuestion } from '../../Icons/calendar-questionmark.svg';
import { ReactComponent as GreyCheck } from '../../Icons/grey-check.svg';
import { ReactComponent as UploadIcon } from '../../Icons/upload-icon-white.svg';
import { ReactComponent as GreenDownloadArrow } from '../../Icons/green-download-arrow.svg';
import { ReactComponent as StarOn } from '../../Icons/star-on.svg';
import { ReactComponent as StarOff } from '../../Icons/star-off.svg';
import { ReactComponent as AlertTriangle } from '../../Icons/alert-triangle.svg';
import { ReactComponent as AlertTriangleDark } from '../../Icons/alert-triangle-dark.svg';
import { ReactComponent as AlertTriangleOutline } from '../../Icons/alert-triangle-outline.svg';
import { ReactComponent as SquaredPlus } from '../../Icons/squared-plus.svg';
import { ReactComponent as SquaredPlusGrey } from '../../Icons/squared-plus-grey.svg';
import { ReactComponent as PlayCircle } from '../../Icons/play-circle.svg';
import { ReactComponent as Archive } from '../../Icons/archive.svg';
import { ReactComponent as MusicScore } from '../../Icons/check.svg';
import { ReactComponent as RefreshCCW } from '../../Icons/refresh-ccw.svg';
import { ReactComponent as Zap } from '../../Icons/zap.svg';
import { ReactComponent as CalendarOutlined } from '../../Icons/calendar-outlined.svg';
import { ReactComponent as Percent } from '../../Icons/percent.svg';
import { ReactComponent as FullLock } from '../../Icons/full-lock.svg';
import { ReactComponent as PendingDownload } from '../../Icons/pending-download.svg';
import { ReactComponent as FolderX } from '../../Icons/folder-x.svg';
import { ReactComponent as GreyClock } from '../../Icons/grey-clock.svg';
import { ReactComponent as XSquare } from '../../Icons/x-square.svg';
import { ReactComponent as Package } from '../../Icons/package.svg';
import { ReactComponent as RadioSelected } from '../../Icons/radio-selected.svg';
import { ReactComponent as RadioUnselected } from '../../Icons/radio-unselected.svg';
import { ReactComponent as RadioSelectedGold } from '../../Icons/radio-selected-gold.svg';
import { ReactComponent as Disc } from '../../Icons/disc.svg';
import { ReactComponent as TransparentClose } from '../../Icons/transparent-close.svg';
import { ReactComponent as Danger } from '../../Icons/danger-icon.svg';
import { ReactComponent as MusiversalLogo } from '../../Icons/musiversal-logo.svg';
import { ReactComponent as GrammyLogo } from '../../Icons/grammy-logo.svg';
import { ReactComponent as CalendarIlustration } from '../../Icons/calendar-ilustration.svg';
import { ReactComponent as HeadphonesIlustration } from '../../Icons/headsets-ilustration.svg';
import { ReactComponent as BookIlustration } from '../../Icons/book-ilustration.svg';
import { ReactComponent as PauseIlustration } from '../../Icons/pause-ilustration.svg';
import { ReactComponent as DocumentIlustration } from '../../Icons/document-ilustration.svg';
import { ReactComponent as ChatIlustration } from '../../Icons/chat-ilustration.svg';
import { ReactComponent as BuoyIlustration } from '../../Icons/buoy-ilustration.svg';
import { ReactComponent as Video } from '../../Icons/video.svg';
import { ReactComponent as FullCircle } from '../../Icons/full-circle.svg';
import { ReactComponent as OneQuarterCircle } from '../../Icons/1-4-circle.svg';
import { ReactComponent as ThreeQuarterCircle } from '../../Icons/3-4-circle.svg';
import { ReactComponent as HalfCircle } from '../../Icons/half-circle.svg';
import { ReactComponent as PausedCircle } from '../../Icons/paused-circle.svg';
import { ReactComponent as UploadFolder } from '../../Icons/upload.svg';
import { ReactComponent as File } from '../../Icons/file.svg';
import { ReactComponent as AlertCircle } from '../../Icons/alert-circle.svg';
import { ReactComponent as TrendingUp } from '../../Icons/trending-up.svg';
import { ReactComponent as Filter } from '../../Icons/filter.svg';
import { ReactComponent as DollarSign } from '../../Icons/dollar-sign.svg';
import { ReactComponent as Rss } from '../../Icons/rss.svg';
import { ReactComponent as Infinite } from '../../Icons/Infinite.svg';
import { ReactComponent as CheckCircle } from '../../Icons/check-circle.svg';
import { ReactComponent as ClockIcon } from '../../Icons/clock-icon.svg';
import { ReactComponent as CalendarIcon } from '../../Icons/calendar-icon.svg';

const iconTypes = {
  musiversal: Musiversal,
  'musiversal-black': MusiversalBlack,
  warning: Warning,
  facebook: Facebook,
  google: Google,
  'magnifying-glass': MagnifiyingGlass,
  'construction-sign': ConstructionSign,
  clock: Clock,
  calendar: Calendar,
  music: Music,
  'music-album': MusicAlbum,
  'sound-wave': SoundWave,
  'folder-music': FolderMusic,
  'flag-diagonal': FlagDiagonal,
  bpm: BPM,
  bookmark: Bookmark,
  'reverse-time': ReverseTime,
  'move-layer-down': MoveLayerDown,
  attach: Attach,
  'r-chat': RChat,
  'pen-01': Pen01,
  'new-comment': NewComment,
  'folder-download': FolderDownload,
  key: Key,
  'dots-menu': DotsMenu,
  'c-delete': CDelete,
  'c-close': CClose,
  'google-calendar': GCalendar,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'full-arrow-right': FullArrowRight,
  'live-stream': LiveStream,
  'logo-long': LogoLong,
  'open-eye': OpenEye,
  'closed-eye': ClosedEye,
  tick: Tick,
  discount: Discount,
  'question-mark': QuestionMark,
  logo: Logo,
  lock: Lock,
  'gold-tick': GoldTick,
  'green-tick': GreenTick,
  spinner: Spinner,
  'spinner-blue': SpinnerBlue,
  gift: Gift,
  tags: Tags,
  creditCardActive: CreditCardActive,
  creditCardInactive: CreditCardInactive,
  paypalActive: PaypalActive,
  paypalInactive: PaypalInactive,
  'credit-card-red': CreditCardRed,
  'credit-card-grey': CreditCardGrey,
  'paypal-red': PaypalRed,
  'paypal-grey': PaypalGrey,
  error: Error,
  'green-single-tick': GreenSingleTick,
  info: Info,
  getHelp: GetHelp,
  globe: Globe,
  bigGlobe: BigGlobe,
  question: Question,
  offboardingWarning: OffboardingWarning,
  checkboxTick: CheckboxTick,
  brokenHeart: BrokenHeart,
  goToLink: GoToLink,
  nextArrow: NextArrow,
  streaming: Streaming,
  play: Play,
  pause: Pause,
  dollar: Dollar,
  stopWatch: StopWatch,
  xClose: XClose,
  timer: Timer,
  welcome: Welcome,
  hi: Hi,
  backArrow: BackArrow,
  greenCircleTick: GreenCircleTick,
  happy: Happy,
  rocket: Rocket,
  referral: Referral,
  profile: Profile,
  helpCenter: HelpCenter,
  contactSupport: ContactSupport,
  logout: Logout,
  navbarLogo: NavbarLogo,
  plus: Plus,
  faq: Faq,
  dropdownArrow: DropdownArrow,
  magGlass: MagGlass,
  infoIcon: InfoIcon,
  plusIcon: PlusIcon,
  minusIcon: MinusIcon,
  tickSecondary: TickSecondary,
  offline: Offline,
  'calendar-check': CalendarCheck,
  'calendar-question': CalendarQuestion,
  'grey-check': GreyCheck,
  'checkmark': GreyCheck,
  'upload-icon': UploadIcon,
  'upload-folder': UploadFolder,
  'green-download-arrow': GreenDownloadArrow,
  'star-on': StarOn,
  'star-off': StarOff,
  'alert-triangle': AlertTriangle,
  'alert-triangle-dark': AlertTriangleDark,
  'alert-triangle-outline': AlertTriangleOutline,
  'squared-plus': SquaredPlus,
  'squared-plus-grey': SquaredPlusGrey,
  'play-circle': PlayCircle,
  'archive': Archive,
  'calendar_outlined': CalendarOutlined,
  'music_score': MusicScore,
  'refresh_ccw': RefreshCCW,
  'percent': Percent,
  'zap': Zap,
  'full-lock': FullLock,
  'pending-download': PendingDownload,
  'folder-x': FolderX,
  'grey-clock': GreyClock,
  'x-square': XSquare,
  'package': Package,
  'radio-selected': RadioSelected,
  'radio-selected-gold': RadioSelectedGold,
  'radio-unselected': RadioUnselected,
  'disc': Disc,
  'transparent-close': TransparentClose,
  'danger': Danger,
  'musiversal-logo': MusiversalLogo,
  'grammy-logo': GrammyLogo,
  'calendar-ilustration': CalendarIlustration,
  'headphones-ilustration': HeadphonesIlustration,
  'book-ilustration': BookIlustration,
  'pause-ilustration': PauseIlustration,
  'document-ilustration': DocumentIlustration,
  'chat-ilustration': ChatIlustration,
  'buoy-ilustration': BuoyIlustration,
  'video': Video,
  'full-circle': FullCircle,
  'one-quarter-circle': OneQuarterCircle,
  'half-circle': HalfCircle,
  'three-quarter-circle': ThreeQuarterCircle,
  'paused-circle': PausedCircle,
  'file': File,
  'alert-circle': AlertCircle,
  'trending_up': TrendingUp,
  'filter': Filter,
  'dollar_sign': DollarSign,
  'rss': Rss,
  'infinite': Infinite,
  'check_circle': CheckCircle,
  'clock_icon': ClockIcon,
  'calendar_icon': CalendarIcon
};

const IconComponent = ({ name, ...props }) => {
  const Icon = iconTypes[name];
  return <Icon {...props} />;
};

export default IconComponent;
