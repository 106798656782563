import { useEffect } from 'react';
import { Mixpanel } from './../helpers/mixpanel.helper';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
// import { MixpanelPageViewsEvents } from './../enums/mixpanel-events.enum';
// import { MixPanelViewedPageProperties } from './../enums/mixpanel-properties.enum';
// import { Routes } from './../enums/routes.enum';

export const useTrackPageViewedEvent = (): void => {
  const { enableMixpanelPageViews } = useFlags();
  const location = useLocation();
  useEffect((): void => {
    if (location?.pathname && enableMixpanelPageViews) {
      try {
        Mixpanel.track('Page Viewed', {
          'Path': location.pathname
        });
        Mixpanel.track_pageview(location?.pathname);
      } catch (err) {
        console.error(err);
      }
    }
  }, [location]);
};
