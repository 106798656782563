import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import React, { ReactElement, useEffect, useState } from 'react';

import AppRouting from './App.routing';
import ToastPortal from './components/Atoms/v3/ToastPortal/ToastPortal';
import { initAnalytics } from './helpers/init-analytics';

const LDClientSideId = process.env.REACT_APP_LD_CLIENT_SIDE_ID;
const PaypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

const App = (): ReactElement => {
  const [isReady, setIsReady] = useState(false);

  useEffect((): void => {
    const init = async (): Promise<void> => {
      initAnalytics();
      setIsReady(true);
    };
    init().finally();
  }, []);

  return (
    <LDProvider clientSideID={LDClientSideId}>
      <PayPalScriptProvider options={{ 'client-id': PaypalClientId, vault: true }}>
        {isReady && <AppRouting />}
        <ToastPortal />
      </PayPalScriptProvider>
    </LDProvider>
  );
};

export default App;
