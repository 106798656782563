import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { getMe } from '../../store/actions/user.actions';
import { selectOnboardingData } from '../../store/slices/global.slice';
import { selectUser, setUser } from '../../store/slices/user.slice';
import { SplitContainer, QuoteAndReview } from '@musiversal/musiversal-components';
import RadioWide from '../../components/Atoms/v3/RadioWide/RadioWide';
import TimezonePicker from '../../components/Atoms/v3/TimezonePicker/TimezonePicker';
import Button from '../../components/Atoms/v3/Button/Button';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { ToastTypes } from '../../enums/toast-types.enum';
import { FinishAccountSetupDataInterface } from '../../services/user/user.interface';
import UserService from '../../services/user/user.service';
import { useToast } from '../../hooks/useToast';
import {
  mainTitle,
  timezoneSectionTitle,
  timezoneSectionSubtitle,
  personaSurveyTitle,
  personaOptions,
  buttonSubmittingText,
  buttonText
} from './account-setup.const';

import {
  reviewQuote,
  reviewImage,
  reviewName,
  reviewSubtitle,
  reviewIcon
} from './../review-and-quote.const';

import styles from './AccountSetup.module.scss';

const AccountSetup = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  const [submitting, setSubmitting] = useState(false);
  const user = useSelector(selectUser, shallowEqual);
  const guessedTimezone = user.timezone || moment.tz.guess();
  const { sendToast } = useToast();

  const { control, getValues, watch, register, setValue, handleSubmit } = useForm({
    defaultValues: {
      timezone: {
        value: guessedTimezone,
        label: guessedTimezone
      }
    }
  });

  useEffect(() => {
    if (user?.status !== 'signup') {
      redirectToExplore();
    }
  }, [user?.status]);

  const redirectToExplore = async () => {
    dispatch(getMe());
    history.push('/explore');
  };

  const onSubmit = async data => {
    setSubmitting(true);
    let success = false;
    const formValues = getValues();
    if (!formValues.persona_type) {
      formValues.persona_type = onboardingData?.persona?.Options[0]?.radio_option?.value;
    }
    const formData: FinishAccountSetupDataInterface = {
      persona_type: formValues.persona_type
    };
    try {
      const info = {
        user: {
          timezone: formValues.timezone.value
        }
      };
      const res = await UserService.updateMe(info);
      await dispatch(setUser({ ...user, ...res.data.user }));
      await UserService.finishAccountSetup(formData);
      success = true;
      setSubmitting(false);
      sendToast({ message: 'Your account is ready!', toastType: ToastTypes.SUCCESS });
      redirectToExplore();
    } catch (e) {
      console.error(e);
      sendToast({ message: e, toastType: ToastTypes.DANGER });
    }
  };

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          <h1 className={styles.header}>{mainTitle}</h1>
          <div className={styles.timezoneSection}>
            <div className={styles.timezoneTitle}>
              {timezoneSectionTitle}
            </div>
            <div className={styles.timezoneSubtitle}>
              {timezoneSectionSubtitle}
            </div>
            <div className={styles.timezoneInput}>
               <TimezonePicker control={control} className={styles.timezoneSelect} isNoLabel />
            </div>
          </div>
          <div className={styles.personaSurvey}>
            <div className={styles.personaSurveyTitle}>
              {personaSurveyTitle}
            </div>
            <form className={styles.formInner} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.personaForm}>
                <div className={styles.personaOptions}>
                  { onboardingData?.persona?.Options?.map(op => op?.radio_option)?.map(
                    ({
                      id,
                      title,
                      value,
                      copy
                    }) => (
                      <div key={id}>
                        <RadioWide
                          defaultChecked={onboardingData?.persona?.Options[0]?.radio_option?.id === id}
                          name='persona_type'
                          control={control}
                          id={id}
                          value={value}
                          label={title}
                          description={copy}
                          infoContent={null}
                        />
                      </div>
                    )
                  )}
                </div>
                <div className={styles.button}>
                <Button
                    isSubmit
                    disabled={submitting}
                    width={'100%'}
                    buttonSize={ButtonSizes.MEDIUM}
                    buttonText={submitting ? buttonSubmittingText : buttonText}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      }
      rightColumn={
          <QuoteAndReview
            quote={onboardingData?.persona?.Review?.review_copy ?? reviewQuote}
            image={onboardingData?.persona?.Review?.avatar?.url ?? reviewImage}
            name={onboardingData?.persona?.Review?.user_name ?? reviewName}
            subtitle={onboardingData?.persona?.Review?.user_description ?? reviewSubtitle}
            icon={reviewIcon}
          />
      }
    />
  );
};

export default AccountSetup;
