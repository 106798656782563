import { History } from 'history';
import { ThunkDispatch } from 'redux-thunk';

import { localStorageKeys } from '../constants/localStorageKeys.const';
import { PaymentProviders } from '../enums/payment-providers.enum';
import { ToastTypes } from '../enums/toast-types.enum';
import { ToastInterface } from '../interfaces/toast.interface';
import { UserInterface } from '../interfaces/user.interface';
import PaypalService from '../services/subscriptions/paypal.service';
import SubscriptionsService from '../services/subscriptions/stripe.service';
import { getMe } from '../store/actions/user.actions';
import { setIsShowGlobalLoader } from '../store/slices/global.slice';
import { translateUserSubscription } from './translate-user-subscription';

interface FinishTrialHelperInterface {
  dispatch: ThunkDispatch<any, any, any>;
  user: UserInterface;
  sendToast: (params: ToastInterface, delay?: number) => void;
  history: History;
}

export const finishTrialHelper = async ({
  dispatch,
  user,
  sendToast,
  history
}: FinishTrialHelperInterface): Promise<void> => {
  const { paymentsProvider, currentPrice } = translateUserSubscription(user);

  try {
    dispatch(setIsShowGlobalLoader(true));
    if (paymentsProvider === PaymentProviders.PAYPAL) {
      await PaypalService.enablePaypalSubscription(currentPrice);
    } else {
      await SubscriptionsService.joinSubscriptionNow();
    }
    localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, currentPrice);
    localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
  } catch (e) {
    await dispatch(getMe());
    const errorMessage = e.response?.data?.message;
    dispatch(setIsShowGlobalLoader(false));
    sendToast({ message: errorMessage, toastType: ToastTypes.DANGER });
  }
  history.push('/profile#billing?session_id=true');
};
