import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store/store';

import { ToastTypes, ToastPositions } from '../../enums/toast-types.enum';
import { ToastInterface } from '../../interfaces/toast.interface';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  message: string;
  toastType: ToastTypes | null;
  show: boolean;
  position: ToastPositions | null;
};

export const toast = createSlice({
  name: SlicesNames.TOAST_SLICE,
  initialState: {
    message: '',
    toastType: null,
    show: null,
    position: ToastPositions.BOTTOM_RIGHT
  },
  reducers: {
    showToast: (state: SliceState, { payload }: PayloadAction<ToastInterface>): void => {
      state.message = payload?.message;
      state.toastType = payload?.toastType;
      state.show = true;
      state.position = payload?.position;
    },
    hideToast: (state: SliceState): void => {
      state.message = '';
      state.toastType = null;
      state.show = false;
      state.position = ToastPositions.BOTTOM_RIGHT;
    }
  }
});

export const { showToast, hideToast } = toast.actions;
export const selectToast = (state: RootState) => state.toastSlice;

export default toast.reducer;
