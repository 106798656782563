import axios, { AxiosPromise } from 'axios';

import { MusicProductionInterface } from '../../interfaces/music-production.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2/music_production_sessions`;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const createMusicProductionRequest = (track: MusicProductionInterface): AxiosPromise<MusicProductionInterface> => axiosInstance.post('', track);

export default {
  createMusicProductionRequest
};
