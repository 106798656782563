// import { useMediaQuery } from 'react-responsive';
import { Alert, AlertTemplates } from '@musiversal/musiversal-components';
import React, { ReactElement, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { Mixpanel } from '../../../..//helpers/mixpanel.helper';
import { useIsMobileResolution } from '../../../../hooks/useIsMobileResolution';
import UsersService from '../../../../services/user/user.service';
import { selectUser, selectPermissions } from '../../../../store/slices/user.slice';
import { getMe, getPermissions } from '../../../../store/actions/user.actions';
import {
  TUTORIAL_EXPLORE_PAGE_TITLE,
  TUTORIAL_EXPLORE_PAGE_BODY,
  TUTORIAL_MY_SESSIONS_PAGE_TITLE,
  TUTORIAL_MY_SESSIONS_PAGE_BODY,
  TUTORIAL_RESOURCES_PAGE_TITLE,
  TUTORIAL_RESOURCES_PAGE_BODY
} from './AlertsHandler.const';
import styles from './AlertsHandler.module.scss';

interface AlertsHandlerInterface {
  category?: 'tutorial';
  section: 'explore' | 'my_sessions' | 'resources';
}

const AlertsHandler = ({ category = 'tutorial', section }: AlertsHandlerInterface): ReactElement => {
  const isMobileDevice = useIsMobileResolution();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);

  const textBySection = {
    explore: {
      permission: 'alert_explorepage_1',
      title: TUTORIAL_EXPLORE_PAGE_TITLE,
      body: TUTORIAL_EXPLORE_PAGE_BODY
    },
    my_sessions: {
      permission: 'alert_mysessionspage_1',
      title: TUTORIAL_MY_SESSIONS_PAGE_TITLE,
      body: TUTORIAL_MY_SESSIONS_PAGE_BODY
    },
    resources: {
      permission: 'alert_resourcespage_1',
      title: TUTORIAL_RESOURCES_PAGE_TITLE,
      body: TUTORIAL_RESOURCES_PAGE_BODY
    }
  };

  const sectionEquivalency = {
    explore: 'alert_explorepage_1',
    my_sessions: 'alert_mysessionspage_1',
    resources: 'alert_resourcespage_1'
  };

  const shouldShowAlert = useMemo(() => {
    if (permissions) {
      const componentPermission = sectionEquivalency[section];
      if (permissions[category]) {
        return !permissions[category][componentPermission];
      }
    }
    return true;
  }, [permissions]);

  const updatePermission = async () => {
    const payload = {
      feature: category,
      permission: textBySection[section].permission,
      value: true
    };
    try {
      const response = await UsersService.updatePermissionsRequest(payload);
      try {
        Mixpanel.track('Tutorial Message Closed', {
          'User Status': user.status,
          feature: category,
          section: section,
          permission: textBySection[section].permission
        });
      } catch (e) {
        console.error(e);
      }
      await dispatch(getPermissions());
    } catch (e) {
      console.error(e);
    }
  };

  return (
    shouldShowAlert && (
      <Alert
        className={styles.alert}
        showCloseButton={true}
        template={AlertTemplates.GRAY}
        title={textBySection[section].title}
        body={textBySection[section].body}
        onCloseButtonFunction={() => updatePermission()}
      />
    )
  );
};

export default AlertsHandler;
