import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import mixpanel from 'mixpanel-browser';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { Environments } from '../enums/environments.enum';

const events = {};
// sessionBooked: 'Booked a session',
// visitBilling: 'Visited billing',
// downloadSessionFiles: 'Download session files'

const tagManagerArgs = {
  production: {
    gtmId: process.env.REACT_APP_GTM_TAG,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
    events
  },
  demo: {
    gtmId: process.env.REACT_APP_GTM_TAG,
    // auth: process.env.REACT_APP_GTM_AUTH,
    // preview: process.env.REACT_APP_GTM_PREVIEW,
    events
  }
} as Record<Environments, TagManagerArgs>;

export const initAnalytics = (): void => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
      api_host: 'https://mp.musiversal.com',
      debug: process.env.REACT_APP_NODE_ENV === Environments.DEVELOPMENT,
      track_pageview: true,
      persistence: 'localStorage'
    });
  }

  if (process.env.REACT_APP_NODE_ENV === Environments.PRODUCTION) {
    TagManager.initialize(tagManagerArgs[Environments.PRODUCTION]);

    if (Bugsnag) {
      Bugsnag.start({
        apiKey: 'f8da66cf3bffad6980a6d9c6a700e48e', // TODO: move to envs,
        plugins: [new BugsnagPluginReact()]
      });
    }
  } else {
    TagManager.initialize(tagManagerArgs[Environments.DEMO]);
  }
};
