export const COPY = {
    category: 'musiversal unlimited',
    title: 'Take your music to the next level',
    subtitle: 'Unlimited recording sessions for just',
    price: '$199 per month',
    unsubscribedMessage: 'To subscribe, please rejoin the waitlist',
    defaultDescription: 'Record your music with professional musicians and bring your music to life.',
    learnMoreLink: {
        text: 'Learn More',
        href: 'https://www.musiversal.com/unlimited'
      },
    button: 'Join waitlist'
  };