export const header = (isTrialOn?: boolean): string => isTrialOn ? headerWithTrial : headerWithoutTrial;
export const headerWithoutTrial = 'Create your free account';
export const headerWithTrial = 'Sign up now and get \n1 credit for free';
export const orText = 'OR';
export const buttonText = 'CREATE ACCOUNT';
export const buttonSubmittingText = 'GETTING STARTED...';
export const referralHeader = 'Sign up';
export const signUpHeader = (artist?: string, isTrialOn?: boolean): string => 'Create your free account';
// export const signUpHeader = (artist?: string, isTrialOn?: boolean): string =>
//   artist ? 'Sign up now' + isTrialOn && `and\nget your first session\nwith ${artist} for free` : header(isTrialOn);
export const referralSubheader = (name?: string): string =>
  `${name} will be excited to see you in the Musiversal Studio!`;
export const referralUsedSubheader =
  'This referral link has already been claimed. Please try another link or continue to sign up.';
export const referralErrorSubheader =
  "There's a problem with your referral link. Please try again with a different link or continue to sign up.";
export const rightColumnTitle =
  'The future of music recording is here';
export const BF23_INVITATION_CODE = 'BF23';