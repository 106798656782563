import { PlayerVideo } from '@musiversal/musiversal-components';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Button from '../../../../components/Atoms/v3/Button/Button';
//Enums
import { ButtonSizes } from '../../../../enums/button-sizes.enum';
// import { selectVideoModal } from '../../../../store/slices/video-modal.slice';
import { VideoPlatformTypes } from '../../../../enums/video-modal.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import { setIsVideoModal, selectVideoModal } from '../../../../store/slices/modals.slice';
//Components
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
//Styles
import styles from './VideoModal.module.scss';

import {
  headerTitle,
  headerSubtitle,
  buttonText
} from './VideoModal.const';

interface VideoModalProps extends ModalInterface {
  videoPlatform?: VideoPlatformTypes;
  videoId: string;
  headerTitle?: string;
  headerSubtitle?: string;
  buttonText?: string;
}

const VideoModal = ({ isOpen }: VideoModalProps): ReactElement => {
  const dispatch = useDispatch();
  const { videoOnly, copy, videoPlatform, videoId, afterCloseCallback } = useSelector(selectVideoModal, shallowEqual);
  const onRequestClose = (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    dispatch(setIsVideoModal({ isShown: false, videoOnly: false, copy: {}, videoId: null, videoPlatform: null, afterCloseCallback: null }));
    if (afterCloseCallback) {
      afterCloseCallback();
    }
  };

  const modalClassName = classNames({
    [styles.modal]: true,
    [styles.modalBlackBG]: videoOnly
  });

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={false}
      hideCloseIcon={false}
      className={modalClassName}
      onRequestClose={onRequestClose}
    >
      <div className={styles.player}>
        <PlayerVideo videoPlatform={videoPlatform} videoId={videoId} />
      </div>
      {!videoOnly && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>{copy?.headerTitle || headerTitle}</div>
            <div className={styles.subtitle}>{copy?.headerSubtitle || headerSubtitle}</div>
            <div className={styles.button}>
              <Button
                onClick={onRequestClose}
                disabled={false}
                width={'100%'}
                buttonSize={ButtonSizes.LARGE}
                buttonText={copy?.buttonText || buttonText}
              />
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default VideoModal;
