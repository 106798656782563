import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import React, { ReactElement } from 'react';
import { UseFormGetValues } from 'react-hook-form';

import { timeParserHelper } from '../../../../../../../helpers/time-parser.helper';
import Icon from '../../../../../../Atoms/Icon/Icon';
import { ConfirmBookingPayloadInterface, SessionInterface } from '../../../../../../interfaces/session.interface';
import { SlotInterface } from '../../../../../../interfaces/slot.interface';
import { attendanceLivestream, slotSessionTypes } from './PreConfirmationDetails.const';
import styles from './PreConfirmationDetails.module.scss';

interface PreConfirmationDetailsInterface {
  session: SessionInterface;
  credits: number;
  creditsRemaining: number;
  getValues: UseFormGetValues<any>;
  serviceSessionType: string;
  filesDelivery: string;
  timezone: string;
  selectedService: any;
}

const renderPreConfirmationDetailsRegularItem = ({
  icon,
  title,
  description,
  mode
}: {
  icon: string;
  title: string;
  description: string;
  mode?: string;
}): ReactElement => (
  <div className={styles.item}>
    <div className={styles.icon}>
      <Icon name={icon} width={18} height={18} />
    </div>
    {mode == 'oneline' && (
      <div className={styles.oneLineTitle}>
        {title} <span className={styles.oneLineDescription}>{description}</span>
      </div>
    )}
    {mode == 'simple' && (
      <div className={styles.itemTitleContainer}>
        <h3>{title}</h3>
        <div className={styles.itemDescriptionContainer}>{description}</div>
      </div>
    )}
    {mode == 'deliverables' && (
      <div className={styles.itemTitleContainer}>
        <h3>{title}</h3>
        <ul className={styles.itemListDescriptionContainer}>
          <Icon className={styles.iconBullet} name='grey-check' width={20} height={25} viewBox='0 0 20 18' />{' '}
          {description}
        </ul>
      </div>
    )}
  </div>
);

const PreConfirmationDetails = ({
  session,
  credits,
  creditsRemaining,
  getValues,
  serviceSessionType,
  filesDelivery,
  timezone,
  selectedService
}: PreConfirmationDetailsInterface): ReactElement => {
  const { track_title: track, meter, bpm, sampleRate, service, attendance, files_format } = getValues();
  const { duration, start_date } = session || {};
  const { no_track } = selectedService;
  const { hideSessionCounter } = useFlags();

  const attendanceDescription = () => {
    if (serviceSessionType === slotSessionTypes.OFFLINE) {
      return attendanceLivestream[slotSessionTypes.OFFLINE];
    }
    return `${attendanceLivestream[attendance]} (${duration}min)`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.detailsRow}>
          {renderPreConfirmationDetailsRegularItem({
            icon: 'sound-wave',
            title: no_track ? `${track?.label?.substr(0, 80) + '\u2026'}` : `${track?.label?.substr(0, 80) + '\u2026'} (${sampleRate?.label?.replace(
              /\(recommended\)/g,
              ''
            )} / 24-bit)`,
            description: selectedService.form.bpm ? `${meter} ${bpm ? 'BPM (' + bpm + ')' : ''}` : '',
            mode: 'simple'
          })}
        </div>
        <div className={styles.separator} />
        <div className={styles.detailsRow}>
          {renderPreConfirmationDetailsRegularItem({
            icon: 'music',
            title: `${service}`,
            description: `${attendanceDescription()}`,
            mode: 'simple'
          })}
          {renderPreConfirmationDetailsRegularItem({
            icon: 'calendar',
            title: `${moment(start_date).tz(timezone).format("ddd DD MMM 'YY")} (${moment(start_date)
              .tz(timezone)
              .format('HH:mm')})`,
            description: `${timezone}`,
            mode: 'simple'
          })}
        </div>
        {selectedService?.form?.deliverables && (
          <>
            <div className={styles.separator} />
            <div className={styles.detailsRow}>
              {renderPreConfirmationDetailsRegularItem({
                icon: 'attach',
                title: `Deliverables (Up to ${timeParserHelper(filesDelivery)} after the session is completed)`,
                description: `${files_format}`,
                mode: 'deliverables'
              })}
            </div>
          </>
        )}
        {!hideSessionCounter && (
          <>
            <div className={styles.separator} />
            <div className={styles.detailsRow}>
              {renderPreConfirmationDetailsRegularItem({
                icon: 'stopWatch',
                title: `${session?.duration} minutes booking`,
                description: `${credits} ${credits === 1 ? 'session long' : 'sessions long'}`,
                mode: 'simple'
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreConfirmationDetails;
