import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { Button, ButtonTypes, ButtonSizes, ButtonRadius } from '@musiversal/musiversal-components';
import { ModalTypes } from './modal-types.enum';
import styles from './ModalWrapper.module.scss';

interface ModalWrapperInterface {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  onRequestClose?: () => void;
  isPreventClosing?: boolean;
  modalType?: ModalTypes;
  hideCloseIcon?: boolean;
}

const ModalWrapper = ({
  isOpen,
  children,
  className,
  onRequestClose,
  modalType = ModalTypes.DEFAULT,
  isPreventClosing,
  hideCloseIcon = false
}: ModalWrapperInterface): ReactElement => (
  <ReactModal
    appElement={document.getElementById('root')}
    isOpen={isOpen}
    onRequestClose={isPreventClosing ? null : onRequestClose}
    className={classNames((modalType == ModalTypes.FULLSCREEN ? styles.fullscreen : styles.content), className)}
    overlayClassName={styles.overlay}
  >
    { (modalType === ModalTypes.DEFAULT || modalType === ModalTypes.FULLSCREEN) && (
     !isPreventClosing && (
        !hideCloseIcon && (
          <span className={styles.close}>
            <Button
              buttonType={ButtonTypes.SECONDARY_FILL}
              buttonSize={ButtonSizes.SMALL}
              buttonRadius={ButtonRadius.ROUND}
              hasIcon={true}
              iconName='x'
              onClick={onRequestClose}
            />
          </span>
        )
      )
    )}
    <div className={styles.body}>{children}</div>
  </ReactModal>
);
export default ModalWrapper;