import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { PORTUGUESE_TAX } from '../../../../constants/european-tax.const';

import Icon from '../../Icon/Icon';
import styles from './VATBreakdown.module.scss';

interface VATBreakdownInterface {
  amount: number;
  includeTotal?: boolean;
}

const VATBreakdown = ({ amount = 0, includeTotal = false }: VATBreakdownInterface): ReactElement => {
  const VATamount = (PORTUGUESE_TAX / 100) * (amount * 100) / 100;
  const totalWithVAT = parseFloat(amount) + VATamount;

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.breakdownRow}>
        <div className={styles.subtotal }>
          Subtotal
        </div>
        <div className={styles.subtotal }>
          ${amount}
        </div>
      </div>
      <div className={styles.breakdownRow}>
        <div className={styles.lightText }>
          VAT Charges
        </div>
        <div className={styles.lightText }>
          ${VATamount}
        </div>
      </div>
      { includeTotal && (
        <div className={styles.totalRow}>
          <div className={styles.text}>
            TOTAL
          </div>
          <div className={styles.text}>
            ${totalWithVAT}
          </div>
        </div>
      )}
    </div>
  );
};

export default VATBreakdown;