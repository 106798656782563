import { VideoPlatformTypes } from '../enums/video-modal.enum';

export const figureOutVideoPlatform = url => {
  if (url.includes('youtube')) {
    return VideoPlatformTypes.YOUTUBE;
  }
  if (url.includes('vimeo')) {
    return VideoPlatformTypes.VIMEO;
  }
  return VideoPlatformTypes.YOUTUBE;
};

export const getVideoId = url => {
  if (url.includes('youtube')) {
    return url.split('v=')[1];
  }
  // the url could be https://player.vimeo.com/video/818430660?h=c35bf0ae0f&badge=0&autopause=0&player_id=0&app_id=58479
  // so we need to remove the query params
  if (url.includes('vimeo')) {
    if (url.includes('player.vimeo.com/video/') && url.includes('?')) {
      return url.split('player.vimeo.com/video/')[1].split('?')[0];
    }
    return url.split('vimeo.com/')[1];
  }

  return url;
};
