export enum MixPanelSignUpEvents {
  LOGIN = 'Login',
  CHOSE_SIGN_UP_METHOD = 'Chose Sign up Method',
  COMPLETED_SIGN_UP = 'Completed Sign up',
  CLICKED_CHANGE_PLAN = 'Clicked Change Plan',
  CHANGED_PLAN = 'Changed Plan',
  ADDED_PAYMENT_METHOD = 'Added Payment Method',
  TRIAL_STARTED = 'Trial Started'
}

export type MixPanelEventTypes = MixPanelSignUpEvents;
