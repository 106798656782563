import { createAsyncThunk } from '@reduxjs/toolkit';

import SessionsService from '../../services/sessions/sessions.service';
import TracksService from '../../services/tracks/tracks.service';
import { setIsSessionsLoading } from '../slices/sessions.slice';
import { SlicesNames } from '../slices/slices-names.enum';

export const getSession = createAsyncThunk(
  `${SlicesNames.SESSIONS_SLICE}/getSession`,
  async (): Promise<any> => {
    try {
      return (await SessionsService.getSessionsRequest()).data.sessions;
    } catch (e) {
      return;
    }
  }
);

export const getSessions = createAsyncThunk(
  `${SlicesNames.SESSIONS_SLICE}/getSessions`,
  async (arg, thunkAPI): Promise<any> => {
    try {
      thunkAPI.dispatch(setIsSessionsLoading(true));
      return (await SessionsService.getSessionsRequest()).data.sessions;
    } catch (e) {
      console.error(e);
    } finally {
      thunkAPI.dispatch(setIsSessionsLoading(false));
    }
  }
);

export const getSingleSession = createAsyncThunk(
  `${SlicesNames.SESSIONS_SLICE}/getSingleSession`,
  async (sessionId: number): Promise<any> => {
    try {
      console.log(sessionId);
      const r = await SessionsService.getSingleSessionRequest(sessionId);
      const session = r.data;
      return session;
    } catch (e) {
      return;
    }
  }
);

export const getTracks = createAsyncThunk(
  `${SlicesNames.SESSIONS_SLICE}/getTracks`,
  async (): Promise<any> => {
    try {
      return (await TracksService.getTracksRequest()).data;
    } catch (e) {
      return;
    }
  }
);
