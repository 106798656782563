import React, { ReactElement } from 'react';
import { Illustration, Button, Icon } from '@musiversal/musiversal-components';
import { useDispatch } from 'react-redux';

import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import { setIsShowSpeedLimitModal } from '../../../../store/slices/modals.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import styles from './SpeedLimitModal.module.scss';

const SpeedLimitModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();

  const closeSpeedLimitModal = () => {
    dispatch(setIsShowSpeedLimitModal(false));
  };

  return (
    <>
      <ModalWrapper isOpen={isOpen} modalType={ModalTypes.DEFAULT} onRequestClose={closeSpeedLimitModal}>
        <div className={styles.container}>
          <Illustration name='fair' />
          <div className={styles.textContainer}>
            <div className={styles.title}>Fair Access</div>
            <div className={styles.paragraph}>Aligning with our community policy, your bookings are momentarily full. Finish a session to book more.</div>
            <div className={styles.thanks}>Thanks for contributing to our collective success!</div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default SpeedLimitModal;
