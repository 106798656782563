import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BookSessionForm from '../../../../../components/Molecules/BookSessionForm/BookSessionForm';
import { CardMusician } from '@musiversal/musiversal-components';
import Billboard from '../../../../../components/Molecules/Billboard/Billboard';
import SidePane from '../../../../../components/Molecules/SidePane/SidePane.js';
import AlertsHandler from '../../../../../components/Molecules/v3/AlertsHandler/AlertsHandler';
import { config } from '../../../../../config/musicianConfigs';
import styles from '../Explore.module.scss';
import useIsPartnerGuard from '../../../../../guards/useIsPartnerDashboard.guard';
import { getCurrentlyBookingArtistConfig, getExploreFilters, getNextAvailableSessions } from '../../../../../store/actions/musicians.actions';
import Spinner from '../../../../../components/Atoms/Spinner/Spinner';
import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { getTracks } from '../../../../../store/actions/sessions.actions';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import {
  setIsHireProducerModal,
  setIsShowSubscriptionModal,
  setIsShowWaitlistModal,
  setIsShowLeadToWaitlisterModal,
  setIsShowArtistProfileModal
} from '../../../../../store/slices/modals.slice';
import MusiciansService from '../../../../../services/musicians/musicians.service';
import StrapiService from '../../../../../services/cms/cms.service';
import { selectTracks, setSession } from '../../../../../store/slices/sessions.slice';
import moment from 'moment-timezone';
import ProFilters from './pro-filters/pro-filters';
import { selectExploreFilters, selectNextAvailableSessions } from '../../../../../store/slices/musicians.slice';
import Icon from '../../../../../components/Atoms/Icon/Icon';
import classNames from 'classnames';
import { Mixpanel } from 'app/helpers/mixpanel.helper';

const AVAILABILITY_DAYS = 60;
const DEFAULT_CONFIG = {
  bookingOptions: null,
  fileUploadRules: null,
  deliverablesOptions: {
    default: 'Stems to be imported in a different DAW',
    options: ['Stems to be imported in a different DAW']
  }
};

// Order partners based on their slug given an array
const PARTNERS_ORDER = [
  'clare-dove',
  'devin-malloy',
  'pedro-araujo',
  'chris-barber',
  'bruno-migliari',
  'marta-garrett',
  'gabor-udvarhelyi',
  'ben-trigg',
  'damian-bolotin',
  'yuri-villar',
  'chris-ott',
  'bence-taborszky',
  'felipe-ribeiro',
  'luiz-tornaghi',
  'arthur-romio',
  'joao-paulo-drumond',
  'davi-mello',
  'fernando-moreno',
  'pablo-arruda',
  'antonio-guerra',
  'vitaliy-tkachuk',
  'samuel-da-silva',
  'ayran-nicodemo',
  'henrique-vilhena',
  'nuno-fernandes'
];

const ProExplore = () => {
  const dispatch = useDispatch();
  const { user, user: { timezone, status: userStatus, booking_window_hours } } = useSelector(state => state.userSlice, shallowEqual);
  const tracks = useSelector(selectTracks, shallowEqual);
  const exploreFilters = useSelector(selectExploreFilters, shallowEqual);
  const nextAvailableSessions = useSelector(selectNextAvailableSessions, shallowEqual);
  const history = useHistory();
  const { slug } = useParams();
  const location = useLocation();

  const [showPanel, setShowPanel] = useState(false);
  const [slotsToShow, setSlotsToShow] = useState([]);
  const [selectedMusician, setSelectedMusician] = useState(null);
  const [selectedMusicianConfig, setSelectedMusicianConfig] = useState(null);
  const [partnersToDisplay, setPartnersToDisplay] = useState([]);
  const [partners, setPartners] = useState([]);
  const [serviceFilters, setServiceFilters] = useState([]);
  const [timeFilters, setTimeFilters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  useIsPartnerGuard('/sessions');

  const getPartners = async filters => {
    try {
      setIsLoading(true);
      const { partners: partnersList } = (await MusiciansService.gerPartners(filters)).data;
      setPartners(partnersList);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!timeFilters) {
      return;
    }
    getPartners(timeFilters).finally();
  }, [timeFilters, timezone]);

  useEffect(() => {
    if (serviceFilters.length === 0) {
      setPartnersToDisplay(partners);
      return;
    }
    const filteredPartners = partners.map(partner =>
      partner.services.some(service => serviceFilters.includes(service)) ? partner : null).filter(p => !!p);
    setPartnersToDisplay(filteredPartners);
  }, [partners, serviceFilters]);

  useEffect(() => {
    const getData = async () => {
      const promises = [];
      if (!exploreFilters?.length) {
        promises.push(dispatch(getExploreFilters()));
      }
      if (!Object.keys(nextAvailableSessions)?.length) {
        promises.push(dispatch(getNextAvailableSessions()));
      }
      if (!tracks?.length) {
        promises.push(dispatch(getTracks()));
      }
      await Promise.all(promises);
    };
    getData().finally();
  }, [dispatch, exploreFilters?.length, Object.keys(nextAvailableSessions)?.length, tracks]);

  const getMusicianAvailability = useCallback(async ({ id }) => {
    const slots = (await MusiciansService.getMusicianAvailability(id, AVAILABILITY_DAYS)).data;
    setSlotsToShow(slots);
  }, []);

  const isWaitlisterOrLead = useMemo(() => [UserStatuses.WAITLISTER, UserStatuses.LEAD].includes(userStatus), [userStatus]);

  const orderPartners = useCallback((partners, definedOrder) => {
    const orderedPartners = [];
    definedOrder.forEach(slug => {
      const partner = partners.find(partner => partner.slug === slug);
      if (partner) {
        orderedPartners.push(partner);
      }
    });
    // Now add the rest of the partners
    partners.forEach(partner => {
      if (!orderedPartners.includes(partner)) {
        orderedPartners.push(partner);
      }
    });
    return orderedPartners;
  }, []);

  const getMusicianConfiguration = useCallback(async ({ id }) => {
    try {
      const res = await MusiciansService.getMusicianConfiguration(id);
      setSelectedMusicianConfig(res.data);
    } catch (err) {
      setSelectedMusicianConfig(DEFAULT_CONFIG);
      throw new Error(err);
    }
  }, []);

  const selectMusician = useCallback(async musician => {
    if (isWaitlisterOrLead) {
      if (userStatus === UserStatuses.WAITLISTER) {
        dispatch(setIsShowWaitlistModal(true));
      }
      if (userStatus === UserStatuses.LEAD) {
        dispatch(setIsShowLeadToWaitlisterModal({ isShown: true, source: 'book_musician_button' }));
      }
    } else {
      dispatch(setIsShowGlobalLoader(true));
      try {
        await Promise.all([
          getMusicianAvailability(musician),
          getMusicianConfiguration(musician),
          dispatch(getCurrentlyBookingArtistConfig(musician.slug))
        ]);
        setSelectedMusician(musician);
        setShowPanel(true);
        Mixpanel.track('Booking Session Drawer Opened', {
          'Partner': musician.slug,
          'User ID': user.id,
          // 'Total Sessions booked': user.sessions_booked,
          'User Status': user.status
        });
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setIsShowGlobalLoader(false));
      }
    }
  }, [getMusicianAvailability, getMusicianConfiguration, dispatch]);

  useEffect(() => {
    if (!slug) {
      return;
    }
    if (location.search) {
      history.push(location.pathname);
    }
    const musicianToOpen = partners?.find(musician => musician.slug === slug);
    if (!musicianToOpen) {
      history.push(location.pathname);
      // history.push('/explore');
      return;
    }
    selectMusician(musicianToOpen).finally();
  }, [partners, selectMusician, slug, dispatch, history, location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname == '/offers') {
      history.push('/explore');
      return;
    }
  }, [dispatch, history, location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname == '/musicproduction') {
      dispatch(setIsHireProducerModal(true));
      history.push('/explore');
      return;
    }
    if (location.pathname == '/subscribe') {
      console.log('user.status', user.status);
      if (![UserStatuses.UNLIMITED, UserStatuses.PENDING_CANCELLATION].includes(user.status) && user.allow_subscribe) {
        dispatch(setIsShowSubscriptionModal(true));
      }
      if (user.status === UserStatuses.PENDING_CANCELLATION) {
        history.push('/profile#billing');
      } else {
        history.push('/explore');
      }
      return;
    }
  }, [dispatch, history, location.pathname, location.search]);


  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const onPanelClose = () => {
    dispatch(setSession(null));
    history.push('/explore');
    setShowPanel(false);
  };

  const openCustomQuote = async musician => {
    try {
      Mixpanel.track('Custom Quote Button Clicked', {
        'Partner': musician.slug,
        'User ID': user.id,
        // 'Total Sessions booked': user.sessions_booked,
        'User Status': user.status
      });
    } catch (error) {
      console.error(error);
    }
    window.open('https://musiversal.typeform.com/to/xaAGhMbU', '_blank');
  };

  const openMusicianUrl = useCallback(async musician => {
    if (musician?.modal_profile) {
      dispatch(setIsShowArtistProfileModal({ isShown: true, artistSlug: musician?.slug, buttonCallback: () => selectMusician(musician) }));
    } else {
      const musicianUrl = `${process.env.REACT_APP_MUSICIAN_PROFILES_DOMAIN}/artists/${musician.slug}`;
      Mixpanel.track('View Profile Clicked', {
        'Partner': musician.slug,
        'User ID': user.id,
        'Musician URL': musicianUrl,
        // 'Total Sessions booked': user.sessions_booked,
        'User Status': user.status,
        'Has Available Sessions': nextAvailableSessions[booking_window_hours]?.filter(x => x.slug === musician.slug)?.length > 0,
        'Time until Next Session': moment(nextAvailableSessions[booking_window_hours]?.find(x => x.slug === musician.slug)?.start_date).fromNow()
      });
      window.open(musicianUrl, '_blank');
    }
  }, [getMusicianAvailability, getMusicianConfiguration, dispatch]);

  const { startDate: filterStartDate, endDate: filterEndDate, startTime, endTime } = timeFilters || {};
  const filtersApplied = serviceFilters.length || filterStartDate || filterEndDate || startTime || endTime;

  return (
    <>
      <div className={styles.wrapper}>
        {exploreFilters?.length ? <div className={styles.proExploreContainer}>
            <ProFilters
              filters={exploreFilters}
              serviceFilters={serviceFilters}
              timeFilters={timeFilters}
              setServiceFilters={setServiceFilters}
              setTimeFilters={setTimeFilters}
              setIsMobileFiltersOpen={setIsMobileFiltersOpen}
            />
            <div className={styles.exploreBody}>
              <AlertsHandler section='explore' />
              <div className={classNames(styles.billboard)}>
                <Billboard />
              </div>
                {!isMobileFiltersOpen && <div className={classNames(styles.musiciansGrid, filtersApplied && styles.filtersApplied)}>
                  {isLoading ? (
                    <Spinner className={styles.spinner} isBlue />
                  ) : partnersToDisplay.length ? (
                    orderPartners(partnersToDisplay, nextAvailableSessions[booking_window_hours].map(x => x.slug)).map(musician => {
                      /* Musicians that don't have an image throws `undefined` which makes the whole code breaks, did this quick fix until it's resolved */
                      //const tempImg = musician.image_url ? musician.image_url : (musician.image?.url ? musician.image?.url : "/images/musicians/avatar.png");

                      const tempImg = musician.coverImage ?? (musician.image ?? `../../../assets/images/musicians-avatars/${musician.name}.png`);
                      return (
                        <CardMusician
                          key={musician.id}
                          id={musician.id}
                          image={tempImg}
                          name={musician.name}
                          bio={musician.ShortBio}
                          title={musician.title}
                          onClick={() => selectMusician(musician)}
                          onClickSecondary={() => openMusicianUrl(musician)}
                          externalUrl={config.linksByName[musician.name]}
                          musicianUrl={`${process.env.REACT_APP_MUSICIAN_PROFILES_DOMAIN}/artists/${musician.slug}`}
                          hasAvailableSessions={isWaitlisterOrLead ? true : nextAvailableSessions[booking_window_hours]?.filter(x => x.slug === musician.slug)?.length > 0}
                          nextAvailableSession={nextAvailableSessions[booking_window_hours]?.find(x => x.slug === musician.slug)?.start_date}
                          flag={musician.flag}
                          isNew={musician.isNew}
                          showNextDate={!isWaitlisterOrLead}
                          onUnavailableClick={() => openCustomQuote(musician)}
                        />
                      );
                    })
                  ) : <div className={styles.noAvailableSessionContainer}>
                    <Icon name={'magGlass'} />
                    <p>{'There are no available sessions for the chosen filters. Please try another service or date range!'}</p>
                  </div>}
                </div>}
              </div>
            </div> :
            <Spinner className={styles.spinner} isBlue />
          }
        </div>

      <SidePane
        isOpen={showPanel}
        isLarge
        onRequestClose={onPanelClose}
      >
        {selectedMusician && (
          <>
            <BookSessionForm
              musician={selectedMusician}
              musicianConfiguration={selectedMusicianConfig}
              instrument={selectedMusician.title}
              timezone={user?.timezone || moment.tz.guess()}
              slots={slotsToShow}
              tracks={tracks}
              mobileVersion={width < 576}
              onClose={() => {
                setShowPanel(false);
              }}
            />
          </>
        )}
      </SidePane>
    </>
  );
};

export default ProExplore;
