import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import React, { Dispatch, ReactElement, useCallback, useMemo, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';

import Button from '../../../../../../components/Atoms/Button/Button';
import Chip from '../../../../../../components/Atoms/Chip/Chip';
import CustomCheckbox from '../../../../../../components/Atoms/CustomCheckbox/CustomCheckbox';
import DateRangeInput from '../../../../../../components/Atoms/DateRangeInput/DateRangeInput';
import Icon from '../../../../../../components/Atoms/Icon/Icon';
import { UserStatuses } from '../../../../../../enums/user-statuses.enum';
import { Mixpanel } from '../../../../../../helpers/mixpanel.helper';
import { timeSystemConverterHelper } from '../../../../../../helpers/time-system-converter.helper';
import { useIsMobileResolution } from '../../../../../../hooks/useIsMobileResolution';
import { GetPartnersFiltersInterface } from '../../../../../../interfaces/get-partners-filters.interface';
import { ServiceInterface } from '../../../../../../interfaces/service.interface';
import { selectBannerHeight } from '../../../../../../store/slices/global.slice';
import { selectUser } from '../../../../../../store/slices/user.slice';
import styles from './pro-filters.module.scss';

interface ProFilters {
  filters: ServiceInterface[];
  serviceFilters: string[];
  setServiceFilters: Dispatch<string[]>;
  timeFilters: Partial<GetPartnersFiltersInterface>;
  setTimeFilters: Dispatch<Partial<GetPartnersFiltersInterface>>;
  setIsMobileFiltersOpen: Dispatch<boolean>;
}

const ProFilters = ({
  filters,
  serviceFilters,
  setTimeFilters,
  setServiceFilters,
  timeFilters,
  setIsMobileFiltersOpen
}: ProFilters): ReactElement => {
  const isMobileResolution = useIsMobileResolution();
  const bannerHeight = useSelector(selectBannerHeight);
  const [dateRange, setDateRange] = useState([null, null]);
  const [datePickerRange, setDatePickerRange] = useState([]);
  const [timeRange, setTimeRange] = useState<[string, string]>([null, null]);
  const [startDate, endDate] = dateRange;
  const { timezone, status: userStatus } = useSelector(selectUser, shallowEqual);
  const [filterPhrase, setFilterPhrase] = useState('');
  const [exploreFilters, setExploreFilters] = useState<ServiceInterface[]>(filters);
  const filterPhraseElementRef = useRef<HTMLInputElement | null>();
  const serviceFiltersRef = useRef<string[]>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { control, watch, reset, setValue } = useForm();

  const { startDate: filterStartDate, endDate: filterEndDate, startTime, endTime } = timeFilters || {};
  const filtersApplied = serviceFilters.length || filterStartDate || filterEndDate || startTime || endTime;

  const watchers = watch();

  useEffect((): void => {
    const instruments = Object.keys(watchers)
      .map(key => (watchers[key] ? key : null))
      .filter(w => !!w);
    if (isEqual(instruments, serviceFiltersRef.current)) {
      return;
    }
    setServiceFilters(instruments);
    serviceFiltersRef.current = instruments;
    try {
      Mixpanel.track('Filter Instruments', {
        'Full choice': serviceFiltersRef?.current,
        'Last selected': serviceFiltersRef?.current?.[serviceFiltersRef?.current?.length - 1],
        'Number selected': serviceFiltersRef?.current?.length,
        'User Status': userStatus
      });
    } catch (err) {
      console.error(err);
    }
  }, [setServiceFilters, watchers]);

  useEffect((): void => {
    setIsMobileFiltersOpen(isFiltersOpen);
  }, [isFiltersOpen]);

  useEffect((): void => {
    if (isMobileResolution) {
      return;
    }
    setTimeFilters({
      timezone,
      startDate: datePickerRange[0] ? moment(datePickerRange[0]).format('YYYY-MM-DD') : null,
      endDate: datePickerRange[1] ? moment(datePickerRange[1]).format('YYYY-MM-DD') : null,
      startTime: timeRange[0] ? timeSystemConverterHelper(timeRange[0], true) : null,
      endTime: timeRange[1] ? timeSystemConverterHelper(timeRange[1], true) : null
    });
  }, [timezone, datePickerRange, setTimeFilters, timeRange, isMobileResolution]);

  const onMobileSearch = (): void => {
    const updatedFilters = {
      timezone,
      startDate: datePickerRange[0] ? moment(datePickerRange[0]).format('YYYY-MM-DD') : null,
      endDate: datePickerRange[1] ? moment(datePickerRange[1]).format('YYYY-MM-DD') : null,
      startTime: timeRange[0] ? timeSystemConverterHelper(timeRange[0], true) : null,
      endTime: timeRange[1] ? timeSystemConverterHelper(timeRange[1], true) : null
    };
    if (!isEqual(updatedFilters, timeFilters)) {
      setTimeFilters(updatedFilters);
    }
    toggleMobileFilters();
  };

  const isWaitlisterOrLead = useMemo((): boolean => [UserStatuses.WAITLISTER, UserStatuses.LEAD].includes(userStatus), [
    userStatus
  ]);

  const onMobileClear = (): void => {
    setTimeFilters({
      timezone,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null
    });
    onClearFilters();
    setIsFiltersOpen(false);
  };

  useEffect((): void => {
    setExploreFilters(filters.filter(({ label }) => label.toLowerCase().includes(filterPhrase?.toLowerCase())));
  }, [filters, filterPhrase]);

  const onDateRangeClear = (): void => {
    setDateRange([null, null]);
    setDatePickerRange([null, null]);
  };

  const onDateChipClear = (): void => {
    onDateRangeClear();
    setTimeFilters({
      ...timeFilters,
      startDate: null,
      endDate: null
    });
  };

  const onTimeChipClear = (): void => {
    onTimePickerClear();
    setTimeFilters({
      ...timeFilters,
      startTime: null,
      endTime: null
    });
  };

  const onInputFilterPhase = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterPhrase(e.target.value);
  };

  const onClearFilterPhrase = (): void => {
    setFilterPhrase('');
    filterPhraseElementRef?.current.focus();
  };

  const onTimePickerClear = (): void => {
    setTimeRange([null, null]);
  };

  const onClearFilters = (): void => {
    reset();
    setFilterPhrase('');
    onDateRangeClear();
    onTimePickerClear();
  };

  const handleCalendarClose = (): void => {
    setDatePickerRange(dateRange);
  };

  const toggleMobileFilters = (): void => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const onRemoveServiceChip = (service: string): (() => void) => (): void => {
    setValue(service, false);
  };

  const dateChipContent = `${filterStartDate ? moment(filterStartDate).format('MM/DD/YY') : ''} - ${
    filterEndDate ? moment(filterEndDate).format('MM/DD/YY') : ''
  }`;
  const timeChipContent = `${startTime ? timeSystemConverterHelper(startTime, false) : ''} - ${
    endTime ? timeSystemConverterHelper(endTime, false) : ''
  }`;

  const renderFiltersContent = (): ReactElement => (
    <>
      {!isWaitlisterOrLead && (
        <div className={styles.dateRangePicker}>
          <DatePicker
            dateFormat='MM/dd/yy'
            selectsRange
            startDate={startDate}
            endDate={endDate}
            openToDate={moment().toDate()}
            minDate={moment().toDate()}
            maxDate={moment().add(2, 'months').toDate()}
            onChange={update => setDateRange(update)}
            customInput={<DateRangeInput onClear={onDateRangeClear} />}
            calendarClassName='dateTimePicker'
            dayClassName={() => 'calendarDay'}
            onCalendarClose={handleCalendarClose}
          />
        </div>
      )}

      {/* <TimeRangeInput timeRange={timeRange} setTimeRange={setTimeRange} onClear={onTimePickerClear} /> */}

      {!isWaitlisterOrLead && (
        <div className={styles.timezone}>
          <Icon name={'globe'} />
          <span>{timezone}</span>
        </div>
      )}

      <div className={classNames(styles.serviceFilter, !isWaitlisterOrLead && styles.separation)}>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder={'Instruments & Services'}
            onChange={onInputFilterPhase}
            value={filterPhrase}
            ref={filterPhraseElementRef}
          />
          <div className={styles.icons}>
            <Icon name={'xClose'} className={styles.closeIcon} onClick={onClearFilterPhrase} />
          </div>
        </div>
        <div className={styles.list}>
          {exploreFilters.map(({ value, label }) => (
            <CustomCheckbox
              key={value}
              required
              name={value}
              label={label}
              control={control}
              containerClassName={styles.checkbox}
              checkedContainerClassName={styles.checked}
              labelClassName={styles.label}
            />
          ))}
        </div>
      </div>
    </>
  );

  const renderChips = (): ReactElement => (
    <div className={styles.chipsContainer}>
      <div className={styles.chips}>
        {(filterStartDate || filterEndDate) && <Chip content={dateChipContent} onClear={onDateChipClear} />}
        {/* {(startTime || endTime) && (
          <Chip className={styles.uppercase} content={timeChipContent} onClear={onTimeChipClear} />
        )} */}
        {serviceFilters.map(filter => (
          <React.Fragment key={filter}>
            <Chip content={filter} onClear={onRemoveServiceChip(filter)} />
          </React.Fragment>
        ))}
      </div>
      <div className={styles.appliedFiltersButtons}>
        <button onClick={toggleMobileFilters}>open filters</button>
        <span> / </span>
        <button onClick={onMobileClear}>clear all</button>
      </div>
    </div>
  );

  const renderMobileFilters = (): ReactElement => (
    <div
      className={classNames(styles.proFiltersMobile, filtersApplied)}
      style={{ zIndex: isFiltersOpen ? 3000 : 1000 }}
    >
      {isFiltersOpen ? (
        <div className={classNames(styles.mobileScrollContent, isFiltersOpen && styles.mobileScrollContentOpen)}>
          <div className={styles.buttons}>
            <Button value={'Search'} className={styles.searchButton} onClick={onMobileSearch} />
            <span className={styles.clearFilters} onClick={onMobileClear}>
              clear search
            </span>
            <Icon name={'xClose'} onClick={(): void => setIsFiltersOpen(false)} />
          </div>
          <div className={styles.mobileFiltersContent}>{renderFiltersContent()}</div>
        </div>
      ) : filtersApplied ? (
        renderChips()
      ) : (
        <div className={styles.buttonContainer}>
          <button onClick={toggleMobileFilters} className={classNames(isFiltersOpen && styles.buttonIsOpen)}>
            Instruments & Services
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.filtersContainer}>
      {isMobileResolution ? (
        renderMobileFilters()
      ) : (
        <div
          className={styles.proFilters}
          style={{ top: `${bannerHeight + 80}px`, height: `calc(100vh - ${bannerHeight + 80}px)` }}
        >
          <span className={styles.clearFilters} onClick={onClearFilters}>
            clear filters
          </span>
          {renderFiltersContent()}
        </div>
      )}
    </div>
  );
};

export default ProFilters;
