import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { PORTUGUESE_TAX } from '../../../constants/european-tax.const';

import Icon from '../Icon/Icon';
import styles from './VATBreakdown.module.scss';

interface VATBreakdownInterface {
  amount: number;
  template: string;
}

const VATBreakdown = ({ amount = 0, template = 'dark' }: VATBreakdownInterface): ReactElement => {
  const VATamount = (PORTUGUESE_TAX / 100) * (amount * 100) / 100;
  const totalWithVAT = parseFloat(amount) + VATamount;
  const isDarkTemplate = template === 'dark';
  return (
    <div className={classNames(styles.container, styles[template])}>
      <div className={styles.breakdownRow}>
        <div className={isDarkTemplate ? styles.text : styles.lightBlackText }>
          Subtotal
        </div>
        <div className={isDarkTemplate ? styles.text : styles.lightBlackText }>
          ${amount}
        </div>
      </div>
      <div className={styles.breakdownRow}>
        <div className={isDarkTemplate ? styles.text : styles.lightText }>
          VAT Charges
        </div>
        <div className={isDarkTemplate ? styles.text : styles.lightText }>
          ${VATamount}
        </div>
      </div>
      { isDarkTemplate && (
        <div className={styles.totalRow}>
          <div className={styles.text}>
            TOTAL
          </div>
          <div className={styles.text}>
            ${totalWithVAT}
          </div>
        </div>
      )}
    </div>
  );
};

export default VATBreakdown;