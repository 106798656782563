import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { getLabelValueObjectHelper } from '../../../helpers/get-label-value-object.helper';
import { SessionInterface } from '../../../interfaces/session.interface';
import { selectCurrentlyBookingArtistConfig } from '../../../store/slices/musicians.slice';

interface UseGetFormDefaultValuesHookInterface {
  session: SessionInterface;
  extraConfig: Record<string, any>;
}

export const useGetFormDefaultValuesHook = ({
  session,
  extraConfig
}: UseGetFormDefaultValuesHookInterface): Record<string, any> => {
  const { services, sampleRates } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const instruments = services?.servicesList;

  const { title, track_genre, reference_track, meter, bpm, options: trackOptions, sampleRate: trackSampleRate } =
    session?.session_info || {};

  return useMemo(
    () => ({
      track_title: { label: title, value: session?.track_id },
      track_genre: { label: track_genre, value: track_genre },
      reference_track,
      meter,
      bpm,
      sampleRate: trackSampleRate
        ? getLabelValueObjectHelper(trackSampleRate, sampleRates)
        : sampleRates?.find(sampleRate => sampleRate.isDefault),
      extra: session?.session_info?.options
        ? getLabelValueObjectHelper(session?.session_info?.options, instruments)
        : instruments.find(({ isDefault }) => isDefault),
      service: instruments?.find(({ isDefault }) => isDefault).value,
      files_format: session?.files_format ? session?.files_format : extraConfig?.deliverablesOptions?.default,
      attendance: session?.attendance
        ? session?.attendance
        : session?.partner?.sessions_type === 'offline'
        ? 'false'
        : 'true'
    }),
    [
      title,
      session?.track_id,
      session?.session_info?.options,
      session?.files_format,
      session?.attendance,
      session?.partner?.sessions_type,
      track_genre,
      reference_track,
      meter,
      bpm,
      trackSampleRate,
      sampleRates,
      instruments,
      extraConfig?.deliverablesOptions?.default
    ]
  );
};
