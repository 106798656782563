import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ModalInterface } from '../../../../interfaces/modal.interface';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ButtonSizes } from '../../../../enums/button-sizes.enum';
import { ButtonTypes } from '../../../../enums/button-types.enum';
import { getOnboardingModalData } from '../../../../store/actions/modals.actions';
import { selectIsPartnersDashboard, setIsShowGlobalLoader, selectOnboardingData } from '../../../../store/slices/global.slice';
import { getOnboardingData } from '../../../../store/actions/global.actions';
import { uploadTrackFile } from '../../../../store/actions/uploads.actions';
import { setIsHireProducerModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import musicProductionService from '../../../../services/music-production/music-production.service';
import trackFilesService from '../../../../services/track-files/track-files.service';

import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Icon from '../../../../components/Atoms/Icon/Icon';
import IlustrationButton from '../../../../components/Atoms/v3/IlustrationButton/IlustrationButton';
import TrustedBy from '../../../../components/Atoms/v3/TrustedBy/TrustedBy';
import TextArea from '../../../../components/Atoms/v3/TextArea/TextArea';
import Button from '../../../../components/Atoms/v3/Button/Button';
import { SplitContainer, ListItems } from '@musiversal/musiversal-components';
import FileUploadDropzone from '../../../../components/Atoms/v3/FileUploadDropzone/FileUploadDropzone';

import styles from './HireProducerModal.module.scss';

import {
  rightColumnTitle,
  headerTitle,
  trustedByMessage,
  formTitle,
  buttonText,
  submmitingMessage,
  submmitingFilesMessage,
  message,
  options,
  addOnDisclaimer
} from './HireProducerModal.const';

export enum OptionActions {
  OPEN_VIDEO = 'openVideo',
  OPEN_CALENDLY = 'openCalendly',
  OPEN_HIRE_PRODUCER_MODAL = 'openHireProducerModal'
}

const HireProducerModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    getValues
  } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const [processComplete, setProcessComplete] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [addedFiles, setAddedFiles] = useState([]);

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
    };

    init().finally();
  }, [dispatch]);

  useEffect((): void => {
    const data = getValues();
    if (data.description) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  }, [watch('description')]);

  // const goToLink = () => {
  //   window.open(onboardingData.starting.calendly_link);
  // };

  // const selectOnClickFunction = (option): any => {
  //   if (option.action === OptionActions.OPEN_CALENDLY) {
  //     return goToLink;
  //   }
  //   if (option.action === OptionActions.OPEN_HIRE_PRODUCER_MODAL) {
  //     return goToLink;
  //   }
  //   return goToLink;
  // };

  const onRequestClose = (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    dispatch(setIsHireProducerModal(false));
  };

  const redirectToExplore = () => {
    dispatch(setIsHireProducerModal(false));
  };

  const onSubmit = async data => {
    setSubmitting(true);
    console.log('HireProducer Submit', data);

    const musicProductionSessionRequestData = {
      description: data?.description ?? ''
    };

    try {
      const { data } = await musicProductionService.createMusicProductionRequest(musicProductionSessionRequestData);
      const { track_id } = data?.music_production_session;
      console.log('addedfiles', addedFiles);
      const r = await trackFilesService.getTrackFileUploadUrl(
        {
          track_id: track_id,
          files: addedFiles.map(({ path, type }) => ({ fileName: path, filePath: path, fileType: type }))
        }
      );
      const { urls } = r.data;
      const uploadsRes = await Promise.allSettled(
        urls.map(async ({ url, key }) => {
          const fileToUpload = addedFiles.find(file => file.path === key);
          console.log('fileToUpload', fileToUpload);
          if (!fileToUpload) {
            return;
          }
          await dispatch(uploadTrackFile({ fileToUpload, url, track_id }));
        })
      );
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
    setProcessComplete(true);
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={false}
      className={styles.modal}
      modalType={ModalTypes.FULLSCREEN}
      onRequestClose={onRequestClose}
      hideCloseIcon={false}
    >
      <SplitContainer
        leftColumn={
          <>
            { !submitting && !processComplete && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <h1 className={styles.title}>{headerTitle}</h1>
                </div>
                <div className={styles.form}>
                  <div className={styles.formTitle}>{formTitle}</div>
                  <div className={styles.form}>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.formInner}>
                      <TextArea
                        placeholder={'Do you have any musical references? Deadline? Other comments?'}
                        name={'description'}
                        id={'description'}
                        registerRef={register('description', {
                          required: 'Required'
                        })}
                        errors={[errors.description?.message]}
                        clearErrors={() => clearErrors()}
                      />
                      <FileUploadDropzone
                        setAddedFiles={setAddedFiles}
                      />

                      <div className={styles.button}>
                        <Button
                          isSubmit
                          disabled={!readyToSubmit}
                          width={'100%'}
                          buttonSize={ButtonSizes.LARGE}
                          buttonText={submitting ? submmitingMessage : buttonText}
                        />
                      </div>
                      <div className={styles.addOnDisclaimer}>{addOnDisclaimer}</div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            { submitting && !processComplete && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <h1 className={styles.title}>Processing your request</h1>
                </div>
                <div className={styles.subheader}>
                  <div className={styles.formTitle}>Uploading files</div>
                </div>
              </div>
            )}
            { !submitting && processComplete && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <h1 className={styles.title}>Success!</h1>
                </div>
                <div className={styles.subheader}>
                  <div className={styles.formTitle}>{"We've received your request and will get in touch asap."}</div>
                </div>
                <div className={styles.button}>
                  <Button
                    onClick={redirectToExplore}
                    width={'100%'}
                    buttonSize={ButtonSizes.LARGE}
                    buttonText={
                      <div className={styles.buttonContent}>
                        <span>Enter the studio</span><Icon name='full-arrow-right' className={styles.icon} />
                      </div>
                    }
                  />
                </div>
              </div>
            )}
          </>
        }
        rightColumn={
          <div className={styles.rightColumnContainer}>
            <ListItems
              title={rightColumnTitle}
              bullets={onboardingData?.music_producer?.bullets ?? []}
            />
            {/* <div className={styles.trustedBy}>
              <TrustedBy message={trustedByMessage}/>
            </div> */}
          </div>
        }
      />
    </ModalWrapper>
  );
};

export default HireProducerModal;
