import { createAsyncThunk } from '@reduxjs/toolkit';

import { OffboardingModalInterface } from '../../interfaces/offboarding-modal.interface';
import { OnboardingModalInterface } from '../../interfaces/onboarding-modal.interface';
import CmsService from '../../services/cms/cms.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getOffboardingModalData = createAsyncThunk(
  `${SlicesNames.MODALS_SLICE}/getOffboardingModalData`,
  async (): Promise<OffboardingModalInterface> => {
    try {
      return (await CmsService.getOffboardingDataRequest()).data;
    } catch (e) {
      console.error(e);
    }
  }
);

const presentSelectionModalItem = (item: any) => ({
  id: item.id,
  title: item.title,
  description: item.subtext,
  image: item.artist?.ProfileImage?.url,
  action: 'openArtist',
  artistSlug: item.artist?.slug
});

export const getOnboardingModalData = createAsyncThunk(
  `${SlicesNames.MODALS_SLICE}/getOnboardingModalData`,
  async (): Promise<OnboardingModalInterface> => {
    try {
      const data =  (await CmsService.getOnboardingModalDataRequest()).data;
      const { SelectionModal, UserPersonaSurvey } = data;
      return {
        SelectionModal: {
          title: SelectionModal?.title,
          subtitle: SelectionModal?.subtitle,
          linkText: SelectionModal?.linkText,
          linkSubtext: SelectionModal?.linkSubtext,
          casual: SelectionModal?.casual?.map(presentSelectionModalItem),
          aspiring: SelectionModal?.aspiring?.map(presentSelectionModalItem),
          professional: SelectionModal?.professional?.map(presentSelectionModalItem)
        },
        UserPersonaSurvey: {
          title: UserPersonaSurvey?.title,
          subtitle: UserPersonaSurvey?.subtitle,
          surveyOptions: UserPersonaSurvey?.surveyOption?.map((option: any) => ({
            id: option.value,
            value: option.value,
            description: option.description
          })),
          mainButton: UserPersonaSurvey?.MainButton,
          secondaryButton: UserPersonaSurvey?.AlternativeButton
        }
      };
    } catch (e) {
      console.error(e);
    }
  }
);
