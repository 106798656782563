import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactElement } from 'react';

import Icon from '../../Icon/Icon';
import styles from './PreSessionSpecs.module.scss';

const CANCELLATION_POLICY_URL = 'https://help.musiversal.com/how-can-i-cancel-my-upcoming-session';

interface PreSessionSpecsInterface {
  // children: ReactElement;
  timezone: string;
  sessionDuration: number;
  sessionCost: number;
  offlineService: boolean;
  showCancellationPolicy?: boolean;
}

const PreSessionSpecs = ({
  timezone,
  sessionCost,
  sessionDuration,
  offlineService,
  showCancellationPolicy = false
}: PreSessionSpecsInterface): ReactElement => {
  const { hideSessionCounter } = useFlags();
  return (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        {sessionDuration == 0 ? (
          <>
            <div className={styles.preSessionSpecsIcon}>
              <Icon name={offlineService ? 'offline' : 'stopWatch'} />
            </div>
            <p>Select one or multiple session slots to create a booking.</p>
          </>
        ) : (
          <>
            <div className={styles.preSessionSpecsIcon}>
              <Icon name={offlineService ? 'offline' : 'stopWatch'} />
            </div>
            <div className={styles.description}>
              {offlineService ? (
                <span className={styles.duration}>Offline session</span>
              ) : (
                <span className={styles.duration}>{sessionDuration} min</span>
              )}
              {!hideSessionCounter && (
                <span className={styles.totalCredits}>
                ({sessionCost} {sessionCost <= 1 ? 'Session' : 'Sessions'})
              </span>
              )}
            </div>
            {showCancellationPolicy && (
              <a
                className={styles.cancellationPolicyLink}
                href={CANCELLATION_POLICY_URL}
                rel='noreferrer'
                target='_blank'
              >
                Cancelation policy
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PreSessionSpecs;
